import React, { createContext, useReducer, useContext } from 'react'
import { createPortal } from 'react-dom'
import Toast from '../components/Toast'

export const ToastContext = createContext()

const initialState = []

export const ADD = 'ADD'
export const REMOVE = 'REMOVE'
export const REMOVE_ALL = 'REMOVE_ALL'
export const REMOVE_TAG = 'REMOVE_TAG'

//For the reducer hook this function updates the toast array by adding new toasts or removing them
export const toastReducer = (state, action) => {
    switch (action.type) {
        case ADD:
            return [
                ...state,
                {
                    id: action.payload.id,
                    content: {
                        message: action.payload.message,
                        severity: action.payload.severity,
                        clear: false,
                    },
                },
            ]
        case REMOVE_TAG:
            const tempState = [...state]
            let updateElement = null

            const updateElementIdx = tempState.findIndex(
                (t) => t.id === action.payload.id
            )
            if (updateElementIdx > -1) {
                updateElement = tempState[updateElementIdx]

                tempState.splice(updateElementIdx, 1, {
                    ...updateElement,
                    clear: true,
                })
            }

            return [...tempState]
        case REMOVE:
            return state.filter((t) => t.id !== action.payload.id)
        case REMOVE_ALL:
            return initialState
        default:
            return state
    }
}

//Uses the reducer hook to provide child element with the ability to make toast notifications apear on the main screen
export const ToastProvider = (props) => {
    const [toast, toastDispatch] = useReducer(toastReducer, initialState)

    //function to add toast
    const sendToast = (message, severity = 'success') => {
        const id = +new Date()

        toastDispatch({
            type: ADD,
            payload: {
                id: id,
                severity: severity,
                message: message,
            },
        })

        setTimeout(() => {
            toastDispatch({
                type: REMOVE_TAG,
                payload: { id: id },
            })
        }, 4000)

        setTimeout(() => {
            toastDispatch({
                type: REMOVE,
                payload: { id: id },
            })
        }, 4500)
    }

    //function to remove toast
    const removeToast = (id) => {
        toastDispatch({ type: REMOVE, payload: { id: id } })
    }

    //function to remove all toasts
    const removeAllToast = () => {
        toastDispatch({
            type: REMOVE_ALL,
        })
    }

    const toastData = React.useMemo(
        () => ({
            sendToast,
        }),
        []
    )

    //gives all child components ability to modify and read toast info
    //Also shows toasts on the screen on the main page outside of any child element
    return (
        <ToastContext.Provider value={toastData}>
            {props.children}
            {createPortal(
                <Toast toast={toast} removeToast={removeToast} />,
                document.body
            )}
        </ToastContext.Provider>
    )
}

//gives other classes acces to the context provided here
export const useToastContext = () => {
    return useContext(ToastContext)
}
