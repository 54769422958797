import React, { useState, useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { styles } from '../../theme/styles'
import { Box, Paper } from '@mui/material'
import { getAllMasterLogbooks } from './actions'
import { useToastContext } from '../../context/ToastContext'
import useLoader from '../../hooks/useLoader'
import { ReactComponent as MasterLogsheetIcon } from '../../icons/masterLogsheet.svg'

const MasterLogbooks = () => {
    const [masterLogbooks, setMasterLogbooks] = useState([])
    const [LoaderWrapper, setIsLoading] = useLoader()
    const toastContext = useToastContext()

    const asyncFetchMasterLogbooks = async () => {
        setIsLoading(true)

        const masterLogbooksResults = await getAllMasterLogbooks().catch((e) =>
            toastContext.sendToast('Error Fetching Data', 'error')
        )
        console.log('masterLogbooksResults', masterLogbooksResults)
        setMasterLogbooks(masterLogbooksResults)
        setIsLoading(false)
    }

    useEffect(() => {
        asyncFetchMasterLogbooks()
    }, [])

    return (
        <LoaderWrapper>
            <Box sx={styles.cardContainer}>
                {Array.isArray(masterLogbooks) &&
                    masterLogbooks.length > 0 &&
                    masterLogbooks
                        .sort((a, b) => {
                            if (!a.masterLogsheetId && b.masterLogsheetId)
                                return -1
                            if (a.masterLogsheetId && !b.masterLogsheetId)
                                return 1
                            return new Date(b.createdAt) - new Date(a.createdAt)
                        })
                        .map((masterLogbook) => (
                            <Box
                                component={RouterLink}
                                to={`/logbooks/${masterLogbook.id}`}
                                style={{ textDecoration: 'none', gap: '5px' }}
                                sx={{
                                    ':hover': {
                                        boxShadow:
                                            '0px 10px 15px rgba(0, 0, 0, 0.2)',
                                    },
                                }}
                            >
                                <Paper
                                    key={masterLogbook.id}
                                    elevation={0}
                                    style={styles.paper}
                                >
                                    <Box sx={styles.cardImageSmallContainer}>
                                        <MasterLogsheetIcon
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        />
                                    </Box>
                                    <Box sx={styles.cardTextContainer}>
                                        <div style={styles.cardTextBig}>
                                            {masterLogbook.masterLogbookTitle}
                                        </div>
                                    </Box>
                                </Paper>
                            </Box>
                        ))}
            </Box>
        </LoaderWrapper>
    )
}

export default MasterLogbooks
