import axios from 'axios'
import { generateZoneExcelReport, generateExcelReport } from '../../utils/excel'

//This file contains functions that use axios to send and get information from the backend and make excel reports

export const getAllZones = async () => {
    return await axios.get('/api/zones').then(({ data }) => data)
}

export const getAllZonesWithStations = async () => {
    return await axios
        .get('/api/zones-with-stations')
        .then(({ data }) => {
            const returnObj = {}
            for (const zone of data) {
                returnObj[zone.id] = zone
            }
            return returnObj
        })
        .catch((err) => console.error(err))
}

export const getIndividualZone = async (zoneId) => {
    return await axios.get(`/api/zones/${zoneId}`)
}
export const downloadReport = async (masterLogsheetId = null) => {
    return await axios
        .post('/api/exports')
        .then(({ data }) => data)
        .then((measurements) => generateExcelReport(measurements, masterLogsheetId))
        .catch((err) => console.error(err))
}

export const downloadZoneReport = async (zoneId) => {
    return await axios
        .post('/api/exports', {
            zoneId,
        })
        .then(({ data }) => generateZoneExcelReport(data))
        .catch((err) => console.error(err))
}

export const updateStation = async (zoneId, stationId, payload) => {
    return await axios
        .patch(`/api/zones/${zoneId}/stations/${stationId}`, payload)
        .then(({ data }) => data)
}

export const deleteStation = async (zoneId, stationId) => {
    return await axios
        .delete(`/api/zones/${zoneId}/stations/${stationId}`)
        .then(({ data }) => data)
}

export const createZone = async (zoneTitle, imageS3Key, organizationId) => {
    return await axios
        .post(`/api/zones`, {
            zoneTitle,
            imageS3Key,
        })
        .then(({ data }) => data)
        .catch((err) => console.error(err))
}

export const updateZone = async (zoneId, zoneTitle, imageS3Key) => {
    return await axios
        .put(`/api/zones/${zoneId}`, {
            zoneTitle,
            imageS3Key,
        })
        .then(({ data }) => data)
}

export const getZoneOrdering = async (payload) => {
    return await axios
        .get(`/api/zones/orderings`, payload)
        .then(({ data }) => data)
}

export const updateZoneOrdering = async (payload) => {
    return await axios
        .post(`/api/zones/orderings`, payload)
        .then(({ data }) => data)
        .catch((err) => console.error(err))
}

export const getStationOrdering = async (zoneId, payload) => {
    return await axios
        .get(`/api/zones/${zoneId}/stations/orderings`, payload)
        .then(({ data }) => data)
}

export const updateStationOrdering = async (zoneId, payload) => {
    return await axios
        .post(`/api/zones/${zoneId}/stations/orderings`, payload)
        .then(({ data }) => data)
        .catch((err) => console.error(err))
}

export const deleteZone = async (zoneId) => {
    return await axios.delete(`/api/zones/${zoneId}`).then(({ data }) => data)
}

export const createStation = async (
    zoneId,
    stationTitle,
    stationDescription,
    target,
    rangeLow,
    rangeHigh,
    measurementUnit,
    measurementOptions
) => {
    return await axios
        .post(`/api/zones/${zoneId}/stations`, {
            zoneId,
            stationTitle,
            stationDescription,
            target,
            rangeLow,
            rangeHigh,
            measurementUnit,
            measurementOptions,
        })
        .then(({ data }) => data)
}

export const updateMasterLogsheetIdInZones = async (
    masterLogsheetId,
    zonesToUpdate
) => {
    await Promise.all(
        zonesToUpdate.map(async (zoneId) => {
            try {
                const response = await axios.patch(
                    `/api/zones/${zoneId}/`,
                    masterLogsheetId
                )
                const updatedZone = response.data
                console.log('Zone updated:', updatedZone)
                return updatedZone
            } catch (error) {
                console.error('Error updating zone:', error)
                throw error // Propagate the error if needed
            }
        })
    )
}
