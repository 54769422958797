import React, { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { useToastContext } from '../../../context/ToastContext'
import useCrudLoader from '../../../hooks/useCrudLoader'
import {
    minifiedGetAllZonesWithStations,
    getAllMasterLogsheets,
} from '../../Logsheets/actions'
import ModalContent from './ModalContent'

export default function CreateOrUpdateDashboardItem({
    setDisplayModal,
    setCurrentDashboard,
    userDashboards,
    dashboardIndex,
    setOrdering,
}) {
    const [open, setOpen] = useState(true)
    const [error, setError] = useState(false)
    const toastContext = useToastContext()
    const [LoaderWrapper, setIsLoading, isLoading] = useCrudLoader()
    const [zonesWithStations, setZonesWithStations] = useState([])
    const [masterLogsheets, setMasterLogsheets] = useState([])
    
    const asyncFetchMasterLogsheets = async () => {
        setIsLoading(true)
        const masterLogsheetResults = await getAllMasterLogsheets().catch((e) =>
            toastContext.sendToast('Error Fetching Data', 'error')
        )
        
        setMasterLogsheets((prev) => [
            ...prev,
            ...masterLogsheetResults?.map((logsheet) => [
                logsheet.masterLogsheetTitle,
                logsheet.id,
            ]),
        ])
        setIsLoading(false)
    }

    const asyncFetchZonesWithStations = async () => {
        const zonesWithStationsResponse =
            await minifiedGetAllZonesWithStations()
        setZonesWithStations(zonesWithStationsResponse)
    }

    const handleClose = () => {
        setOpen(false)
        setDisplayModal(false)
    }

    useEffect(() => {
        asyncFetchMasterLogsheets()
        asyncFetchZonesWithStations()
    }, [])

    if (isLoading) {
        return (
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={'xs'}
            >
                <LoaderWrapper></LoaderWrapper>
            </Dialog>
        )
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogContent>
                <DialogContentText id="create-zone-error">
                    {error}
                </DialogContentText>

                <ModalContent
                    setDisplayModal={setDisplayModal}
                    masterLogsheets={masterLogsheets}
                    zonesWithStations={zonesWithStations}
                    setCurrentDashboard={setCurrentDashboard}
                    userDashboards={userDashboards}
                    dashboardIndex={dashboardIndex}
                    setOrdering={setOrdering}
                    setLoading={setIsLoading}
                />
                <br />
            </DialogContent>
        </Dialog>
    )
}
