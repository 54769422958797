import React, { useState, useEffect } from 'react'
import { NavLink as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Button, Collapse, Link, ListItem } from '@mui/material'
import { makeStyles } from '@mui/styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

const useStyles = makeStyles((theme) => ({
    item: {
        '&:hover, &:focus': {
            backgroundColor: 'transparent',
        },
        '&:active': {
            backgroundColor: 'transparent',
        },
    },
    itemLeaf: {
        '&:hover, &:focus': {
            backgroundColor: 'transparent',
        },
        '&:active': {
            backgroundColor: 'transparent',
        },
    },
    button: {
        padding: '5px 8px',
        textTransform: 'none',
        height: '20px',
    },
    buttonLeaf: {
        padding: '5px 8px',
        textTransform: 'none',
        letterSpacing: 0,
        height: '20px',
    },
    icon: {
        height: '20px',
        fill: '#474747',
        padding: '0 10px 0 10px',
    },
    iconSelected: {
        height: '20px',
        fill: 'white',
        padding: '0 10px 0 10px',
    },

    title: {
        color: '#363636',
        fontFamily: 'Montserrat',
        fontWeight: 500,
        fontSize: '13px',
        letterSpacing: '-0.5px',
    },
    titleSelected: {
        color: 'white',
        fontFamily: 'Montserrat',
        fontWeight: 500,
        fontSize: '13px',
        letterSpacing: '-0.5px',
    },
}))
// Creates the nav item interface. Allows for a toggle to pen nav items. There can be children to the nav items.
const NavItem = ({
    children,
    className,
    depth,
    disabled,
    href,
    selected,
    icon: Icon,
    info: Info,
    open: openProp,
    title,
    external,
    ...rest
}) => {
    const classes = useStyles()
    //  const [open, setOpen] = useState(openProp)

    //  const handleToggle = () => {
    //      setOpen((prevOpen) => !prevOpen)
    //  }

    let paddingLeft = 0

    if (depth > 0) {
        paddingLeft = 32 + 5 * depth
    }

    const style = {
        paddingLeft,
        textTransform: 'lowercase',
        display: 'flex',
        justifyContent: 'center',
        gap: '10px',
    } // inside the button

    if (children) {
        return (
            <ListItem
                className={clsx(classes.item, className)}
                disableGutters
                key={title}
                {...rest}
            >
                <Button
                    className={classes.button}
                    disabled={disabled}
                    style={style}
                >
                    {Icon && <Icon />}
                    <span
                        className={
                            selected ? classes.titleSelected : classes.title
                        }
                    >
                        {title}
                    </span>
                    {openProp ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Button>
                <Collapse in={openProp}>{children}</Collapse>
            </ListItem>
        )
    }

    return (
        <ListItem
            className={clsx(classes.itemLeaf, className)}
            disableGutters
            key={title}
            {...rest}
            component={!external ? RouterLink : undefined}
            to={!external ? href : undefined}
        >
            {external ? (
                <Button
                    activeClassName={classes.active}
                    className={clsx(classes.buttonLeaf, `depth-${depth}`)}
                    style={style}
                    href={href}
                    rel="noopener"
                    target="_blank"
                    disabled={disabled}
                >
                    {Icon && (
                        <Icon
                            className={
                                selected
                                    ? classes.iconSelected
                                    : classes.iconNotSelected
                            }
                        />
                    )}
                    <span
                        className={
                            selected ? classes.titleSelected : classes.title
                        }
                    >
                        {title}
                    </span>
                    {Info && <Info />}
                </Button>
            ) : (
                <Button
                    activeClassName={classes.active}
                    className={clsx(classes.buttonLeaf, `depth-${depth}`)}
                    component={RouterLink}
                    exact
                    style={style}
                    to={href}
                    disabled={disabled}
                >
                    {Icon && (
                        <Icon
                            className={
                                selected ? classes.iconSelected : classes.icon
                            }
                        />
                    )}
                    <span
                        className={
                            selected ? classes.titleSelected : classes.title
                        }
                    >
                        {title}
                    </span>
                    {Info && <Info />}
                </Button>
            )}
        </ListItem>
    )
}

NavItem.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    depth: PropTypes.number.isRequired,
    href: PropTypes.string,
    icon: PropTypes.elementType,
    info: PropTypes.elementType,
    open: PropTypes.bool,
    title: PropTypes.string.isRequired,
    external: PropTypes.bool,
}

NavItem.defaultProps = {
    open: false,
    external: false,
}

export default NavItem
