import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Button, Typography } from '@mui/material'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useToastContext } from '../../../context/ToastContext'
import useCrudLoader from '../../../hooks/useCrudLoader'

import { deleteZone } from '../actions'

//A component that allows the user to delete a zone
const DeleteZone = ({ zoneId, displayDeleteZone, onSetDisplayDeleteZone }) => {
    console.log(zoneId)
    const [location, setLocation] = useState('')
    const history = useHistory()
    const [formValues, setFormValues] = useState({})
    const [error, setError] = useState(false)
    const toastContext = useToastContext()
    const [LoaderWrapper, setIsLoading, isLoading] = useCrudLoader()

    useEffect(() => {
        if (location) {
            const locationStripRegexp = new RegExp('/zones/.')
            const [locationStrip, ...rest] = location.match(locationStripRegexp)
            history.push(locationStrip)
            onSetDisplayDeleteZone(false)
        }
    }, [location])

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setFormValues({
            ...formValues,
            [name]: value,
        })
    }
    
    const handleSubmit = async (event) => {
        if(isLoading) {
            return;
        }
        event.preventDefault()
        setIsLoading(true);

        await deleteZone(zoneId)
            .then(() => toastContext.sendToast('Successfully Deleted Zone'))
            .catch((e) => toastContext.sendToast(e.message, 'error'))

        history.push('/zones')
        setIsLoading(false)
    }

    if (isLoading && displayDeleteZone) {
        return (
            <Dialog
                open={displayDeleteZone}
                onClose={() => onSetDisplayDeleteZone(false)}
                fullWidth={true}
                maxWidth={'xs'}
            >
                <LoaderWrapper>
                </LoaderWrapper>
            </Dialog>
        )
    }

    return (
        displayDeleteZone && (
            <Dialog
                open={displayDeleteZone}
                onClose={() => onSetDisplayDeleteZone(false)}
            >
                <DialogContent>
                    <DialogContentText id="create-zone-error">
                        {error}
                    </DialogContentText>
                    <DialogContentText>
                        Are you sure you want to delete this zone? This action
                        cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => onSetDisplayDeleteZone(false)}>
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit}>Delete</Button>
                </DialogActions>
            </Dialog>
        )
    )
}

export default DeleteZone
