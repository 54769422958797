import React, { useState } from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import {
    Typography,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { changePassword, changeRole } from '../actions'
import { useToastContext } from '../../../context/ToastContext'
import useCrudLoader from '../../../hooks/useCrudLoader'
import { isUserRole } from '../../../utils/isUserRole'
import { useAuth } from 'oidc-react'
import { generateRandomPassword } from '../../../utils/generateRandomPassword'

export default function EditUser(props) {
    const [open, setOpen] = React.useState(true)
    const auth = useAuth()
    const [formValues, setFormValues] = useState({
        role: '',
        password: '',
    })
    const [error, setError] = useState(false)
    const toastContext = useToastContext()
    const [LoaderWrapper, setIsLoading, isLoading] = useCrudLoader()

    const handleClose = () => {
        setOpen(false)
        props.setDisplayModal(false)
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target

        setFormValues({
            ...formValues,
            [name]: value,
        })
    }

    const generatePassword = () => {
        const randomPassword = generateRandomPassword(12)
        setFormValues({
            ...formValues,
            password: randomPassword,
        })
    }

    const handlePasswordChange = async () => {
        if (isLoading) {
            return
        }

        if (formValues?.password?.trim().length > 0) {
            setIsLoading(true)

            await changePassword(props.user.username, formValues.password)
                .then(() => {
                    props?.onSuccess?.()
                    toastContext.sendToast('Successfully Updated Password')
                    setFormValues({
                        ...formValues,
                        password: '',
                    })
                })
                .catch((e) => toastContext.sendToast(e.message, 'error'))

            setIsLoading(false)
        } else {
            setError('All fields are required')
        }
    }

    const handleRoleChange = async () => {
        if (isLoading) {
            return
        }

        if (formValues?.role?.trim().length > 0) {
            setIsLoading(true)

            await changeRole(props.user.username, formValues.role)
                .then(() => {
                    props?.onSuccess?.()
                    toastContext.sendToast('Successfully Updated Role')
                    setFormValues({
                        ...formValues,
                        password: '',
                    })
                })
                .catch((e) => toastContext.sendToast(e.message, 'error'))

            setIsLoading(false)
        } else {
            setError('All fields are required')
        }
    }

    if (isLoading) {
        return (
            <Dialog open={open} fullScreen>
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <LoaderWrapper></LoaderWrapper>
                </Box>
            </Dialog>
        )
    }

    return (
        <Dialog fullScreen open={open} onClose={handleClose}>
            <DialogContent sx={{ padding: 4 }}>
                <DialogContentText>{error}</DialogContentText>

                <Typography variant="h1" mb={3}>
                    Edit {props.user.email}
                </Typography>

                <Box mb={3}>
                    <Box display={'flex'} alignItems="center" gap={3}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="password"
                            name="password"
                            label="Temp Password"
                            fullWidth
                            variant="standard"
                            onChange={handleInputChange}
                            value={formValues.password}
                        />

                        <Button
                            size="small"
                            variant="contained"
                            onClick={generatePassword}
                            style={{
                                alignSelf: 'center',
                                padding: '0.25rem 1.5rem',
                            }}
                        >
                            <Typography whiteSpace={'nowrap'}>
                                Generate Password
                            </Typography>
                        </Button>
                    </Box>

                    <Box mt={2}>
                        <Button
                            size="small"
                            variant="contained"
                            onClick={() => {
                                handlePasswordChange()
                            }}
                        >
                            <Typography>Set Temp User Password</Typography>
                        </Button>
                    </Box>
                </Box>

                <Box>
                    <FormControl fullWidth>
                        <InputLabel id="role-select">Role</InputLabel>
                        <Select
                            labelId="role-select"
                            id="role-select"
                            name="role"
                            value={formValues.role}
                            label="Role"
                            onChange={handleInputChange}
                        >
                            {isUserRole(auth, 'admin') && (
                                <MenuItem value={'admin'}>Super Admin</MenuItem>
                            )}
                            <MenuItem value={'organization_admin'}>
                                Organization Admin
                            </MenuItem>
                            <MenuItem value={'organization_manager'}>
                                Organization Manager
                            </MenuItem>
                            <MenuItem value={'organization_superuser'}>
                                Organization Super User
                            </MenuItem>
                            <MenuItem value={'organization_operator'}>
                                Organization Operator
                            </MenuItem>
                            <MenuItem value={'user'}>User</MenuItem>
                        </Select>
                    </FormControl>

                    <Box mt={2}>
                        <Button
                            size="small"
                            variant="contained"
                            onClick={() => {
                                handleRoleChange()
                            }}
                        >
                            <Typography>Change User Role</Typography>
                        </Button>
                    </Box>
                </Box>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}
