import React, { useState, useEffect, useRef } from 'react'
import { styled } from '@mui/material/styles'
import {
    Box,
    Accordion,
    AccordionSummary,
    Typography,
    AccordionDetails,
    FormGroup,
    FormControlLabel,
    Checkbox,
    CircularProgress,
    Button,
    Dialog,
    TextField,
    DialogActions,
    DialogContent,
    DialogContentText,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { makeStyles } from '@mui/styles'
import {
    createMasterLogsheet,
    updateMasterLogsheet,
    minifiedGetAllZonesWithStations,
} from './actions'
import { uploadImage } from '../actions'
import { useToastContext } from '../../context/ToastContext'
import useCrudLoader from '../../hooks/useCrudLoader'

const useStyles = makeStyles((theme) => ({
    zoneImage: {
        height: '200px',
        width: '250px',
        objectFit: 'cover',
        overflow: 'hidden',
        alignSelf: 'center',
        display: 'flex',
        margin: 'auto',
    },
    zoneImageContainer: {
        padding: theme.spacing(2),
        margin: '16px 0px 0px 0px',
    },
}))

const SelectButton = styled(Button)({
    marginTop: '10px',
})

//component used to render a form where admins can enter data for a new master logsheet (name, image, etc.) or update one
//props should have a variable 'operation' which defines what changes the user is making
export default function CreateOrUpdateLogsheet(props) {
    const [open, setOpen] = React.useState(true)
    const [formValues, setFormValues] = useState({
        name: props.masterLogsheetTitle,
    })
    const [error, setError] = useState(false)
    const [recommendation, setRecommendation] = useState(false)
    const [zonesWithStations, setZonesWithStations] = React.useState([])
    const [selectedZoneIds, setSelectedZoneIds] = useState({})
    const classes = useStyles()
    const toastContext = useToastContext()
    const [LoaderWrapper, setIsLoading, isLoading] = useCrudLoader()

    const setInitialSelectedZones = (zonesWithStations) => {
        const initialSelectedZones = {}
        zonesWithStations.forEach((zone) => {
            if (zone.masterLogsheetId == props.masterLogsheetId) {
                initialSelectedZones[zone.id] = zone.stations.map(
                    (station) => station.id
                )
            }
        })

        return initialSelectedZones
    }

    const asyncFetchZonesWithStations = async () => {
        const zonesWithStationsResponse =
            await minifiedGetAllZonesWithStations()
        setZonesWithStations(zonesWithStationsResponse)

        if (props.operation === 'Update') {
            const initialSelectedZones = setInitialSelectedZones(
                zonesWithStationsResponse
            )
            setSelectedZoneIds(initialSelectedZones)
        }
    }

    useEffect(() => {
        asyncFetchZonesWithStations()
    }, [props.operation])

    const handleClose = () => {
        setOpen(false)
        props.setDisplayModal(false)
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target

        setFormValues({
            ...formValues,
            [name]: value,
        })
    }

    const handleCheckZone = (zone) => {
        if (zone.id in selectedZoneIds) {
            let tempZoneIds = { ...selectedZoneIds }
            delete tempZoneIds[zone.id]
            setSelectedZoneIds(tempZoneIds)
        } else {
            setSelectedZoneIds((currSelectedStationIds) => {
                return {
                    ...currSelectedStationIds,
                    [zone.id]:
                        zone.stations.length > 0
                            ? zone.stations.map((station) => station.id)
                            : [],
                }
            })
        }
    }

    //Called to make sure all data is entered correctly. Sends request to backend upon completion.
    const handleSubmit = async (event) => {
        if (!formValues.name) {
            setRecommendation('A Master Logsheet name is required')
            return
        }

        if (isLoading) {
            return
        }
        event.preventDefault()
        setIsLoading(true)

        if (props.operation === 'Create') {
            const stationIds = [...Object.values(selectedZoneIds)].flat()
            const zoneIds = Object.keys(selectedZoneIds).map((value) =>
                parseInt(value)
            )

            await createMasterLogsheet(formValues.name, stationIds, zoneIds)
                .then(() => {
                    props?.onSuccess?.()
                    toastContext.sendToast(
                        'Successfully Created Master Logsheet'
                    )
                })
                .catch((e) => toastContext.sendToast(e.message, 'error'))
        } else {
            const stationIds = [...Object.values(selectedZoneIds)].flat()
            const zoneIds = Object.keys(selectedZoneIds).map((value) =>
                parseInt(value)
            )

            await updateMasterLogsheet(
                props.masterLogsheetId,
                formValues.name,
                stationIds,
                zoneIds
            )
                .then(() => {
                    props?.onSuccess?.()
                    toastContext.sendToast(
                        'Successfully Updated Master Logsheet'
                    )
                })
                .catch((e) => toastContext.sendToast(e.message, 'error'))
        }
        setIsLoading(false)
        props.setDisplayModal(false)
    }

    if (isLoading) {
        return (
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={'xs'}
            >
                <LoaderWrapper></LoaderWrapper>
            </Dialog>
        )
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogContent
                sx={{
                    width: '440px',
                }}
            >
                <DialogContentText id="create-zone-error">
                    {error}
                </DialogContentText>

                <form onSubmit={handleSubmit}>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        name="name"
                        label="Master Logsheet Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={handleInputChange}
                        value={formValues.name}
                    />

                    {props.operation === 'Create' ? (
                        zonesWithStations.length > 0 ? (
                            <Box sx={{ my: 2 }}>
                                {zonesWithStations.some(
                                    (zone) => zone.masterLogsheetId === null
                                ) ? (
                                    <Typography mb={1}>
                                        Choose which zones to associated with
                                        this master logsheet:
                                    </Typography>
                                ) : (
                                    <Typography mb={1}>
                                        All zones are already associated
                                    </Typography>
                                )}
                                <FormGroup>
                                    {zonesWithStations.map(
                                        (zoneWithStations, idx) => {
                                            return zoneWithStations.stations
                                                .length > 0 &&
                                                zoneWithStations.masterLogsheetId ===
                                                    null ? (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={
                                                                selectedZoneIds[
                                                                    zoneWithStations
                                                                        .id
                                                                ]
                                                            }
                                                            onChange={() => {
                                                                handleCheckZone(
                                                                    zoneWithStations
                                                                )
                                                            }}
                                                        />
                                                    }
                                                    label={
                                                        zoneWithStations.zoneTitle
                                                    }
                                                />
                                            ) : null
                                        }
                                    )}
                                </FormGroup>
                            </Box>
                        ) : (
                            <CircularProgress
                                sx={{ display: 'block', my: 2 }}
                            />
                        )
                    ) : // UPDATE
                    zonesWithStations.length > 0 ? (
                        <Box sx={{ my: 2 }}>
                            {zonesWithStations.some(
                                (zone) =>
                                    zone.masterLogsheetId === null ||
                                    zone.masterLogsheetId ==
                                        props.masterLogsheetId
                            ) ? (
                                <Typography mb={1}>Edit zones</Typography>
                            ) : (
                                <Typography mb={1}>
                                    All zones are already associated
                                </Typography>
                            )}
                            <FormGroup>
                                {zonesWithStations.map(
                                    (zoneWithStations, idx) => {
                                        return zoneWithStations.stations
                                            .length > 0 &&
                                            (zoneWithStations.masterLogsheetId ===
                                                null ||
                                                zoneWithStations.masterLogsheetId ==
                                                    props.masterLogsheetId) ? (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            !!selectedZoneIds[
                                                                zoneWithStations
                                                                    .id
                                                            ]
                                                        }
                                                        onChange={() => {
                                                            handleCheckZone(
                                                                zoneWithStations
                                                            )
                                                        }}
                                                    />
                                                }
                                                label={
                                                    zoneWithStations.zoneTitle
                                                }
                                            />
                                        ) : null
                                    }
                                )}
                            </FormGroup>
                        </Box>
                    ) : (
                        <CircularProgress sx={{ display: 'block', my: 2 }} />
                    )}
                </form>
            </DialogContent>
            <DialogActions>
                <Typography sx={{ color: 'red' }}>{recommendation}</Typography>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSubmit}>
                    {props.operation === 'Create' ? 'Create' : 'Update'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
