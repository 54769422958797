import React, { useEffect, useState } from 'react'
import {
    getMainLogsheet,
    getMainLogsheetStationOrdering,
    updateMainLogsheetStationOrdering,
} from '../actions'
import useLoader from '../../../hooks/useLoader'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import {
    DndContext,
    useSensor,
    useSensors,
    PointerSensor,
    closestCenter,
} from '@dnd-kit/core'
import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

const SortableTableRow = ({ row }) => {
    const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({
            id: row.id,
        })

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    }

    return (
        <TableRow ref={setNodeRef} style={style} {...attributes} {...listeners}>
            <TableCell component="th" scope="row">
                {row.stationTitle}
            </TableCell>
            <TableCell align="right">{row.stationDescription}</TableCell>
            <TableCell align="right">{row.rangeLow}</TableCell>
            <TableCell align="right">{row.rangeHigh}</TableCell>
            <TableCell align="right">{row.target}</TableCell>
            <TableCell align="right">{row.measurementUnit}</TableCell>
        </TableRow>
    )
}

const MasterLogsheetOrganizingTablePath = ({ match }) => {
    const masterLogsheetId = match.params.masterLogsheetId
    const [mainLogsheet, setMainLogsheet] = useState({})
    const [rows, setRows] = useState([])
    const [LoaderWrapper, setIsLoading] = useLoader()

    const sensors = useSensors(useSensor(PointerSensor))

    const submitReordering = async () => {
        setIsLoading(true)
        const orderingJSON = {}
        for (const [index, station] of rows.entries()) {
            orderingJSON[station.id] = index + 1
        }

        await updateMainLogsheetStationOrdering(masterLogsheetId, orderingJSON)
        setIsLoading(false)
    }

    const handleDragEnd = (event) => {
        const { active, over } = event
        if (active && over && active.id !== over.id) {
            const oldIndex = rows.findIndex((row) => row.id === active.id)
            const newIndex = rows.findIndex((row) => row.id === over.id)
            setRows((items) => arrayMove(items, oldIndex, newIndex))
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)
            try {
                const [mainLogsheetResponse, mainLogsheetOrderingResponse] =
                    await Promise.all([
                        getMainLogsheet(masterLogsheetId),
                        getMainLogsheetStationOrdering(masterLogsheetId),
                    ])

                if (mainLogsheetResponse) {
                    let reorderableRows = mainLogsheetResponse?.stations || []

                    if (mainLogsheetOrderingResponse) {
                        reorderableRows = [
                            ...reorderableRows.sort((a, b) => {
                                return (
                                    mainLogsheetOrderingResponse?.[a.id] -
                                    mainLogsheetOrderingResponse?.[b.id]
                                )
                            }),
                        ]
                    }

                    setMainLogsheet(mainLogsheetResponse)
                    setRows(reorderableRows)
                }
            } catch (error) {
                console.error(error)
            } finally {
                setIsLoading(false)
            }
        }
        fetchData()
    }, [masterLogsheetId])

    return (
        <LoaderWrapper>
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
            >
                <SortableContext
                    items={rows.map((row) => row.id)}
                    strategy={verticalListSortingStrategy}
                >
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Station Name</TableCell>
                                    <TableCell align="right">
                                        Station Description
                                    </TableCell>
                                    <TableCell align="right">
                                        Range Low
                                    </TableCell>
                                    <TableCell align="right">
                                        Range High
                                    </TableCell>
                                    <TableCell align="right">Target</TableCell>
                                    <TableCell align="right">
                                        Measurement Unit
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows && rows.length > 0 ? (
                                    rows.map((row) => (
                                        <SortableTableRow
                                            key={row.id}
                                            row={row}
                                        />
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={6} align="center">
                                            No data available
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </SortableContext>
            </DndContext>
            <Button
                variant="contained"
                color="primary"
                onClick={submitReordering}
                style={{ marginTop: '16px' }}
            >
                Submit Reordering
            </Button>
        </LoaderWrapper>
    )
}

export default MasterLogsheetOrganizingTablePath
