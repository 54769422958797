import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { capitalize } from 'lodash'
import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    CircularProgress,
    Divider,
    Paper,
    SVGIcon,
    Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import CreateUser from './CreateUser'

const roleMap = {
    0: 'Super Admin',
    1: 'Administrator',
    2: 'General User',
}

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: '900px',
    },
    accountIcon: {
        height: '60px',
        width: '60px',
    },
    adminAccountMetaContainer: {
        padding: theme.spacing(2),
        display: 'flex',
        justifyContent: 'space-between',
    },
    adminAccountMeta: {
        marginTop: '16px',
    },
    cardRoot: {
        width: '340px',
        margin: theme.spacing(3),
    },
    cardHeading: {
        paddingTop: theme.spacing(3),
        margin: `0 auto ${theme.spacing(2)}px`,
        display: 'flex',
        justifyContent: 'space-between',
        width: '90%',
    },
    createUserButton: {
        margin: `0 ${theme.spacing(3)}px`,
        justifySelf: 'flex-end',
    },
    userContainer: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    userButtonContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    userButton: {
        display: 'block',

        width: 'fit-content',
        margin: theme.spacing(2),
    },
}))
// Allows an admin to delete a user from their association.
const DeleteConfirmation = ({ open, onClose, onConfirmation, userName }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Are you sure you want to delete {userName}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {userName} will be permanently deleted from your account.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button
                    onClick={async () => {
                        await onConfirmation().then(() => onClose())
                    }}
                    color="primary"
                    autoFocus
                >
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    )
}

// Allows an admin to see and perform actions on other users.
export const IndividualUser = ({
    id,
    firstName,
    lastName,
    role,
    emailAddress,
    onUserDelete,
    visibleAdminOptions,
}) => {
    const classes = useStyles()
    const [userDeletePrompt, setDeletePrompt] = useState(false)

    const roleAllowedReset = role !== 0

    return (
        <Paper className={classes.cardRoot}>
            <DeleteConfirmation
                open={userDeletePrompt}
                onClose={setDeletePrompt.bind(this, false)}
                onConfirmation={onUserDelete.bind(this, id)}
                userName={`${firstName} ${lastName}`}
            />
            <Box className={classes.cardHeading}>
                <AccountCircleIcon className={classes.accountIcon} />
                <Box
                    style={{
                        alignSelf: 'center',
                        width: '74%',
                        wordBreak: 'break-all',
                    }}
                >
                    <Typography variant="h3">
                        {`${firstName} ${lastName}`}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ alignSelf: 'center' }}
                    >
                        {emailAddress}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ alignSelf: 'center' }}
                    >
                        {roleMap[role]}
                    </Typography>
                </Box>
            </Box>
            {visibleAdminOptions && (
                <>
                    <Divider></Divider>
                    <Box className={classes.userButtonContainer}>
                        {/* <Button
                            color="primary"
                            variant="contained"
                            className={classes.userButton}
                            // disabled={!roleAllowedReset}
                            disabled
                        >
                            Reset Password
                        </Button> */}
                        <Button
                            color="primary"
                            variant="contained"
                            className={classes.userButton}
                            disabled={!roleAllowedReset}
                            onClick={async (e) => {
                                // await onUserDelete(e.target.value)
                                setDeletePrompt(true)
                            }}
                        >
                            Delete
                        </Button>
                    </Box>
                </>
            )}
        </Paper>
    )
}
// Allows an admin to view all their users and see if they are an admin as well as create an user modal.
const AdminAccount = ({
    loadingComplete,
    userAccounts,
    onUserDelete,
    onUserCreate,
    currentRole,
}) => {
    const classes = useStyles()

    const [createUserModalDisplayed, setCreateUserModal] = useState(false)
    const isAdmin = currentRole < 2

    return (
        <Box className={classes.root}>
            {isAdmin && (
                <Box display="flex">
                    <Button
                        color="primary"
                        onClick={() => {
                            setCreateUserModal(!createUserModalDisplayed)
                        }}
                        className={classes.createUserButton}
                        variant="contained"
                        size="large"
                    >
                        Create User
                    </Button>
                </Box>
            )}
            <CreateUser
                active={createUserModalDisplayed}
                onClose={setCreateUserModal.bind(this, false)}
                onUserCreate={onUserCreate}
            />
            <Box className={classes.userContainer}>
                {userAccounts.map((userAccount) => (
                    <IndividualUser
                        {...userAccount}
                        onUserDelete={onUserDelete}
                        visibleAdminOptions={isAdmin}
                    />
                ))}
            </Box>
        </Box>
    )
}

AdminAccount.defaultProps = {
    loadingComplete: false,
    userAccounts: [],
    onUserDelete: () => {},
}

AdminAccount.propTypes = {
    loadingComplete: PropTypes.bool,
    userAccounts: PropTypes.array,
    onUserDelete: PropTypes.func,
    currentRole: PropTypes.number,
}

export default AdminAccount
