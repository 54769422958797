import React, { createContext, useEffect, useState } from 'react'
import _ from 'lodash'
import { THEMES } from 'src/constants'

// Context that sets the UI equal to the default settings initially
const defaultSettings = {
    direction: 'ltr',
    responsiveFontSizes: true,
    theme: THEMES.LIGHT,
}

// Restores the settings to the settings that were stored in local storage for the website if such storage does exist.
export const restoreSettings = () => {
    let settings = null

    try {
        const storedData = window.localStorage.getItem('settings')

        if (storedData) {
            settings = JSON.parse(storedData)
        }
    } catch (err) {
        console.error(err)
        // If stored data is not a strigified JSON this will fail,
        // that's why we catch the error
    }

    return settings
}

// Stores the settings of the web page into local storage.
export const storeSettings = (settings) => {
    window.localStorage.setItem('settings', JSON.stringify(settings))
}

// Gives other classes access to the setting context here, with the context having default settings.
const SettingsContext = createContext({
    settings: defaultSettings,
    saveSettings: () => {},
})
// Settings provider that will be passed to all of its children about the state of the settings.
export const SettingsProvider = ({ settings, children }) => {
    const [currentSettings, setCurrentSettings] = useState(
        settings || defaultSettings
    )
    // Update the settings to include what the user changed.
    const handleSaveSettings = (update = {}) => {
        const mergedSettings = _.merge({}, currentSettings, update)

        setCurrentSettings(mergedSettings)
        storeSettings(mergedSettings)
    }
    // If there is settings to be restored, then set the current settings to be the local storage settings during the initial render.
    useEffect(() => {
        const restoredSettings = restoreSettings()

        if (restoredSettings) {
            setCurrentSettings(restoredSettings)
        }
    }, [])

    // For every change in the current settings state, the directionality of the DOM will be that of the webpage.
    useEffect(() => {
        document.dir = currentSettings.direction
    }, [currentSettings])

    //Gives child elements access to 'settings' to view current settings and 'saveSettings' to change settings
    return (
        <SettingsContext.Provider
            value={{
                settings: currentSettings,
                saveSettings: handleSaveSettings,
            }}
        >
            {children}
        </SettingsContext.Provider>
    )
}

export const SettingsConsumer = SettingsContext.Consumer

export default SettingsContext
