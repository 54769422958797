import React, { useState } from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { createOrganization, updateOrganization } from '../actions'
import { useToastContext } from '../../../context/ToastContext'
import useCrudLoader from '../../../hooks/useCrudLoader'
import { useAuth } from 'oidc-react'

export default function CreateOrganization(props) {
    const [open, setOpen] = React.useState(true)
    const [formValues, setFormValues] = useState({})
    const [error, setError] = useState(false)
    const toastContext = useToastContext()
    const [LoaderWrapper, setIsLoading, isLoading] = useCrudLoader()
    const auth = useAuth()

    const handleClose = () => {
        setOpen(false)
        props.setDisplayModal(false)
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target

        setFormValues({
            ...formValues,
            [name]: value,
        })
    }

    const handleSubmit = async (event) => {
        if (isLoading) {
            return
        }
        event.preventDefault()

        if (
            (formValues?.organizationName?.trim().length > 0 &&
                formValues?.ownerEmail?.trim()?.length > 0) ||
            (props.operation === 'update' &&
                (formValues?.organizationName?.trim().length > 0 ||
                    formValues?.ownerEmail?.trim()?.length > 0))
        ) {
            setIsLoading(true)

            if (props.operation === 'create') {
                await createOrganization(
                    formValues.organizationName,
                    formValues.ownerEmail
                )
                    .then(() => {
                        props?.onSuccess?.()
                        toastContext.sendToast(
                            'Successfully Created Organization'
                        )
                    })
                    .catch((e) => toastContext.sendToast(e.message, 'error'))
            } else {
                await updateOrganization(
                    auth.userData.profile['custom:user_organizationId'],
                    formValues.organizationName,
                    formValues.ownerEmail
                )
                    .then(() => {
                        props?.onSuccess?.()
                        toastContext.sendToast(
                            'Successfully Updated Organization'
                        )
                    })
                    .catch((e) => toastContext.sendToast(e.message, 'error'))
            }

            setIsLoading(false)
            props.setDisplayModal(false)
        } else {
            setError('All fields are required')
        }
    }

    if (isLoading) {
        return (
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={'xs'}
            >
                <LoaderWrapper></LoaderWrapper>
            </Dialog>
        )
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogContent>
                <DialogContentText>{error}</DialogContentText>

                <form onSubmit={handleSubmit}>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="organizationName"
                        name="organizationName"
                        label="Organization Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={handleInputChange}
                        mb={3}
                    />

                    <TextField
                        margin="dense"
                        id="ownerEmail"
                        name="ownerEmail"
                        label="Owner Email"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={handleInputChange}
                    />
                </form>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSubmit}>{props.operation}</Button>
            </DialogActions>
        </Dialog>
    )
}
