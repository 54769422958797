import React, { useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Box, Typography, Breadcrumbs, Link } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { capitalize } from 'lodash'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { useLocation } from 'react-router-dom'
import { getAllZones } from '../../views/Zones/actions'

import localforage from 'localforage'
import {
    getAllMasterLogsheets,
    getIndividualMasterLogsheet,
} from '../../views/Logsheets/actions'
import { useToastContext } from '../../context/ToastContext'
import useLoader from '../../hooks/useLoader'

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
        marginBottom: '10px',
        fontFamily: 'Montserrat',
        fontWeight: 400,
    },
    link: {
        fontFamily: 'Montserrat',
        fontSize: '13px',

        borderRadius: '50px',
        padding: '10px 15px, 10px, 10',
        border: '2px solid transparent',
        '&:hover': {
            color: 'black',
        },
    },
    nonlink: {
        borderRadius: '50px',
        padding: '150px 15px, 10px, 10',
        border: '2px solid transparent',
    },
}))

// Displays the breadcrumbs of the page by partitioning everything into subpaths.
const BreadCrumbs = ({ className, ...rest }) => {
    const [masterLogsheets, setMasterLogsheets] = useState([])
    const [allLogsheets, setAllLogsheets] = useState([])
    const [zones, setZones] = useState([])
    const [LoaderWrapper, setIsLoading] = useLoader()
    const toastContext = useToastContext()

    const classes = useStyles()
    const location = useLocation()
    const locationSubPath = location.pathname.split('/')
    const isBasePath = location.pathname === '/'
    let isCommentsPage = false

    if (locationSubPath.at(-1) === 'comments') {
        locationSubPath.pop()
        if (locationSubPath.at(-2) === 'station') {
            locationSubPath.pop()
            locationSubPath.pop()
        }
        isCommentsPage = true
    }

    const asyncFetchMasterLogsheets = async () => {
        if (!navigator.onLine) {
            const allMasterLogsheets = await localforage.getItem('allLogsheets')
            if (allMasterLogsheets) {
                setMasterLogsheets(allMasterLogsheets)

                setIsLoading(false)
                return
            }
        }

        const masterLogsheetResults = await getAllMasterLogsheets().catch((e) =>
            toastContext.sendToast('Error Fetching Data', 'error')
        )
        setMasterLogsheets(masterLogsheetResults)
    }
    const asyncFetchLogsheets = async () => {
        try {
            const logsheetsResults = await Promise.all(
                masterLogsheets.map(async (master) => {
                    return await getIndividualMasterLogsheet(master.id)
                })
            )

            const combinedLogsheets = [...logsheetsResults]

            logsheetsResults.forEach((master) => {
                master.logsheets?.forEach((logsheet) => {
                    combinedLogsheets.push(logsheet)
                })
            })

            setAllLogsheets(combinedLogsheets)
        } catch (error) {
            toastContext.sendToast('Error Fetching Data', 'error')
        }
    }
    const asyncFetchZones = async () => {
        try {
            const zoneResults = await getAllZones()
            setZones(zoneResults)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        asyncFetchMasterLogsheets()
    }, [])
    useEffect(() => {
        asyncFetchLogsheets()
    }, [masterLogsheets])
    useEffect(() => {
        asyncFetchZones()
    }, [])

    return (
        <Box className={clsx(classes.root, className)} {...rest}>
            {!isBasePath && (
                <Breadcrumbs
                    // separator={<NavigateNextIcon fontSize="small" />}
                    separator={
                        <Box display="flex" alignItems="center" padding="0 0px">
                            <ArrowRightIcon />
                        </Box>
                    }
                    aria-label="breadcrumb"
                    sx={{ margin: 0 }}
                >
                    <Link
                        color="inherit"
                        to="/"
                        component={RouterLink}
                        className={classes.link}
                        style={{
                            fontSize: '14px',
                            fontFamily: 'Montserrat',
                            fontStyle: 'italic',
                        }}
                    >
                        Dashboard
                    </Link>

                    {locationSubPath
                        .filter((key) => !!key)
                        .map((entry, index, array) => {
                            if (
                                array[index] === 'logsheet' ||
                                array[index] === 'signoff'
                            ) {
                                return null
                            }

                            //For the case where there is a NFC tag in the URL
                            if (
                                index >= 2 &&
                                (array[index - 2] == 'logsheet' ||
                                    (array[index - 2] == 'zones' &&
                                        entry != 'summary'))
                            ) {
                                return
                            }

                            let backPath = ''
                            let text = capitalize(entry)
                            let modifiedIndex = index + 1

                            //Both needed for the cases where there is a NFC tag in the URL
                            if (
                                index >= 3 &&
                                array[index - 1] == 'logsheet' &&
                                array.length == 5
                            ) {
                                modifiedIndex++
                            }
                            if (
                                index >= 3 &&
                                array[index - 1] == 'signoff' &&
                                array.length == 5
                            ) {
                                modifiedIndex++
                            }
                            if (
                                index >= 1 &&
                                array[index - 1] == 'zones' &&
                                array.length == 3 &&
                                array[index + 1] != 'summary'
                            ) {
                                modifiedIndex++
                            }

                            const isLeafPath = modifiedIndex < array.length
                            for (let i = 0; i < modifiedIndex; i++) {
                                backPath += array[i] + '/'
                            }
                            backPath = backPath.substring(
                                0,
                                backPath.length - 1
                            )
                            return !isLeafPath && !isCommentsPage ? (
                                <Typography
                                    color="textPrimary"
                                    key={Date.now()}
                                    className={classes.nonlink}
                                    style={{
                                        fontSize: '14px',
                                        fontFamily: 'Montserrat',
                                        fontStyle: 'italic',
                                    }}
                                >
                                    {array[index] === 'zones' &&
                                        array.length === 1 &&
                                        'Zones'}
                                    {array[0] === 'zones' &&
                                        array.length === 2 &&
                                        zones.find(
                                            (zone) =>
                                                zone.id === parseInt(entry)
                                        )?.zoneTitle}
                                    {array[0] === 'signoffs' &&
                                        array.length === 2 &&
                                        allLogsheets.find(
                                            (logsheet) =>
                                                logsheet.id === parseInt(entry)
                                        )?.masterLogsheetTitle}
                                    {array[array.length - 1] === 'signoffs' &&
                                        array.length === 3 &&
                                        entry !== 'logsheets' &&
                                        'Signoffs'}
                                    {array[array.length - 1] === 'table' &&
                                        array.length === 3 &&
                                        entry !== 'logsheets' &&
                                        'Table'}
                                    {array[index] === 'logsheets' &&
                                        array.length === 1 &&
                                        'Master Logsheets'}
                                    {array[index - 1] === 'logsheets' &&
                                        array.length === 2 &&
                                        allLogsheets.find(
                                            (logsheet) =>
                                                logsheet.id === parseInt(entry)
                                        )?.masterLogsheetTitle}
                                    {array[index - 1] === 'logsheet' &&
                                        array.length === 4 &&
                                        allLogsheets.find(
                                            (logsheet) =>
                                                logsheet.id ===
                                                    parseInt(entry) &&
                                                logsheet.logsheetTitle
                                        )?.logsheetTitle}
                                </Typography>
                            ) : (
                                <Link
                                    key={Date.now()}
                                    color="inherit"
                                    to={`/${backPath}`}
                                    component={RouterLink}
                                    className={classes.link}
                                    style={{
                                        fontSize: '14px',
                                        fontFamily: 'Montserrat',
                                        fontStyle: 'italic',
                                    }}
                                >
                                    {array[0] === 'zones' &&
                                        array.length === 2 &&
                                        'Zones'}
                                    {array[array.length - 1] === 'signoffs' &&
                                        array.length === 3 &&
                                        entry === 'logsheets' &&
                                        'Master Logsheets'}
                                    {array[array.length - 1] === 'signoffs' &&
                                        array.length === 3 &&
                                        entry !== 'logsheets' &&
                                        allLogsheets.find(
                                            (logsheet) =>
                                                logsheet.id === parseInt(entry)
                                        )?.masterLogsheetTitle}
                                    {array[array.length - 1] === 'table' &&
                                        array.length === 3 &&
                                        entry === 'logsheets' &&
                                        'Master Logsheets'}
                                    {array[array.length - 1] === 'table' &&
                                        array.length === 3 &&
                                        entry !== 'logsheets' &&
                                        allLogsheets.find(
                                            (logsheet) =>
                                                logsheet.id === parseInt(entry)
                                        )?.masterLogsheetTitle}
                                    {array[index] === 'logsheets' &&
                                        array.length === 2 &&
                                        'Master Logsheets'}
                                    {array[index] === 'logsheets' &&
                                        array.length === 4 &&
                                        'Master Logsheets'}
                                    {array[index] !== 'logsheets' &&
                                        array.length === 4 &&
                                        allLogsheets.find(
                                            (logsheet) =>
                                                logsheet.id === parseInt(entry)
                                        )?.masterLogsheetTitle}
                                </Link>
                            )
                        })}

                    {isCommentsPage ? (
                        <Typography
                            color="textPrimary"
                            key={Date.now()}
                            className={classes.nonlink}
                            style={{
                                fontSize: '14px',
                                fontFamily: 'Montserrat',
                                fontStyle: 'italic',
                            }}
                        >
                            Comments
                        </Typography>
                    ) : null}
                </Breadcrumbs> // array : logsheets16
            )}
        </Box>
    )
}

BreadCrumbs.propTypes = {
    orderedEndpoints: PropTypes.array,
}

export default BreadCrumbs
