import axios from 'axios'

//This file contains functions that use axios to send and get information from the backend
export const uploadImage = async (organizationId, fileName, file) => {
    let key
    let formData = new FormData()
    formData.append('image', file)
    formData.append('fileName', fileName)
    formData.append('organizationId', '1')

    await axios
        .post(`/api/images`, formData)
        .then(({ data }) => (key = data.key))
        .catch((err) => console.error(err.response.data))

    return key
}

export const createStationMeasurement = async (
    zoneId,
    stationId,
    measurementValue,
    logsheetId,
    comment = null
) => {
    return await axios
        .post(`/api/zones/${zoneId}/stations/${stationId}/measurements`, {
            measurementValue,
            logsheetId,
            comment,
        })
        .then(({ data }) => data)
}

export const setBrokenStation = async (zoneId, stationId, brokenVal) => {
    return await axios
        .post(`/api/zones/${zoneId}/stations/${stationId}/broken`, {
            brokenVal,
        })
        .then(({ data }) => data)
        .catch((err) => console.error(err))
}

export const getZoneMeasurements = async (zoneId) => {
    return await axios
        .get(`/api/zones/${zoneId}/measurements`)
        .then(({ data }) => data)
        .catch((err) => console.error(err))
}

export const downloadRecentMeasurements = async (zoneId, stationId) => {
    return await getZoneMeasurements(zoneId)
        .then((stations) => {
            for (const station of stations) {
                if (station.id === stationId) {
                    return station.stationMeasurements.slice(0, 10) ?? []
                }
            }
        })
        .catch((err) => console.error(err))
}

export const updateRecentMeasurements = async (
    zoneId,
    stationId,
    recentMeasurements
) => {
    const measurements =
        (await downloadRecentMeasurements(
            zoneId,
            stationId,
            recentMeasurements
        )) ?? []

    const ids = new Set(recentMeasurements.map(({ id }) => id))

    let mergedMeasurements = [
        ...recentMeasurements,
        ...measurements.filter(({ id }) => !ids.has(id)),
    ]

    let sortedMeasurements = mergedMeasurements.sort((a, b) => {
        return b.createdAt.localeCompare(a.createdAt)
    })
    return sortedMeasurements.slice(0, 10)
}

export const deleteRecentMeasurements = async (
    zoneId,
    stationId,
    measurementId
) => {
    return await axios
        .delete(
            `/api/zones/${zoneId}/stations/${stationId}/measurements/${measurementId}`
        )
        .then(({ data }) => data)
}

export const getLogsheetComments = async (masterLogsheetId, logsheetId) => {
    return await axios
        .get(
            `/api/logsheets/${masterLogsheetId}/logsheet/${logsheetId}/comments`
        )
        .then(({ data }) => data)
}

export const createLogsheetComment = async (
    masterLogsheetId,
    logsheetId,
    comment
) => {
    return await axios
        .post(
            `/api/logsheets/${masterLogsheetId}/logsheet/${logsheetId}/comments`,
            {
                comment,
            }
        )
        .then(({ data }) => data)
}

export const getStationComments = async (stationId) => {
    return await axios
        .get(`/api/stations/${stationId}/comments`)
        .then(({ data }) => data)
}

export const createStationComment = async (stationId, comment) => {
    return await axios
        .post(`/api/stations/${stationId}/comments`, {
            comment,
        })
        .then(({ data }) => data)
}
