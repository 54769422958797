import React, { useState, useEffect } from 'react'
import localforage from 'localforage'
import MasterLogsheets from './MasterLogsheets'
import { getAllMasterLogsheets } from './actions'
import { useToastContext } from '../../context/ToastContext'
import useLoader from '../../hooks/useLoader'

//Renders MasterLogsheets when the url path is a match
//Uses the useLoader hook to display the waiting screen while master logsheet data
//is bieng retrieved from either localforage or the database
const LogsheetsPath = () => {
    const [masterLogsheets, setMasterLogsheets] = useState([])
    const [LoaderWrapper, setIsLoading] = useLoader()
    const toastContext = useToastContext()

    const asyncFetchMasterLogsheets = async () => {
        setIsLoading(true)

        if (!navigator.onLine) {
            const allMasterLogsheets = await localforage.getItem('allLogsheets')
            if (allMasterLogsheets) {
                setMasterLogsheets(allMasterLogsheets)

                setIsLoading(false)
                return
            }
        }

        const masterLogsheetResults = await getAllMasterLogsheets().catch((e) =>
            toastContext.sendToast('Error Fetching Data', 'error')
        )
        setMasterLogsheets(masterLogsheetResults)
        setIsLoading(false)
    }

    useEffect(() => {
        asyncFetchMasterLogsheets()
    }, [])

    return (
        <LoaderWrapper>
            <MasterLogsheets
                masterLogsheets={masterLogsheets}
                asyncFetchMasterLogsheets={asyncFetchMasterLogsheets}
            />
        </LoaderWrapper>
    )
}

export default LogsheetsPath
