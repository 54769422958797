import React, { useState, useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import {
    Box,
    Button,
    Typography,
    InputLabel,
    NativeSelect,
    FormControl,
    Stack,
    Grid,
    IconButton,
    InputAdornment,
} from '@mui/material'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useToastContext } from '../../../context/ToastContext'
import { createStation, updateStation } from '../actions'


const CopyStation = ({
    displayCopyStation,
    onSetDisplayCopyStation,
    zoneId,
    station = {},
    stationId,
    onSuccess = () => {},

}) => {
    const [location, setLocation] = useState('')
    const history = useHistory()
    const [formValues, setFormValues] = useState({})
    const [error, setError] = useState(false)
    const toastContext = useToastContext()
    const onCopy = async () => {
        await createStation(zoneId,
            station.stationTitle,
            station.stationDescription,
            station.target,
            station.rangeLow,
            station.rangeHigh,
            station.measurementUnit,
            station.measurementOptions)
            .then(() => {
                onSuccess?.()
                toastContext.sendToast('Successfully Copied Station')
            })
            .catch((e) => toastContext.sendToast(e.message, 'error'))
        onSetDisplayCopyStation(false)
    }
    return (
        displayCopyStation && (
            <Dialog
                open={displayCopyStation}
                onClose={() => onSetDisplayCopyStation(false)}
            >
                <DialogContent>
                    <DialogContentText id="create-zone-error">
                        {error}
                    </DialogContentText>
                    <DialogContentText>
                        Are you sure you want to copy this station? This action
                        cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => onSetDisplayCopyStation(false)}>
                        Cancel
                    </Button>
                    <Button onClick={onCopy}>Copy</Button>
                </DialogActions>
            </Dialog>
        )
    )

}
export default CopyStation