import React from 'react'
import { PieChart } from '@mui/x-charts/PieChart'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { styles } from '../../../theme/styles'

export const MultChoicePie = ({
    value,
    measurementOptions,
    title,
    target,
    units,
    min,
    max,
}) => {
    const data = measurementOptions.map((option, idx) => {
        return {
            id: idx,
            value: 100 / measurementOptions.length,
            option: option,
            color: value === option ? '#6fe4ca' : '#f0f0f0',
        }
    })

    return (
        <Box
            sx={{
                position: 'relative',
                textAlign: 'center',
                height: '320px',
                width: '300px',
            }}
        >
            <Typography
                sx={{
                    paddingTop: '15px',
                    top: '10%',
                    left: '0%',
                    fontFamily: 'Montserrat',
                    fontSize: '16px',
                    fontWeight: 700,
                    letterSpacing: '-0.7px',
                }}
            >
                {title}
            </Typography>
            <Typography
                //  variant="h1"
                sx={{
                    fontWeight: 700,
                    fontSize: 45,
                    position: 'absolute',
                    top: '27%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                }}
            >
                {value}
            </Typography>

            <PieChart
                series={[
                    {
                        data: data,
                        arcLabel: (item) => `${item.option}`,
                        innerRadius: 30,
                        outerRadius: 120,
                        paddingAngle: 5,
                        cornerRadius: 5,
                        startAngle: -90,
                        endAngle: 90,
                        cx: 150,
                        cy: 200,
                    },
                ]}
                width={300}
                height={320}
            />
        </Box>
    )
}
