import React, { useState, useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { styles } from '../../../theme/styles'
import {
    Box,
    Paper,
    TextField,
    InputAdornment,
    IconButton,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { getMasterLogbook, createLogbook } from '../actions'
import { useToastContext } from '../../../context/ToastContext'
import useLoader from '../../../hooks/useLoader'
import { ReactComponent as MasterLogsheetIcon } from '../../../icons/masterLogsheet.svg'
import { ReactComponent as CreateIcon } from '../../../icons/buttons/create.svg'

const IndividualMasterLogbook = ({ match }) => {
    const masterLogbookId = match.params.masterLogbookId
    const [logbooks, setLogbooks] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [LoaderWrapper, setIsLoading] = useLoader()
    const toastContext = useToastContext()

    const asyncFetchMasterLogbooks = async () => {
        setIsLoading(true)

        const masterLogbook = await getMasterLogbook(
            masterLogbookId,
            searchTerm
        ).catch((e) => toastContext.sendToast('Error Fetching Data', 'error'))
        setLogbooks(masterLogbook?.logbooks)
        setIsLoading(false)
    }

    useEffect(() => {
        asyncFetchMasterLogbooks()
    }, [])

    return (
        <LoaderWrapper>
            <Box sx={styles.buttonList}>
                <div
                    className="normalButton"
                    onClick={async () => {
                        try {
                            await createLogbook(masterLogbookId)
                        } catch (e) {
                            console.log(e)
                            toastContext.sendToast(
                                'Error creating logbook. One might already exist within the past 24h',
                                'error'
                            )
                        }
                        // asyncFetchMasterLogbooks()
                    }}
                >
                    <div className="buttonIconBlock">
                        <CreateIcon />
                    </div>
                    <div className="buttonTextBlock">
                        <div className="buttonTextBiggerBlock">Create</div>
                        <div className="buttonTextSmallerBlock">logbook</div>
                    </div>
                </div>
            </Box>

            <Box sx={styles.cardContainer}>
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Search by keywords"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    sx={{ mb: 2 }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={asyncFetchMasterLogbooks}>
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                {Array.isArray(logbooks) &&
                    logbooks
                        .sort(
                            (a, b) =>
                                new Date(b.createdAt) - new Date(a.createdAt)
                        )
                        .map((logbook) => (
                            <Box
                                component={RouterLink}
                                to={`/logbooks/${masterLogbookId}/logbook/${logbook.id}`}
                                style={{ textDecoration: 'none', gap: '5px' }}
                                sx={{
                                    ':hover': {
                                        boxShadow:
                                            '0px 10px 15px rgba(0, 0, 0, 0.2)',
                                    },
                                }}
                            >
                                <Paper
                                    key={logbook.id}
                                    elevation={0}
                                    style={styles.paper}
                                >
                                    <Box sx={styles.cardImageSmallContainer}>
                                        <MasterLogsheetIcon
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        />
                                    </Box>
                                    <Box sx={styles.cardTextContainer}>
                                        <div style={styles.cardTextBig}>
                                            {logbook.logbookTitle} -{' '}
                                            {new Date(
                                                logbook.createdAt
                                            ).toLocaleDateString('en-US', {
                                                year: 'numeric', // "2023"
                                                month: 'long', // "July"
                                                day: 'numeric', // "20"
                                                weekday: 'long', // "Monday"
                                            })}{' '}
                                            {new Date(
                                                logbook.createdAt
                                            ).toLocaleTimeString('en-US', {
                                                hour: '2-digit', // "02"
                                                minute: '2-digit', // "30"
                                                hour12: true, // AM/PM format
                                            })}
                                        </div>
                                    </Box>
                                </Paper>
                            </Box>
                        ))}
            </Box>
        </LoaderWrapper>
    )
}

export default IndividualMasterLogbook
