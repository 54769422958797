import React, { useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { deleteMasterLogsheet } from './actions'
import { useToastContext } from '../../context/ToastContext'
import useCrudLoader from '../../hooks/useCrudLoader'

//Component called to show user an option to delete a masterlogsheet
const DeleteMasterLogsheet = (props) => {
    const history = useHistory()
    const [open, setOpen] = useState(true)
    const toastContext = useToastContext()
    const [LoaderWrapper, setIsLoading, isLoading] = useCrudLoader()

    //Sends request to backend to delete the master logsheet
    const handleSubmit = async (event) => {
        if(isLoading) {
            return;
        }
        event.preventDefault()
        setIsLoading(true)

        await deleteMasterLogsheet(props.masterLogsheetId)
            .then(() =>
                toastContext.sendToast('Successfully Deleted Master Logsheet')
            )
            .catch((e) => toastContext.sendToast(e.message, 'error'))

        history.push(`/logsheets`)
        setIsLoading(false)
    }

    const handleClose = () => {
        setOpen(false)
        props.setDisplayModal(false)
    }

    if (isLoading) {
        return (
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={'xs'}
            >
                <LoaderWrapper>
                </LoaderWrapper>
            </Dialog>
        )
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete this master logsheet? This
                    action cannot be undone.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSubmit}>Delete</Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteMasterLogsheet
