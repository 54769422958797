import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'

import NavBar from './NavBar'
import TopBar from './TopBar'
import BreadCrumbs from './BreadCrumbs'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: '100vh',
        overflow: 'hidden',
        width: '100%',
    },
    navBar: {
        zIndex: 1,
        // width: '280px',
        display: 'flex',

        // flexGrow: 1,
        // flexShrink: 1,
        height: '100%',
        boxShadow: '1px 0 1px rgba(0, 0, 0, 0.1)',
    },
    mainContent: {
        flexGrow: 1,
        flexShrink: 1,
        display: 'flex',
        flexDirection: 'column',
    },

    topBar: {
        zIndex: 0,
        width: '100%',
    },

    content: {
        flexGrow: 1,
        overflow: 'auto',
        paddingTop: '14px',
        paddingLeft: '20px',
        paddingRight: '10px',
    },
}))

const Header = ({ children }) => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <div className={classes.navBar}>
                <NavBar />
            </div>
            <div className={classes.mainContent}>
                <div className={classes.topBar}>
                    <TopBar />
                </div>
                <div className={classes.content}>
                    <BreadCrumbs />
                    {children}
                </div>
            </div>
        </div>
    )
}

Header.propTypes = {
    children: PropTypes.node,
}

export default Header
