import React from 'react';
import { Box, Button, Typography } from '@mui/material';

//Page shown when there is a 404 error
export default function PageError() {
  return (
    <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        textAlign="center"
    >
      <Typography variant="h1" color="black">404</Typography>
      <Typography variant="h3" color="black">This page doesn't exist</Typography>
    </Box>
  );
}
