import React, { useState, useEffect } from 'react'
import { useShowContentBasedOnRoles } from '../../../hooks/useShowContentBasedOnRoles'
import { Box, Button, Divider, Paper, Typography } from '@mui/material'
import FormatListNumberedRtlOutlinedIcon from '@mui/icons-material/FormatListNumberedRtlOutlined' // table
import GradingIcon from '@mui/icons-material/Grading'
import { Link as RouterLink } from 'react-router-dom'
import { styles } from '../../../theme/styles'
import { ReactComponent as SignoffIcon1AMSignoff } from '../../../icons/signoffs/1AMSignoff.svg'
import { ReactComponent as SignoffIcon2AMSignoff } from '../../../icons/signoffs/2AMSignoff.svg'
import { ReactComponent as SignoffIcon3AMSignoff } from '../../../icons/signoffs/3AMSignoff.svg'
import { ReactComponent as SignoffIcon4AMSignoff } from '../../../icons/signoffs/4AMSignoff.svg'
import { ReactComponent as SignoffIcon5AMSignoff } from '../../../icons/signoffs/5AMSignoff.svg'
import { ReactComponent as SignoffIcon6AMSignoff } from '../../../icons/signoffs/6AMSignoff.svg'
import { ReactComponent as SignoffIcon7AMSignoff } from '../../../icons/signoffs/7AMSignoff.svg'
import { ReactComponent as SignoffIcon8AMSignoff } from '../../../icons/signoffs/8AMSignoff.svg'
import { ReactComponent as SignoffIcon9AMSignoff } from '../../../icons/signoffs/9AMSignoff.svg'
import { ReactComponent as SignoffIcon10AMSignoff } from '../../../icons/signoffs/10AMSignoff.svg'
import { ReactComponent as SignoffIcon11AMSignoff } from '../../../icons/signoffs/11AMSignoff.svg'
import { ReactComponent as SignoffIcon12AMSignoff } from '../../../icons/signoffs/12AMSignoff.svg'
import { ReactComponent as SignoffIcon1PMSignoff } from '../../../icons/signoffs/1PMSignoff.svg'
import { ReactComponent as SignoffIcon2PMSignoff } from '../../../icons/signoffs/2PMSignoff.svg'
import { ReactComponent as SignoffIcon3PMSignoff } from '../../../icons/signoffs/3PMSignoff.svg'
import { ReactComponent as SignoffIcon4PMSignoff } from '../../../icons/signoffs/4PMSignoff.svg'
import { ReactComponent as SignoffIcon5PMSignoff } from '../../../icons/signoffs/5PMSignoff.svg'
import { ReactComponent as SignoffIcon6PMSignoff } from '../../../icons/signoffs/6PMSignoff.svg'
import { ReactComponent as SignoffIcon7PMSignoff } from '../../../icons/signoffs/7PMSignoff.svg'
import { ReactComponent as SignoffIcon8PMSignoff } from '../../../icons/signoffs/8PMSignoff.svg'
import { ReactComponent as SignoffIcon9PMSignoff } from '../../../icons/signoffs/9PMSignoff.svg'
import { ReactComponent as SignoffIcon10PMSignoff } from '../../../icons/signoffs/10PMSignoff.svg'
import { ReactComponent as SignoffIcon11PMSignoff } from '../../../icons/signoffs/11PMSignoff.svg'
import { ReactComponent as SignoffIcon12PMSignoff } from '../../../icons/signoffs/12PMSignoff.svg'
import { ReactComponent as SignoffIcon } from '../../../icons/signoffs/Signoff.svg'
import { ReactComponent as SignoffMaster } from '../../../icons/signoffs/signoffMaster.svg'
import { useHistory } from 'react-router-dom'
import { ReactComponent as TableIcon } from '../../../icons/buttons/table.svg'

export const SingleLogsheetSignOff = ({
    logsheetTitle,
    updatedAt,
    id,
    logsheets,
    lastSigned,
    lastSignedWithin16Hours,
}) => {
    const isMastLog = logsheets.find(
        (sheet) => sheet.id === id
    ).includesAllStations

    // const getColor = () => {
    //   let num = Math.abs(id);
    //   let lastDigit = num % 10;

    //   if (isMastLog) {
    //     return '#000000';
    //   }

    //   if (lastDigit === 0) {
    //     return '#B71C1C';
    //   } else if (lastDigit === 1) {
    //     return '#006064';
    //   } else if (lastDigit === 2) {
    //     return '#0D47A1';
    //   } else if (lastDigit === 3) {
    //     return '#880E4F';
    //   } else if (lastDigit === 4) {
    //     return '#004D40';
    //   } else if (lastDigit === 5) {
    //     return '#FF6F00';
    //   } else if (lastDigit === 6) {
    //     return '#827717';
    //   } else if (lastDigit === 7) {
    //     return '#33691E';
    //   } else if (lastDigit === 8) {
    //     return '#BF360C';
    //   } else if (lastDigit === 9) {
    //     return '#311B92';
    //   }
    //   // else if (lastDigit === 10) {
    //   //     return '#4A148C'
    //   // }
    //   // else if (lastDigit === 11) {
    //   //     return '#01579B'
    //   // }
    //   // else if (lastDigit === 12) {
    //   //     return '#880E4F'
    //   // }
    //   else {
    //     return '#000000';
    //   }
    // };
    const getIcon = (name) => {
        if (
            name.toLowerCase().includes('11 am') ||
            name.toLowerCase().includes('11am')
        ) {
            return <SignoffIcon11AMSignoff style={iconStyles} />
        } else if (
            name.toLowerCase().includes('12 am') ||
            name.toLowerCase().includes('12am')
        ) {
            return <SignoffIcon12AMSignoff style={iconStyles} />
        } else if (
            name.toLowerCase().includes('3 am') ||
            name.toLowerCase().includes('3am')
        ) {
            return <SignoffIcon3AMSignoff style={iconStyles} />
        } else if (
            name.toLowerCase().includes('4 am') ||
            name.toLowerCase().includes('4am')
        ) {
            return <SignoffIcon4AMSignoff style={iconStyles} />
        } else if (
            name.toLowerCase().includes('5 am') ||
            name.toLowerCase().includes('5am')
        ) {
            return <SignoffIcon5AMSignoff style={iconStyles} />
        } else if (
            name.toLowerCase().includes('6 am') ||
            name.toLowerCase().includes('6am')
        ) {
            return <SignoffIcon6AMSignoff style={iconStyles} />
        } else if (
            name.toLowerCase().includes('7 am') ||
            name.toLowerCase().includes('7am')
        ) {
            return <SignoffIcon7AMSignoff style={iconStyles} />
        } else if (
            name.toLowerCase().includes('8 am') ||
            name.toLowerCase().includes('8am')
        ) {
            return <SignoffIcon8AMSignoff style={iconStyles} />
        } else if (
            name.toLowerCase().includes('9 am') ||
            name.toLowerCase().includes('9am')
        ) {
            return <SignoffIcon9AMSignoff style={iconStyles} />
        } else if (
            name.toLowerCase().includes('10 am') ||
            name.toLowerCase().includes('10am')
        ) {
            return <SignoffIcon10AMSignoff style={iconStyles} />
        } else if (
            name.toLowerCase().includes('1 am') ||
            name.toLowerCase().includes('1am')
        ) {
            return <SignoffIcon1AMSignoff style={iconStyles} />
        } else if (
            name.toLowerCase().includes('2 am') ||
            name.toLowerCase().includes('2am')
        ) {
            return <SignoffIcon2AMSignoff style={iconStyles} />
        } else if (
            name.toLowerCase().includes('11 pm') ||
            name.toLowerCase().includes('11pm')
        ) {
            return <SignoffIcon11PMSignoff style={iconStyles} />
        } else if (
            name.toLowerCase().includes('12 pm') ||
            name.toLowerCase().includes('12pm')
        ) {
            return <SignoffIcon12PMSignoff style={iconStyles} />
        } else if (
            name.toLowerCase().includes('3 pm') ||
            name.toLowerCase().includes('3pm')
        ) {
            return <SignoffIcon3PMSignoff style={iconStyles} />
        } else if (
            name.toLowerCase().includes('4 pm') ||
            name.toLowerCase().includes('4pm')
        ) {
            return <SignoffIcon4PMSignoff style={iconStyles} />
        } else if (
            name.toLowerCase().includes('5 pm') ||
            name.toLowerCase().includes('5pm')
        ) {
            return <SignoffIcon5PMSignoff style={iconStyles} />
        } else if (
            name.toLowerCase().includes('6 pm') ||
            name.toLowerCase().includes('6pm')
        ) {
            return <SignoffIcon6PMSignoff style={iconStyles} />
        } else if (
            name.toLowerCase().includes('7 pm') ||
            name.toLowerCase().includes('7pm')
        ) {
            return <SignoffIcon7PMSignoff style={iconStyles} />
        } else if (
            name.toLowerCase().includes('8 pm') ||
            name.toLowerCase().includes('8pm')
        ) {
            return <SignoffIcon8PMSignoff style={iconStyles} />
        } else if (
            name.toLowerCase().includes('9 pm') ||
            name.toLowerCase().includes('9pm')
        ) {
            return <SignoffIcon9PMSignoff style={iconStyles} />
        } else if (
            name.toLowerCase().includes('10 pm') ||
            name.toLowerCase().includes('10pm')
        ) {
            return <SignoffIcon10PMSignoff style={iconStyles} />
        } else if (
            name.toLowerCase().includes('1 pm') ||
            name.toLowerCase().includes('1pm')
        ) {
            return <SignoffIcon1PMSignoff style={iconStyles} />
        } else if (
            name.toLowerCase().includes('2 pm') ||
            name.toLowerCase().includes('2pm')
        ) {
            return <SignoffIcon2PMSignoff style={iconStyles} />
        } else if (isMastLog) {
            return <SignoffMaster style={iconStyles} />
        } else {
            return <SignoffIcon style={iconStyles} />
            return <SignoffMaster style={iconStyles} />
        }
    }

    const iconStyles = {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }

    const cardImageContainerLogsheet = {
        width: '325px',
        height: '200px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    }

    const iconMasterStyle = {
        width: '100%',
        height: '100%',
        // color: getColor(),
    }

    const textMasterStyle = {
        fontSize: 17,
        color: 'white',
        fontWeight: 'bold',
        position: 'absolute',
        top: '49%', // Adjust the top position to center vertically
        transform: 'translateY(-50%)', // Center the text vertically
    }

    return (
        <Paper key={id} style={styles.paperSignoff}>
            <Box sx={styles.cardImageSmallContainer}>
                {/* <MasterLogsheetIcon style={iconStyles} /> */}
                {getIcon(logsheetTitle)}
            </Box>
            <Box sx={styles.cardTextContainer}>
                <div style={styles.cardTextBig}>{logsheetTitle}</div>
                <div style={styles.cardTextSmallerBlock}>
                    <div style={styles.cardTextSmall}>Last Processed at: </div>
                    <div style={styles.cardTextSmallBold}>
                        {new Date(updatedAt).toLocaleString()}
                    </div>
                </div>
                <div style={styles.cardTextSmallerBlock}>
                    <div style={styles.cardTextSmall}>Last Signed at: </div>
                    <div style={styles.cardTextSmallBold}>
                        {lastSigned
                            ? new Date(
                                  lastSigned.dateSignedOff
                              ).toLocaleString()
                            : 'more than a week ago'}
                    </div>
                </div>
            </Box>
        </Paper>
    )
}

function LogsheetsSignoffs({
    masterLogsheetId,
    masterLogsheetTitle,
    logsheets,
    masterLogsheetSignOffs,
}) {
    const ShowContentBasedOnRolesWrapper = useShowContentBasedOnRoles()
    const [initialLogsheets, setInitialLogsheets] = useState([])
    const history = useHistory()

    useEffect(() => {
        const sortedInitialLogsheets = logsheets
        setInitialLogsheets(sortedInitialLogsheets)
    }, [logsheets])

    return (
        <Box sx={styles.innerContainer}>
            <Box sx={styles.buttonList}>
                {
                    <ShowContentBasedOnRolesWrapper roles={['admin']}>
                        <div
                            className="normalButton"
                            onClick={() =>
                                history.push(
                                    `/logsheets/${masterLogsheetId}/table`
                                )
                            }
                        >
                            <div className="buttonIconBlock">
                                <TableIcon />
                            </div>
                            <div className="buttonTextBlock">
                                <div className="buttonTextBiggerBlock">
                                    Table
                                </div>
                            </div>
                        </div>
                    </ShowContentBasedOnRolesWrapper>
                }
            </Box>

            <div className="titleBigWithLegend">
                <div className="titleBig">{masterLogsheetTitle}</div>
                <div className="legend">
                    <div
                        style={{
                            width: '30px',
                            height: '20px',
                            //  backgroundColor: '#A186E3',
                            border: '2px solid #A186E3',
                            borderRadius: '7px',
                        }}
                    ></div>
                    <div style={styles.cardTextSmallBold}>
                        - signed within last 16 hours
                    </div>
                </div>
            </div>

            <Box sx={styles.cardContainer}>
                {Array.isArray(initialLogsheets) &&
                    initialLogsheets?.map((logsheet) => {
                        const lastSigned = masterLogsheetSignOffs
                            ?.filter((item) => item.logsheetId === logsheet.id)
                            .sort(
                                (a, b) =>
                                    new Date(b.dateSignedOff) -
                                    new Date(a.dateSignedOff)
                            )[0]

                        const lastSignedWithin16Hours =
                            masterLogsheetSignOffs?.find(
                                (item) =>
                                    item.logsheetId === logsheet.id &&
                                    new Date(item.dateSignedOff) >
                                        new Date(
                                            Date.now() - 16 * 60 * 60 * 1000
                                        ) // 16 hours in milliseconds
                            )

                        return (
                            <Box
                                key={logsheet.id}
                                border={2}
                                borderRadius={3}
                                borderColor={
                                    lastSignedWithin16Hours
                                        ? '#A186E3'
                                        : 'transparent'
                                }
                                component={RouterLink}
                                to={`/logsheets/${masterLogsheetId}/signoff/${logsheet.id}`}
                                style={{ textDecoration: 'none' }}
                                sx={{
                                    ':hover': {
                                        boxShadow: lastSignedWithin16Hours
                                            ? '0px 10px 15px rgba(0, 0, 0, 0.2)'
                                            : 'none',
                                        borderColor: lastSignedWithin16Hours
                                            ? '#A186E3'
                                            : 'rgba(0, 0, 0, 0.1)',
                                    },
                                }}
                            >
                                <SingleLogsheetSignOff
                                    {...logsheet}
                                    id={logsheet.id}
                                    logsheets={logsheets}
                                    masterLogsheetSignOffs={
                                        masterLogsheetSignOffs
                                    }
                                    lastSigned={lastSigned}
                                    lastSignedWithin16Hours={
                                        lastSignedWithin16Hours
                                    }
                                />
                            </Box>
                        )
                    })}
            </Box>
        </Box>
    )
}

export default LogsheetsSignoffs
