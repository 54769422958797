import React, { useState, useEffect } from 'react'
import {
    Badge,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    List,
    ListItem,
    ListItemText,
    Link,
    Divider,
} from '@mui/material'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { getUnsignedLogbooks } from '../../views/Logbooks/actions'
import { Link as RouterLink } from 'react-router-dom'

const NotificationBell = () => {
    const [unsignedLogbooks, setUnsignedLogbooks] = useState([])
    const [open, setOpen] = useState(false)

    useEffect(() => {
        fetchUnsignedLogbooks()
    }, [])

    const fetchUnsignedLogbooks = async () => {
        try {
            const logbooks = await getUnsignedLogbooks()
            console.log('logbooks', logbooks)
            setUnsignedLogbooks(logbooks)
        } catch (error) {
            console.error('Error fetching unsigned logbooks:', error)
        }
    }

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <>
            <IconButton color="inherit" onClick={handleOpen}>
                <Badge badgeContent={unsignedLogbooks.length} color="error">
                    <NotificationsIcon />
                </Badge>
            </IconButton>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Unsigned Logbooks From Past 14 Days</DialogTitle>
                <Divider />
                <DialogContent>
                    {unsignedLogbooks.length > 0 ? (
                        <List sx={{ pt: 0 }}>
                            {unsignedLogbooks.map((logbook) => (
                                <ListItem key={logbook.id} disablePadding>
                                    <Link
                                        component={RouterLink}
                                        to={`/logbooks/${logbook.masterLogbookId}/logbook/${logbook.id}`}
                                        color="inherit"
                                    >
                                        <ListItemText
                                            primary={logbook.logbookTitle}
                                            sx={{ color: 'black' }}
                                        />
                                    </Link>
                                </ListItem>
                            ))}
                        </List>
                    ) : (
                        <p style={{ color: 'black' }}>
                            No unsigned logbooks found.
                        </p>
                    )}
                </DialogContent>
            </Dialog>
        </>
    )
}

export default NotificationBell
