import React, { useContext } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
    AppBar,
    IconButton,
    Hidden,
    Button,
    Toolbar,
    Box,
    Typography,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableHead,
    TableCell,
    Paper,
    Alert,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import AccountSettings from './AccountSettings'
import Modal from '@mui/material/Modal'
import { useSessionMeausrementsContext } from '../../context/SessionMeasurementsContext'
import { AuthContext } from 'oidc-react'
import TroubleshootIcon from '@mui/icons-material/Troubleshoot'

import { ReactComponent as UserIcon } from '../../icons/user.svg'
import NotificationBell from './NotificationBell'

const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: 'none',
        backgroundColor: 'white',
        display: 'flex',
        height: '68px',
    },
    toolbar: {
        minHeight: 64,
        justifyContent: 'flex-end',
        width: '100%',
        height: '70px',
        backgroundColor: 'white',
        color: 'black',
        gap: '2rem',
    },
    headerButton: {
        height: '25px',
        width: '25px',
        color: '#DEDEDE',
        //   marginRight: '1rem',
    },
    hamburgerbutton: {},
    hamburger: {
        color: 'black',
    },
    headerText: {
        color: '#363636',
        fontFamily: 'Montserrat',
        fontWeight: 500,
        fontSize: '13px',
        letterSpacing: '-0.5px',
    },
}))

// Creates a top bar that allows for the session measurements modal to open and close, displays the current station
// session measurements and other company information.
const TopBar = ({ className, ...rest }) => {
    const classes = useStyles()
    const [isSessionMeasurementsModalOpen, setIsSessionMeasurementsModalOpen] =
        React.useState(false)
    const { sessionMeasurementsState, removeAllSessionMeasurements } =
        useSessionMeausrementsContext()

    const authContext = useContext(AuthContext)
    const userName =
        authContext?.userData?.profile?.name ||
        authContext?.userData?.profile?.email

    return (
        <Box>
            <Toolbar className={classes.toolbar}>
                <Box
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {/* <Account className={classes.headerButton} /> */}
                    <AccountSettings className={classes.headerButton} />
                </Box>
                <Box className={classes.headerText}>{userName}</Box>

                <TroubleshootIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => setIsSessionMeasurementsModalOpen(true)}
                />

                <NotificationBell />

                <Box
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: '20px',
                    }}
                >
                    <Modal
                        open={isSessionMeasurementsModalOpen}
                        onClose={() => setIsSessionMeasurementsModalOpen(false)}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                bgcolor: 'background.paper',
                                boxShadow: 24,
                                p: 4,
                                outline: 0,
                                borderRadius: 1,
                            }}
                        >
                            <Typography
                                variant="h6"
                                component="h2"
                                sx={{
                                    mb: 2,
                                    textAlign: 'center',
                                }}
                            >
                                Current Session Station Measurements
                            </Typography>

                            {sessionMeasurementsState.length > 0 ? (
                                <>
                                    <TableContainer
                                        component={Paper}
                                        sx={{ maxHeight: '50vh' }}
                                    >
                                        <Table
                                            sx={{
                                                height: 'max-content',
                                            }}
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell
                                                        align="center"
                                                        sx={{
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        Zone Title
                                                    </TableCell>
                                                    <TableCell
                                                        align="center"
                                                        sx={{
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        Station Title
                                                    </TableCell>
                                                    <TableCell
                                                        align="center"
                                                        sx={{
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        Measurement Value
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {sessionMeasurementsState.map(
                                                    (sessionMeasurement) => (
                                                        <TableRow>
                                                            <TableCell align="center">
                                                                {
                                                                    sessionMeasurement
                                                                        .content
                                                                        .zoneTitle
                                                                }
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {
                                                                    sessionMeasurement
                                                                        .content
                                                                        .stationTitle
                                                                }
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {
                                                                    sessionMeasurement
                                                                        .content
                                                                        .measurementValue
                                                                }
                                                                {
                                                                    sessionMeasurement
                                                                        .content
                                                                        .measurementUnit
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                    <Button
                                        size="large"
                                        // variant="contained"
                                        color="error"
                                        sx={{
                                            mt: 2,
                                            mx: 'auto',
                                            display: 'block',
                                        }}
                                        onClick={() =>
                                            removeAllSessionMeasurements()
                                        }
                                    >
                                        Clear All
                                    </Button>
                                </>
                            ) : (
                                <Alert icon={false} severity="info">
                                    No measurements in this session
                                </Alert>
                            )}
                        </Box>
                    </Modal>
                </Box>
            </Toolbar>
        </Box>
    )
}

TopBar.propTypes = {
    className: PropTypes.string,
}

export default TopBar
