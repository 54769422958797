import React, { useState, useEffect } from 'react'
import localforage from 'localforage'
import LogsheetsSignoffs from './LogsheetsSignoffs'
import {
    getIndividualMasterLogsheet,
    getLogsheetOrdering,
    getMasterLogsheetSignOffs,
} from '../actions'
import useLoader from '../../../hooks/useLoader'
import { useToastContext } from '../../../context/ToastContext'

//Renders Logsheets when the url path is a match
//Uses the useLoader hook to display the waiting screen while master logsheet data
//is bieng retrieved from either localforage or the database
const LogsheetsSignoffsPath = ({ match }) => {
    const masterLogsheetId = match?.params.masterLogsheetId
    const [masterLogsheet, setMasterLogsheet] = useState([])
    const [ordering, setOrdering] = useState({})
    const [LoaderWrapper, setIsLoading] = useLoader()
    const toastContext = useToastContext()
    const [signOffs, setSignOffs] = useState([])

    const asyncFetchLogsheets = async () => {
        setIsLoading(true)

        if (!navigator.onLine) {
            const allMasterLogsheets = await localforage.getItem('allLogsheets')
            const logsheetOrdering = await localforage.getItem(
                'logsheetOrdering'
            )

            if (allMasterLogsheets) {
                setMasterLogsheet(
                    allMasterLogsheets.find(
                        (masterLogsheet) =>
                            +masterLogsheet.id === +masterLogsheetId
                    )
                )

                setOrdering(logsheetOrdering)

                setIsLoading(false)
                return
            }
        }

        const [
            masterLogsheetResults,
            logsheetOrderingResults,
            masterLogsheetSignOffs,
        ] = await Promise.all([
            getIndividualMasterLogsheet(masterLogsheetId),
            getLogsheetOrdering(),
            getMasterLogsheetSignOffs(masterLogsheetId),
        ]).catch((e) => toastContext.sendToast('Error Fetching Data', 'error'))

        setMasterLogsheet(masterLogsheetResults)
        setOrdering(logsheetOrderingResults)
        setSignOffs(masterLogsheetSignOffs)
        //   console.log('masterLogsheetSignOffs', masterLogsheetSignOffs)
        //   console.log('masterLogsheetResults', masterLogsheetResults)

        setIsLoading(false)
    }

    useEffect(() => {
        asyncFetchLogsheets()
    }, [])

    return (
        <LoaderWrapper>
            <LogsheetsSignoffs
                logsheets={masterLogsheet.logsheets}
                asyncFetchLogsheets={asyncFetchLogsheets}
                ordering={ordering}
                updateOrdering={setOrdering}
                masterLogsheetId={masterLogsheetId}
                masterLogsheetTitle={masterLogsheet.masterLogsheetTitle}
                masterLogsheetSignOffs={signOffs}
            />
        </LoaderWrapper>
    )
}

export default LogsheetsSignoffsPath
