import ExcelJS from 'exceljs'

//helper method to change the format of the data to 'measurment' objects
export const convertDataToMeasurementsFormat = (data) => {
    console.log(data)

    const allUniqueStations = []
    const allMeasurements = []

    for (let i = 0; i < data.logsheets.length; i++) {
        for (let j = 0; j < data.logsheets[i].stations.length; j++) {
            if (
                allUniqueStations.findIndex(
                    (station) => station.id === data.logsheets[i].stations[j].id
                ) > -1
            ) {
                continue
            }
            allUniqueStations.push(data.logsheets[i].stations[j])
        }
    }

    for (let i = 0; i < allUniqueStations.length; i++) {
        let stationTitle = allUniqueStations[i].stationTitle
        let stationDescription = allUniqueStations[i].stationDescription
        let target = allUniqueStations[i].target
        let lowerrange = allUniqueStations[i].rangeLow
        let upperrange = allUniqueStations[i].rangeHigh
        let unit = allUniqueStations[i].measurementUnit
        let lastupdate = allUniqueStations[i].updatedAt

        for (
            let j = 0;
            j < allUniqueStations[i].stationMeasurements.length;
            j++
        ) {
            allMeasurements.push({
                zone: data.masterLogsheetTitle,
                stationTitle: stationTitle,
                stationDescription: stationDescription,
                target: target,
                lowerrange: lowerrange,
                upperrange: upperrange,
                unit: unit,
                lastupdate: lastupdate,
                value: allUniqueStations[i].stationMeasurements[j]
                    .measurementValue,
            })
        }
    }

    return allMeasurements
}

//Loops through the measurments paramter which should be an array of measurments objects
//Returns a map of excel workbooks with the zone name as the key and corresponding excel sheet as the value
export const generateZoneExcelReport = (measurements) => {
    const sortedMeasurements = measurements.reverse()
    const formatDate = new Intl.DateTimeFormat('us', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    })

    const formatTime = new Intl.DateTimeFormat('us', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    })

    let workbooks = new Map()

    for (const measurement of sortedMeasurements) {
        const {
            zone,
            stationTitle,
            stationDescription,
            target,
            lowerrange,
            upperrange,
            value,
            unit,
            lastupdate,
        } = measurement

        if (!stationTitle || !zone) continue // Skip measurements with null stationTitle or zone

        if (!workbooks.has(zone)) workbooks.set(zone, new ExcelJS.Workbook())

        const workbook = workbooks.get(zone)

        let _stationTitle = stationTitle.replace(/[^a-zA-Z0-9 ]/g, '') // remove special characters in station title

        const worksheetTitle =
            _stationTitle.length > 31
                ? _stationTitle.toUpperCase().slice(0, 30)
                : _stationTitle.toUpperCase() // truncate long title

        if (!workbook.getWorksheet(worksheetTitle)) {
            const worksheet = workbook.addWorksheet(worksheetTitle, {
                properties: {
                    defaultColWidth: 20,
                },
            })

            worksheet.addRow([
                `${stationTitle}` +
                    (!!stationDescription ? ` / ${stationDescription}` : ''),
            ])
            worksheet.mergeCells('A1:C1')

            const unitRow = !!target
                ? `${unit} (${lowerrange}-${upperrange}, Target: ${target})`
                : `${unit}`
            worksheet.addRow([unitRow])
            worksheet.mergeCells('A2:C2')

            worksheet.getRow(3).values = ['Date', 'Time', 'Value']

            worksheet.columns = [
                { key: 'date' },
                { key: 'time' },
                { key: 'value' },
            ]

            worksheet.views = [{ state: 'frozen', xSplit: 0, ySplit: 3 }]
        }

        const worksheet = workbook.getWorksheet(worksheetTitle)

        worksheet.addRow({
            date: formatDate.format(new Date(lastupdate)),
            time: formatTime.format(new Date(lastupdate)),
            unit,
            value,
        })

        const headers = ['A1', 'B1', 'C1', 'A2', 'B2', 'C2', 'A3', 'B3', 'C3']
        headers.map((header) => {
            worksheet.getCell(header).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'cccccc' },
            }

            worksheet.getCell(header).font = {
                bold: true,
                size: 14,
            }

            worksheet.getCell(header).alignment = {
                vertical: 'bottom',
                horizontal: 'center',
            }
        })

        // Highlight values out of range
        worksheet
            .getColumn(3)
            .eachCell({ includeEmpty: false }, function (cell, rowNumber) {
                // Skip text measurement values
                if (!/[A-Za-z]/i.test(cell.value)) {
                    const cellValue = parseFloat(cell.value)
                    if (cellValue < parseFloat(lowerrange)) {
                        worksheet.getCell(`C${rowNumber}`).fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'f1cbff' },
                        }
                    }

                    if (cellValue > parseFloat(upperrange)) {
                        worksheet.getCell(`C${rowNumber}`).fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'ff4040' },
                        }
                    }
                }
            })
    }
    return workbooks
}

export const generateExcelReport = (measurements, masterLogsheetId = null) => {
    if (masterLogsheetId) {
        measurements = measurements.filter(
            (measurement) =>
                parseInt(measurement.masterLogsheetId) ===
                parseInt(masterLogsheetId)
        )
    }

    const sortedMeasurements = measurements.reverse()
    const formatDate = new Intl.DateTimeFormat('us', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    })

    const formatTime = new Intl.DateTimeFormat('us', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    })

    let workbook = new ExcelJS.Workbook()

    let zoneMeasurements = new Map()

    const sanitizeWorksheetName = (name) => {
        return name.replace(/[\/\*\?:\\\[\\\]]/g, '_').slice(0, 31)
    }

    for (const measurement of sortedMeasurements) {
        const { zone, stationTitle } = measurement

        if (!stationTitle || !zone) continue // Skip measurements with null stationTitle or zone
        const items = zoneMeasurements.get(zone) || []
        zoneMeasurements.set(zone, [...items, measurement])

        const sanitizedZone = sanitizeWorksheetName(zone)

        if (!workbook.getWorksheet(sanitizedZone)) {
            workbook.addWorksheet(sanitizedZone, {
                properties: {
                    defaultColWidth: 10,
                },
            })
        }
    }

    for (let [_zone, _measurements] of zoneMeasurements) {
        const sanitizedZone = sanitizeWorksheetName(_zone)
        const worksheet = workbook.getWorksheet(sanitizedZone)
        let stations = []
        let unitRows = []
        let stationColumns = []

        for (const _measurement of _measurements) {
            const { stationTitle, target, lowerrange, upperrange, unit } =
                _measurement
            if (!stations.includes(stationTitle)) {
                stations.push(stationTitle)
                unitRows.push(
                    lowerrange !== null || upperrange !== null
                        ? `${unit}\n(${lowerrange}-${upperrange})`
                        : `${unit}`
                )
                stationColumns.push({ key: stationTitle })
            }
        }

        worksheet.addRow([`     ${_zone}`])
        worksheet.mergeCells('A1:AAA1')

        worksheet.addRow(['Instrument\nUnit\nRange', ...stations])
        worksheet.addRow(['Date ↓', ...unitRows])
        worksheet.views = [{ state: 'frozen', xSplit: 0, ySplit: 3 }]

        worksheet.getRows(1, 3).map((row) => {
            row.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'cccccc' },
            }

            row.font = {
                bold: true,
                size: row.number === 1 ? 14 : 10,
            }

            row.alignment = {
                vertical: row.number <= 2 ? 'bottom' : 'top',
                horizontal: row.number === 1 ? 'left' : 'center',
                wrapText: true,
            }

            row.height = row.number >= 2 ? 50 : 20

            row.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' },
            }
        })
        worksheet.getCell(3, 1).alignment = {
            vertical: 'bottom',
        }

        worksheet.columns = [{ key: 'date' }, ...stationColumns]

        let dates = []
        let stationMap = new Map()

        for (const _measurement of _measurements) {
            const { stationTitle, lastupdate, value } = _measurement
            const _date = formatDate.format(new Date(lastupdate))

            if (!dates.includes(_date)) {
                dates.push(_date)
                stationMap.set(_date, new Map(Object.entries({})))
            }

            if (!stationMap.get(_date).has(stationTitle)) {
                let _stationItem = stationMap.get(_date)
                _stationItem.set(stationTitle, [])
            }

            const items = stationMap.get(_date).get(stationTitle)
            stationMap.get(_date).set(stationTitle, [...items, _measurement])
        }

        let lowerranges = []
        let upperranges = []

        for (const date of dates) {
            const _stations = stationMap.get(date)

            let maxRows = 0
            _stations.forEach((i) => {
                if (i.length > maxRows) {
                    maxRows = i.length
                }
            })

            for (let i = 0; i < maxRows; i++) {
                let data = {}
                _stations.forEach((v, k) => {
                    if (!!v[i]) {
                        data[k] = v[i].value
                        lowerranges.push(v[i].lowerrange)
                        upperranges.push(v[i].upperrange)
                    }
                })

                worksheet.addRow({
                    date: date,
                    ...data,
                })
            }
        }

        for (let i = 0; i < stations.length; i++) {
            worksheet
                .getColumn(stations[i])
                .eachCell({ includeEmpty: false }, function (cell, rowNumber) {
                    const cellValue = parseFloat(cell.value)
                    if (rowNumber > 3 && (!!cellValue || cellValue === 0)) {
                        // Skip text measurement values
                        if (!/[A-Za-z]/i.test(cell.value)) {
                            // Digging into the stationMap to find the current station
                            // ---------------------------------------------------
                            const mapValue = []
                            stationMap.forEach(
                                (value, key) =>
                                    worksheet.getCell(rowNumber, 1).value ===
                                        key && mapValue.push(value)
                            )
                            const mapValue2 = []
                            mapValue.forEach((value, key) =>
                                mapValue2.push(
                                    value.get(
                                        worksheet.getCell(2, cell.col).value
                                    )
                                )
                            )
                            const currentStation = mapValue2[0][0]
                            // ---------------------------------------------------

                            if (
                                cellValue <
                                parseFloat(currentStation.lowerrange)
                            ) {
                                worksheet.getCell(rowNumber, cell.col).fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'f1cbff' },
                                }
                            }

                            if (
                                cellValue >
                                parseFloat(currentStation.upperrange)
                            ) {
                                worksheet.getCell(rowNumber, cell.col).fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'ff4040' },
                                }
                            }
                        }
                    }
                    if (rowNumber > 3) {
                        cell.border = {
                            left: { style: 'thin' },
                            right: { style: 'thin' },
                        }
                    }
                })
        }

        let borderPoints = []
        let lastDate = null
        worksheet
            .getColumn(1)
            .eachCell({ includeEmpty: true }, function (cell, rowNumber) {
                if (rowNumber > 3) {
                    if (cell.value !== lastDate) {
                        borderPoints.push(rowNumber)
                        lastDate = cell.value
                    }
                }
            })
        borderPoints.push(worksheet.getColumn(1)['_worksheet']['_rows'].length)
        borderPoints.forEach((point, i) => {
            if (i > 0) {
                const row = worksheet.getRow(point)
                row.border = {
                    top: {
                        style: i + 1 === borderPoints.length ? null : 'thin',
                    },
                    bottom: {
                        style: i + 1 === borderPoints.length ? 'thin' : null,
                    },
                    left: { style: 'thin' },
                }

                for (let col = 2; col <= worksheet.columnCount; col++) {
                    let cell = row.getCell(col)
                    if (col > stations.length + 2) {
                        cell.value = ''
                        cell.border = {
                            left: { style: null },
                            top: {
                                style:
                                    i + 1 === borderPoints.length
                                        ? null
                                        : 'thin',
                            },
                            bottom: {
                                style:
                                    i + 1 === borderPoints.length
                                        ? 'thin'
                                        : null,
                            },
                        }
                    }
                }
            }
        })
    }

    return new Map(Object.entries({ MasterLogsheet: workbook }))
}

// Creates a function that allows for a workbook to be written as an excel spreadsheet and creates a name for it. This is done through
// clicking a hyperlink that is made.
export const downloadExcelFiles = (workbooks) => {
    workbooks.forEach(async (workbook, zone) => {
        const xls64 = await workbook.xlsx.writeBuffer({ base64: true })
        const element = document.createElement('a')
        const file = new Blob([xls64], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        element.href = URL.createObjectURL(file)
        element.download = `${zone}.xlsx`
        document.body.appendChild(element)
        element.click()
    })
}
