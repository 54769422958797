import React from 'react'
import Plot from 'react-plotly.js'

export class Line extends React.Component {
    render() {
        const xValues = this.props.values?.map(
            (stationMeasurement) => new Date(stationMeasurement.createdAt)
        )
        const yValues = this.props.values?.map((stationMeasurement) => {
            if (!this.props.measurementOptions) {
                return parseFloat(stationMeasurement?.measurementValue)
            } else {
                return stationMeasurement?.measurementValue
            }
        })

        // Calculate the title length threshold for line break
        const titleLengthThreshold1 = 31 // Adjust as needed
        const titleLengthThreshold2 = 60 // Adjust as needed

        // Define the title text
        const titleText = this.props.title

        // Check if the title length exceeds the threshold
        const shouldBreakTitleLine1 = titleText.length > titleLengthThreshold1
        const shouldBreakTitleLine2 = titleText.length > titleLengthThreshold2

        let titleLine1 = titleText.substring(0, titleLengthThreshold1)
        let titleLine2 = shouldBreakTitleLine1
            ? titleText.substring(titleLengthThreshold1, titleLengthThreshold2)
            : ''
        let titleLine3 = shouldBreakTitleLine2
            ? titleText.substring(titleLengthThreshold2)
            : ''

        const data = [
            {
                type: 'scatter',
                x: xValues,
                y: yValues,
                mode: 'lines+markers',
                hoverinfo: 'y',
                marker: {
                    color: yValues.map((value) => {
                        const belowMin =
                            this.props.min !== undefined &&
                            value < parseFloat(this.props.min)
                        const aboveMax =
                            this.props.max !== undefined &&
                            value > parseFloat(this.props.max)
                        return belowMin || aboveMax ? '#EE204D' : '#10d2a8'
                    }),
                    size: 8,
                },
                line: { color: '#10d2a8', width: 2 },
                name: 'Measurement Data',
            },
        ]

        const shapes = []

        if (
            this.props.showSigmaAvg &&
            this.props.measurementOptions.length === 0
        ) {
            if (this.props.stddevLower !== null) {
                shapes.push({
                    type: 'line',
                    x0: 0,
                    x1: 1,
                    xref: 'paper',
                    y0: parseFloat(this.props.stddevLower),
                    y1: parseFloat(this.props.stddevLower),
                    yref: 'y',
                    line: {
                        color: 'black',
                        width: 2,
                        dash: 'dot',
                    },
                })
            }
            if (this.props.stddevUpper !== null) {
                shapes.push({
                    type: 'line',
                    x0: 0,
                    x1: 1,
                    xref: 'paper',
                    y0: parseFloat(this.props.stddevUpper),
                    y1: parseFloat(this.props.stddevUpper),
                    yref: 'y',
                    line: {
                        color: 'black',
                        width: 2,
                        dash: 'dot',
                    },
                })
            }
            if (this.props.mean !== null) {
                shapes.push({
                    type: 'line',
                    x0: 0,
                    x1: 1,
                    xref: 'paper',
                    y0: parseFloat(this.props.mean),
                    y1: parseFloat(this.props.mean),
                    yref: 'y',
                    line: {
                        color: 'blue',
                        width: 2,
                        dash: 'dot',
                    },
                })
            }
        }

        // Add Min line if this.props.min is not null
        if (!this.props.showSigmaAvg) {
            if (this.props.min !== null) {
                shapes.push({
                    type: 'line',
                    x0: 0,
                    x1: 1,
                    xref: 'paper',
                    y0: parseFloat(this.props.min),
                    y1: parseFloat(this.props.min),
                    yref: 'y',
                    line: {
                        color: '#EE204D',
                        width: 2,
                        dash: 'dot',
                    },
                })
            }

            // Add Max line if this.props.max is not null
            if (this.props.max !== null) {
                shapes.push({
                    type: 'line',
                    x0: 0,
                    x1: 1,
                    xref: 'paper',
                    y0: parseFloat(this.props.max),
                    y1: parseFloat(this.props.max),
                    yref: 'y',
                    line: {
                        color: '#EE204D',
                        width: 2,
                        dash: 'dot',
                    },
                })
            }

            // Add Target line if this.props.target is not null
            if (this.props.target !== null) {
                shapes.push({
                    type: 'line',
                    x0: 0,
                    x1: 1,
                    xref: 'paper',
                    y0: parseFloat(this.props.target),
                    y1: parseFloat(this.props.target),
                    yref: 'y',
                    line: {
                        color: '#EDAB07',
                        width: 2,
                        dash: 'dot',
                    },
                })
            }
        }

        const layout = {
            width: this.props.width,
            height: this.props.height,
            margin: {
                l: 30,
                r: 30,
                t: shouldBreakTitleLine1 ? 85 : 70,
                b: 110,
            },
            paper_bgcolor: 'white',
            title: {
                text: `<b style="letter-spacing: -0.7px">${titleLine1}<br>${titleLine2}<br>${titleLine3}</b>`,
                // text: this.props.title,
                font: {
                    size: shouldBreakTitleLine2 ? 14 : 16,
                    family: 'Montserrat',
                },
                y: shouldBreakTitleLine2 && 0.92, // Adjust vertical position based on the number of lines
                x: 0.5,
                xanchor: 'center',
                yanchor: 'top',
            },
            xaxis: {
                type: 'date',
                showline: true,
                showgrid: false,
                zeroline: false,
                linewidth: 1,
                tickwidth: 2,
                tickangle: 0,
                tickfont: {
                    size: 10,
                },
                title: {
                    text: !this.props.showSigmaAvg
                        ? `<span style='color: #EDAB07'>Target: ${
                              this.props.target != null
                                  ? `${this.props.target}${
                                        this.props.units
                                            ? ` ${this.props.units}`
                                            : ''
                                    }`
                                  : '-'
                          }<br></span>` +
                          `<span style='color: #D60033'> Min: ${
                              this.props.min != null
                                  ? `${this.props.min}${
                                        this.props.units
                                            ? ` ${this.props.units}`
                                            : ''
                                    }`
                                  : '- '
                          } </span>` +
                          `<span style='color: #D60033'> Max: ${
                              this.props.max != null
                                  ? `${this.props.max}${
                                        this.props.units
                                            ? ` ${this.props.units}`
                                            : ''
                                    }`
                                  : '- '
                          }</span>`
                        : this.props.showSigmaAvg &&
                          `<span style='color: blue;'>Average: ${
                              this.props.mean != null
                                  ? `${this.props.mean}`
                                  : '-'
                          }</span><br>` +
                              `Lower CL: ${
                                  this.props.stddevLower != null
                                      ? `${this.props.stddevLower}`
                                      : '- '
                              } ` +
                              `Upper CL: ${
                                  this.props.stddevUpper != null
                                      ? `${this.props.stddevUpper}`
                                      : '- '
                              }`,
                    standoff: 1,
                    font: {
                        size: 14,
                    },
                },
            },
            yaxis: {
                zeroline: false,
            },
            autosize: false,
            shapes: shapes,
        }

        return <Plot data={data} layout={layout} />
    }
}

Line.defaultProps = {
    width: 300,
    height: 320,
    title: 'Line',

    min: 0,
    max: Infinity,
    target: 0,

    values: [],
}

export default Line
