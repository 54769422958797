import { useState, useMemo } from 'react'
import { CircularProgress, Box } from '@mui/material'

//Custom hook used to display loading circle
//while the 'isLoading' state is true the loading panel will be shown, after the children components are rendered
//returns 'loadedWrapper' to display the loading box and the 'setisLoading' function to render the children when ready
export const useLoader = () => {
    const [isLoading, setisLoading] = useState(false)

    const LoaderWrapper = useMemo(
        () =>
            ({ children }) => {
                return isLoading ? (
                    <Box
                        sx={{
                            display: 'flex',
                            height: '100%',
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',

                            fontSize: '15px',
                            letterSpacing: '-0.5px',
                        }}
                    >
                        <CircularProgress sx={{ marginBottom: 3 }} />
                        Loading...
                    </Box>
                ) : (
                    <>{children}</>
                )
            },
        [isLoading]
    )

    return [LoaderWrapper, setisLoading]
}

export default useLoader
