import React, { useState, useEffect, useRef } from 'react'
import { styled } from '@mui/material/styles'
import {
    Box,
    Accordion,
    AccordionSummary,
    Typography,
    AccordionDetails,
    FormGroup,
    FormControlLabel,
    Checkbox,
    CircularProgress,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { makeStyles } from '@mui/styles'
import { minifiedGetAllZonesWithStations } from './actions'
import { createLogsheet, updateLogsheet } from './actions'
import { updateMasterLogsheetIdInZones } from '../Zones/actions'
import { uploadImage } from '../actions'
import { useToastContext } from '../../context/ToastContext'
import useCrudLoader from '../../hooks/useCrudLoader'

const useStyles = makeStyles((theme) => ({
    zoneImage: {
        height: '200px',
        width: '250px',
        objectFit: 'cover',
        overflow: 'hidden',
        alignSelf: 'center',
        display: 'flex',
        margin: 'auto',
    },
    zoneImageContainer: {
        padding: theme.spacing(2),
        margin: '16px 0px 0px 0px',
    },
}))

const SelectButton = styled(Button)({
    marginTop: '10px',
})

//A component that allows a user to create or modify a logsheet.
//Acts slightly differnt depending on props.operation. It is either update or create
export default function CreateOrUpdateLogsheet(props) {
    const [open, setOpen] = React.useState(true)
    const [zonesWithStations, setZonesWithStations] = React.useState([])
    const [formValues, setFormValues] = useState({})
    const [error, setError] = useState(false)
    const [selectedStationIds, setSelectedStationIds] = useState(
        props?.initialValues?.stationIds || {}
    )
    const [selectedZones, setSelectedZones] = useState({})
    const [LoaderWrapper, setIsLoading, isLoading] = useCrudLoader()
    const classes = useStyles()
    const toastContext = useToastContext()

    const asyncFetchZonesWithStations = async () => {
        const zonesWithStationsResponse =
            await minifiedGetAllZonesWithStations()
        setZonesWithStations(zonesWithStationsResponse)

        // find the zones associated with the masterlogsheet and set them into selectedZones state
        zonesWithStationsResponse?.forEach((zone) => {
            if (zone.masterLogsheetId == props.masterLogsheetId) {
                setSelectedZones((prev) => ({ ...prev, [zone.id]: true }))
            }
        })
    }
    useEffect(() => {
        setFormValues({ name: props?.initialValues?.logsheetTitle || '' })
    }, [props?.initialValues?.logsheetTitle])

    useEffect(() => {
        asyncFetchZonesWithStations()
    }, [])

    const handleClose = () => {
        setOpen(false)
        props.setDisplayModal(false)
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target

        setFormValues({
            ...formValues,
            [name]: value,
        })
    }

    const handleCheckStation = (stationId) => {
        if (stationId in selectedStationIds) {
            let tempStationIds = { ...selectedStationIds }
            delete tempStationIds[stationId]
            setSelectedStationIds(tempStationIds)
        } else {
            setSelectedStationIds((currSelectedStationIds) => {
                return {
                    ...currSelectedStationIds,
                    [stationId]: 1,
                }
            })
        }
    }

    const handleCheckZone = (zone) => {
        setSelectedZones((prev) => ({
            ...prev,
            [zone.id]: prev[zone.id] ? !prev[zone.id] : true,
        }))

        if (
            zone.stations
                .map((station) => station.id)
                .some((stationId) =>
                    [...Object.keys(selectedStationIds)].includes(
                        stationId.toString()
                    )
                )
        ) {
            let tempStationIds = { ...selectedStationIds }
            for (let i = 0; i < zone.stations.length; i++) {
                delete tempStationIds[zone.stations[i].id]
            }
            setSelectedStationIds(tempStationIds)
        } else {
            let tempStationIds = { ...selectedStationIds }
            for (let i = 0; i < zone.stations.length; i++) {
                tempStationIds[zone.stations[i].id] = 1
            }
            setSelectedStationIds(tempStationIds)
        }
    }
    const handleSelectAllStations = (zone) => {
        const stationIds = zone.stations.map((station) => station.id)
        const updatedSelectedStationIds = { ...selectedStationIds }
        stationIds.forEach((stationId) => {
            updatedSelectedStationIds[stationId] = 1
        })
        setSelectedStationIds(updatedSelectedStationIds)
    }

    const handleUnselectAllStations = (zone) => {
        const stationIds = zone.stations.map((station) => station.id)
        const updatedSelectedStationIds = { ...selectedStationIds }
        stationIds.forEach((stationId) => {
            delete updatedSelectedStationIds[stationId]
        })
        setSelectedStationIds(updatedSelectedStationIds)
    }

    const handleSubmit = async (event) => {
        if (isLoading) {
            return
        }

        event.preventDefault()
        setIsLoading(true)

        if (props.operation === 'Create') {
            if (!formValues.name) {
                setError('A logsheet name is required')
                setIsLoading(false)
                return
            }

            const stationIds = [...Object.keys(selectedStationIds)]

            await createLogsheet(
                formValues.name,
                stationIds,
                props.masterLogsheetId
            )
                .then(() => {
                    props?.onSuccess?.()
                    toastContext.sendToast('Successfully Created Logsheet')
                })
                .catch((e) => toastContext.sendToast(e.message, 'error'))
        } else {
            if (
                formValues.hasOwnProperty('name') &&
                formValues.name.length <= 0
            ) {
                setError('A logsheet name is required')
                setIsLoading(false)
                return
            }

            const stationIds = [...Object.keys(selectedStationIds)]

            await updateLogsheet(
                props.logsheetId,
                props.masterLogsheetId,
                formValues.name,
                stationIds
            )
                .then(() => {
                    props?.onSuccess?.()
                    toastContext.sendToast('Successfully Updated Logsheet')
                    setFormValues({
                        name: props?.initialValues?.logsheetTitle || '',
                    })
                })
                .catch((e) => toastContext.sendToast(e.message, 'error'))

            // if the logsheet has all of the stations (ie, it's the master),
            // then update zones in the masterLogsheet
            const zonesToUpdate = Object.entries(selectedZones)
                .filter(([zoneId, trueFalse]) => trueFalse === true)
                .map(([zoneId, trueFalse]) => parseInt(zoneId))

            if (props.includesAllStations) {
                updateMasterLogsheetIdInZones(
                    props.masterLogsheetId,
                    zonesToUpdate
                )
            }
        }
        setIsLoading(false)
        props.setDisplayModal(false)
    }

    if (isLoading) {
        return (
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={'xs'}
            >
                <LoaderWrapper></LoaderWrapper>
            </Dialog>
        )
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogContent
                sx={{
                    width: '440px',
                }}
            >
                <DialogContentText id="create-zone-error">
                    {error}
                </DialogContentText>

                <form onSubmit={handleSubmit}>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        name="name"
                        label="Logsheet Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={formValues.name}
                        onChange={handleInputChange}
                    />

                    {zonesWithStations.length > 0 ? (
                        <Box sx={{ my: 2 }}>
                            {props.includesAllStations
                                ? // <FormGroup>
                                  //     {zonesWithStations
                                  //         .filter(
                                  //             (zone) =>
                                  //                 zone.masterLogsheetId ===
                                  //                 props.masterLogsheetId
                                  //         )
                                  //         .map((zoneWithStations, idx) => {
                                  //             const zoneHasStationInIncludedStations =
                                  //                 zoneWithStations.stations
                                  //                     .map(
                                  //                         (station) => station.id
                                  //                     )
                                  //                     // [1,2,3,4]
                                  //                     .some((stationId) =>
                                  //                         [
                                  //                             ...Object.keys(
                                  //                                 selectedStationIds
                                  //                             ),
                                  //                         ].includes(
                                  //                             stationId.toString()
                                  //                         )
                                  //                     )
                                  //             return (
                                  //                 zoneWithStations.stations
                                  //                     .length > 0 && (
                                  //                     <FormControlLabel
                                  //                         control={
                                  //                             <Checkbox
                                  //                                 checked={
                                  //                                     zoneHasStationInIncludedStations
                                  //                                 }
                                  //                                 onChange={() => {
                                  //                                     handleCheckZone(
                                  //                                         zoneWithStations
                                  //                                     )
                                  //                                 }}
                                  //                             />
                                  //                         }
                                  //                         label={
                                  //                             zoneWithStations.zoneTitle
                                  //                         }
                                  //                     />
                                  //                 )
                                  //             )
                                  //         })}
                                  // </FormGroup>
                                  null
                                : zonesWithStations
                                      .filter(
                                          (zone) =>
                                              zone.masterLogsheetId ==
                                              props.masterLogsheetId
                                      )
                                      .map((zoneWithStations, idx) => {
                                          const shouldRenderZoneWithStation =
                                              (!zoneWithStations.masterLogsheetId &&
                                                  zoneWithStations.stations
                                                      .length > 0) ||
                                              (zoneWithStations.masterLogsheetId ==
                                                  props.masterLogsheetId &&
                                                  zoneWithStations.stations
                                                      .length > 0)

                                          return (
                                              shouldRenderZoneWithStation && (
                                                  <Accordion key={idx}>
                                                      <AccordionSummary
                                                          expandIcon={
                                                              <ExpandMoreIcon />
                                                          }
                                                          aria-controls="panel1a-content"
                                                          id="panel1a-header"
                                                      >
                                                          <Typography>
                                                              {
                                                                  zoneWithStations.zoneTitle
                                                              }
                                                          </Typography>
                                                      </AccordionSummary>
                                                      <AccordionDetails>
                                                          <Button
                                                              onClick={() =>
                                                                  handleSelectAllStations(
                                                                      zoneWithStations
                                                                  )
                                                              }
                                                          >
                                                              Select All
                                                          </Button>
                                                          <Button
                                                              onClick={() =>
                                                                  handleUnselectAllStations(
                                                                      zoneWithStations
                                                                  )
                                                              }
                                                          >
                                                              Unselect All
                                                          </Button>
                                                          <FormGroup>
                                                              {zoneWithStations.stations.map(
                                                                  (station) => {
                                                                      console.log(
                                                                          station
                                                                      )
                                                                      return (
                                                                          <FormControlLabel
                                                                              key={
                                                                                  station.id
                                                                              }
                                                                              control={
                                                                                  <Checkbox
                                                                                      checked={
                                                                                          selectedStationIds[
                                                                                              station
                                                                                                  .id
                                                                                          ] ===
                                                                                          1
                                                                                      }
                                                                                      onChange={() =>
                                                                                          handleCheckStation(
                                                                                              station.id
                                                                                          )
                                                                                      }
                                                                                  />
                                                                              }
                                                                              label={`${
                                                                                  station.stationTitle
                                                                              }${
                                                                                  station.stationDescription
                                                                                      ? ` - ${station.stationDescription}`
                                                                                      : ''
                                                                              }`}
                                                                          />
                                                                      )
                                                                  }
                                                              )}
                                                          </FormGroup>
                                                      </AccordionDetails>
                                                  </Accordion>
                                              )
                                          )
                                      })}
                        </Box>
                    ) : (
                        <CircularProgress sx={{ display: 'block', my: 2 }} />
                    )}
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSubmit}>
                    {props.operation === 'Create' ? 'Create' : 'Update'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
