import React, { useState, useEffect, useRef } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Button, Paper, Typography } from '@mui/material'
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core'
import {
    arrayMove,
    SortableContext,
    rectSortingStrategy,
    sortableKeyboardCoordinates,
} from '@dnd-kit/sortable'
import { useShowContentBasedOnRoles } from '../../../hooks/useShowContentBasedOnRoles'
import QrCode2Icon from '@mui/icons-material/QrCode2'
import UpdateZone from '../CreateOrUpdateZone'
import IndividualStation from './IndividualStation'
import QRScannerModal from '../QrScanner'
import CreateStationModal from './CreateOrUpdateStation'
import DeleteZone from '../DeleteZone'
import { downloadExcelFiles } from '../../../utils/excel'
import { downloadZoneReport, updateStationOrdering } from '../actions'
import { styles } from '../../../theme/styles'
import { ScreenScrollerButtons } from '../../../components/ScreenScrollerButtons'
import CopyZone from './CopyZone'
import { createStation } from '../actions'

import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined' // create
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined' // export
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined' // update
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined' // delete
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined' // sign off
import FormatListNumberedRtlOutlinedIcon from '@mui/icons-material/FormatListNumberedRtlOutlined' // table
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined' // copy
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined' // summary

import { ReactComponent as DownloadIcon } from '../../../icons/buttons/download.svg'
import { ReactComponent as EditIcon } from '../../../icons/buttons/edit.svg'
import { ReactComponent as DeleteIcon } from '../../../icons/buttons/delete.svg'
import { ReactComponent as CreateIcon } from '../../../icons/buttons/create.svg'
import { ReactComponent as ListIcon } from '../../../icons/buttons/list.svg'
import { ReactComponent as CopyIcon } from '../../../icons/buttons/copy.svg'

import { ReactComponent as ReorderIcon } from '../../../icons/buttons/order.svg'
import { ReactComponent as SaveIcon } from '../../../icons/buttons/save.svg'
import { ReactComponent as XIcon } from '../../../icons/buttons/x.svg'

//Reners all the stations inside a zone and allows reordering
//If the user is an admin additional options are shown such as deleting the zone and adding a station
function IndividualZone({
    id,
    stations,
    zoneTitle,
    loadingComplete,
    asyncGetIndividualZone,
    stationOrdering,
    updateZoneStationOrdering,
    imageS3Key,
    organizationIds,
    zoneId,
    stationIdToScroll,
    asyncReloadZone,
}) {
    const [displayQRScanner, setDisplayQRScanner] = useState(false)
    const [displayCreateStation, setDisplayCreateStation] = useState(false)
    const [displayUpdateZone, setDisplayUpdateZone] = useState(false)
    const [displayDeleteZone, setDisplayDeleteZone] = useState(false)
    const [initialStations, setInitialStations] = useState([])
    const [reorderableStations, setReorderableStations] = useState([])
    const [isDownloadingZoneReport, setInitiateZoneReportDownload] =
        useState(false)
    const [isReorderingStations, setIsReorderingStations] = useState(false)
    const ShowContentBasedOnRolesWrapper = useShowContentBasedOnRoles()
    const [focusedStation, setFocusedStation] = useState(0)
    const [displayCopyZone, setDisplayCopyZone] = useState(false)
    const stationsRef = useRef()
    const scrolledOnce = useRef(false)

    // useEffect(() => {
    //     const sortedInitialStations = [
    //         ...stations.sort((a, b) => {
    //             return stationOrdering?.[a.id] - stationOrdering?.[b.id]
    //         }),
    //     ]
    //     setInitialStations(sortedInitialStations)
    //     setReorderableStations(sortedInitialStations)
    // }, [stations, stationOrdering])

    //scrolls to NFC tag when page is opened
    useEffect(() => {
        if (!scrolledOnce.current) {
            if (!stationIdToScroll) {
                scrolledOnce.current = true
            }
            const element = document.getElementById(
                `station-${stationIdToScroll}`
            )
            if (element) {
                scrolledOnce.current = true
                element.scrollIntoView({
                    behavior: 'auto',
                })
            }
        }
    }, [stationsRef.current])

    useEffect(() => {
        async function asyncDownloadZoneReport() {
            if (isDownloadingZoneReport) {
                setInitiateZoneReportDownload(false)
                const reportContents = await downloadZoneReport(id.toString())
                downloadExcelFiles(reportContents)
            }
        }

        asyncDownloadZoneReport()
    }, [isDownloadingZoneReport])

    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(TouchSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    )

    const handleDragEnd = (event) => {
        const { active, over } = event

        if (active.id !== over.id) {
            setReorderableStations((items) => {
                const activeIndex = items.findIndex((el) => el.id === active.id)
                const overIndex = items.findIndex((el) => el.id === over.id)

                return arrayMove(items, activeIndex, overIndex)
            })
        }
    }

    const handleSubmitReorder = async () => {
        const orderingJSON = {}
        for (const [index, station] of reorderableStations.entries()) {
            orderingJSON[station.id] = index + 1
        }

        await updateStationOrdering(id, orderingJSON).then(() =>
            updateZoneStationOrdering(orderingJSON)
        )
    }

    const handleFocusChange = (currentStationId) => {
        const currentStationIndex = stations.findIndex(
            (i) => i.id === currentStationId
        )
        setFocusedStation(currentStationIndex + 1)
    }

    return (
        <Box sx={styles.innerContainer}>
            <Box sx={styles.buttonList}>
                {displayQRScanner && (
                    <QRScannerModal
                        displayQRScanner={displayQRScanner}
                        onSetDisplayQRScanner={setDisplayQRScanner}
                    />
                )}

                <div
                    className="normalButton"
                    onClick={() => setDisplayQRScanner(true)}
                >
                    <div className="buttonIconBlock">
                        <QrCode2Icon fontSize="large" />
                    </div>
                    <div className="buttonTextBlock">
                        <div className="buttonTextBiggerBlock">Scan</div>
                        <div className="buttonTextSmallerBlock">zone</div>
                    </div>
                </div>

                <div
                    className="normalButton"
                    onClick={() => setInitiateZoneReportDownload(true)}
                >
                    <div className="buttonIconBlock">
                        <DownloadIcon />
                    </div>
                    <div className="buttonTextBlock">
                        <div className="buttonTextBiggerBlock">Export</div>
                        <div className="buttonTextSmallerBlock">
                            zone report
                        </div>
                    </div>
                </div>

                <div
                    className="normalButton"
                    component={RouterLink}
                    to={`/zones/${id}/summary`}
                >
                    <div className="buttonIconBlock">
                        <ListIcon />
                    </div>
                    <div className="buttonTextBlock">
                        <div className="buttonTextBiggerBlock">Zone</div>
                        <div className="buttonTextSmallerBlock">summary</div>
                    </div>
                </div>

                {displayUpdateZone && (
                    <UpdateZone
                        zoneId={id}
                        displayModal={displayUpdateZone}
                        setDisplayModal={setDisplayUpdateZone}
                        onSetDisplayModal={setDisplayUpdateZone}
                        operation={'Update'}
                        onSuccess={asyncGetIndividualZone}
                    />
                )}

                <ShowContentBasedOnRolesWrapper
                    roles={[
                        'admin',
                        'organization_admin',
                        'organization_superuser',
                    ]}
                >
                    <div
                        className="normalButton"
                        onClick={() => setDisplayUpdateZone(true)}
                    >
                        <div className="buttonIconBlock">
                            <EditIcon />
                        </div>
                        <div className="buttonTextBlock">
                            <div className="buttonTextBiggerBlock">Update</div>
                            <div className="buttonTextSmallerBlock">zone</div>
                        </div>
                    </div>
                </ShowContentBasedOnRolesWrapper>

                {/* Only available to admins */}
                {displayDeleteZone && (
                    <DeleteZone
                        zoneId={id}
                        displayDeleteZone={displayDeleteZone}
                        onSetDisplayDeleteZone={setDisplayDeleteZone}
                    />
                )}

                <ShowContentBasedOnRolesWrapper
                    roles={[
                        'admin',
                        'organization_admin',
                        'organization_superuser',
                    ]}
                >
                    <div
                        className="normalButton"
                        onClick={() => {
                            setDisplayDeleteZone(true)
                        }}
                    >
                        <div className="buttonIconBlock">
                            <DeleteIcon />
                        </div>
                        <div className="buttonTextBlock">
                            <div className="buttonTextBiggerBlock">Delete</div>
                            <div className="buttonTextSmallerBlock">zone</div>
                        </div>
                    </div>
                </ShowContentBasedOnRolesWrapper>

                <ShowContentBasedOnRolesWrapper
                    roles={[
                        'admin',
                        'organization_admin',
                        'organization_superuser',
                    ]}
                >
                    <div
                        className="normalButton"
                        onClick={() => {
                            setDisplayCopyZone(true)

                            createStation(
                                zoneTitle.zoneId,
                                stations.stationTitle,
                                stations.stationDescription,
                                stations.target,
                                stations.rangeLow,
                                stations.rangeHigh,
                                stations.possibleRangeLow,
                                stations.possibleRangeHigh,
                                stations.measurementUnit,
                                stations.measurementOptions
                            )
                        }}
                    >
                        <div className="buttonIconBlock">
                            <CopyIcon />
                        </div>
                        <div className="buttonTextBlock">
                            <div className="buttonTextBiggerBlock">Copy</div>
                            <div className="buttonTextSmallerBlock">zone</div>
                        </div>
                    </div>
                </ShowContentBasedOnRolesWrapper>

                {displayCopyZone && (
                    <CopyZone
                        zone={{
                            zoneTitle,
                            imageS3Key,
                            organizationIds,
                        }}
                        displayCopyZone={displayCopyZone}
                        onSetDisplayCopyZone={setDisplayCopyZone}
                        zoneId={zoneId}
                        onSuccess={asyncReloadZone}
                        station={stations}
                    />
                )}

                {/* Only available to admins */}
                {displayCreateStation && (
                    <CreateStationModal
                        zoneId={id}
                        displayCreateStation={displayCreateStation}
                        onSetDisplayCreateStation={setDisplayCreateStation}
                        operation="Create"
                        onSuccess={asyncGetIndividualZone}
                    />
                )}

                <ShowContentBasedOnRolesWrapper
                    roles={[
                        'admin',
                        'organization_admin',
                        'organization_superuser',
                    ]}
                >
                    <div
                        className="normalButton"
                        onClick={() => setDisplayCreateStation(true)}
                    >
                        <div className="buttonIconBlock">
                            <CreateIcon />
                        </div>
                        <div className="buttonTextBlock">
                            <div className="buttonTextBiggerBlock">Create</div>
                            <div className="buttonTextSmallerBlock">
                                station
                            </div>
                        </div>
                    </div>
                </ShowContentBasedOnRolesWrapper>
            </Box>

            {/* <div
                sx={{
                    mt: 3,
                    width: '100%',
                    minHeight: '100%',
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: 'transparent',
                }}
            >
                <div className="titleBig">{zoneTitle}</div>

                <Paper
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        padding: '5px',
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                        gap: 3,
                        zIndex: 1299,
                    }}
                >
                    {isReorderingStations ? (
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 2,
                            }}
                        >
                            <div
                                className="normalButton normalButtonSmaller"
                                onClick={() => {
                                    handleSubmitReorder()
                                    setIsReorderingStations(false)
                                }}
                            >
                                <div className="buttonIconBlock">
                                    <SaveIcon />
                                </div>
                                <div className="buttonTextBlock">
                                    <div className="buttonTextBiggerBlock">
                                        Save
                                    </div>
                                </div>
                            </div>
                            <div
                                className="normalButton normalButtonSmaller"
                                onClick={() => {
                                    setIsReorderingStations(false)
                                    setReorderableStations(initialStations)
                                }}
                            >
                                <div className="buttonIconBlock">
                                    <XIcon />
                                </div>
                                <div className="buttonTextBlock">
                                    <div className="buttonTextBiggerBlock">
                                        Cancel
                                    </div>
                                </div>
                            </div>
                        </Box>
                    ) : (
                        reorderableStations.length > 1 && (
                            <div
                                className="normalButton normalButtonSmaller "
                                onClick={() => setIsReorderingStations(true)}
                            >
                                <div className="buttonIconBlock">
                                    <ReorderIcon />
                                </div>
                                <div className="buttonTextBlock">
                                    <div className="buttonTextBiggerBlock">
                                        Reorder
                                    </div>

                                    <div className="buttonTextSmallerBlock">
                                        stations
                                    </div>
                                </div>
                            </div>
                        )
                    )}
                </Paper>
            </div> */}

            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
            >
                <Box sx={{ flex: 1 }} ref={stationsRef}>
                    <SortableContext
                        items={stations}
                        strategy={rectSortingStrategy}
                    >
                        {stations
                            .sort(
                                (a, b) =>
                                    new Date(b.createdAt) -
                                    new Date(a.createdAt)
                            )
                            .map((station, index) => {
                                return (
                                    <IndividualStation
                                        {...station}
                                        zoneTitle={zoneTitle}
                                        isReorderingStations={
                                            isReorderingStations
                                        }
                                        asyncReloadZone={asyncGetIndividualZone}
                                        stationId={station.id}
                                        isFocused={focusedStation === index}
                                        setFocusedStation={handleFocusChange}
                                    />
                                )
                            })}
                    </SortableContext>
                </Box>
            </DndContext>
        </Box>
    )
}

IndividualZone.defaultProps = {
    stations: [],
    zoneTitle: '',
    onCreateStationMeasurement: () => {},
    loadingComplete: false,
}

export default IndividualZone
