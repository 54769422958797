import React from 'react'
import Plot from 'react-plotly.js'

export class Gauge extends React.Component {
    render() {
        const values =
            this.props.values?.map((stationMeasurement) =>
                parseFloat(stationMeasurement?.measurementValue)
            ) || []
        const value = values.length ? values[0] : 0 // Assuming the first value for demonstration

        // Default gauge range extension factor
        const rangeExtensionFactor = 0.2

        let gaugeMin, gaugeMax

        // Determine base min and max from the data or props
        let baseMin = this.props.min != null ? parseInt(this.props.min, 10) : 0
        let baseMax =
            this.props.max != null ? parseInt(this.props.max, 10) : value + 10

        // Adjust baseMin and baseMax based on the value to ensure it's within the range
        baseMin = Math.min(baseMin, value)
        baseMax = Math.max(baseMax, value)

        // Extend the gauge range by a certain factor to ensure visibility and aesthetic spacing
        gaugeMin = baseMin - Math.abs(baseMax - baseMin) * rangeExtensionFactor
        gaugeMax = baseMax + Math.abs(baseMax - baseMin) * rangeExtensionFactor

        const gaugeSteps = []

        if (this.props.showSigmaAvg) {
            gaugeMin =
                parseInt(this.props.stddevLower) < gaugeMin
                    ? Math.min(
                          parseInt(this.props.stddevLower) -
                              Math.abs(baseMax - baseMin) *
                                  rangeExtensionFactor,
                          gaugeMin
                      )
                    : gaugeMin
            gaugeMax =
                parseInt(this.props.stddevUpper) > gaugeMax
                    ? Math.max(
                          parseInt(this.props.stddevUpper) +
                              Math.abs(baseMax - baseMin) *
                                  rangeExtensionFactor,
                          gaugeMax
                      )
                    : gaugeMax
        }

        // Define gauge steps based on provided min/max or default to a single green zone
        if (this.props.min != null || this.props.max != null) {
            // Color zones based on provided min/max
            gaugeSteps.push(
                {
                    range: [gaugeMin, this.props.min || gaugeMin],
                    color: '#EE204D',
                }, // Red zone before min
                {
                    range: [
                        this.props.min || gaugeMin,
                        this.props.max || gaugeMax,
                    ],
                    color: '#6fe4ca',
                }, // Green zone between min and max
                {
                    range: [this.props.max || gaugeMax, gaugeMax],
                    color: '#EE204D',
                } // Red zone after max
            )
        } else {
            // Default to a single green zone if no min/max is provided
            gaugeSteps.push({ range: [gaugeMin, gaugeMax], color: '#6fe4ca' })
        }

        // add the upper and lower stddev lines if showSigmaAvg is true
        if (this.props.showSigmaAvg) {
            const stddevLowerThickness =
                parseInt(this.props.stddevLower) + (gaugeMax - gaugeMin) / 95
            const stddevUpperThickness =
                parseInt(this.props.stddevUpper) - (gaugeMax - gaugeMin) / 95
            gaugeSteps.push(
                {
                    range: [
                        parseInt(this.props.stddevLower),
                        stddevLowerThickness,
                    ],
                    color: 'black',
                },
                {
                    range: [
                        parseInt(this.props.stddevUpper),
                        stddevUpperThickness,
                    ],
                    color: 'black',
                }
            )
        }

        // Determine if the value is within the specified range
        const isValueInRange =
            this.props.min != null && this.props.max != null
                ? value >= this.props.min && value <= this.props.max
                : true // Default to true if no range is specified

        const gauge = {
            axis: { range: [gaugeMin, gaugeMax] },
            bar: {
                color: isValueInRange ? '#0dae8b' : '#9f0c2c', // Green if within range, red if out of range
                thickness: 0.2,
            },
            steps: gaugeSteps,
            bordercolor: 'transparent',
            ...(this.props.showSigmaAvg && {
                threshold: {
                    line: { color: 'blue', width: 4, dash: 'dot' },
                    thickness: 0.75,
                    value: this.props.mean,
                },
            }),
        }

        // Calculate the title length threshold for line break
        const titleLengthThreshold1 = 31 // Adjust as needed
        const titleLengthThreshold2 = 60 // Adjust as needed

        // Define the title text
        const titleText = this.props.title

        // Check if the title length exceeds the threshold
        const shouldBreakTitleLine1 = titleText.length > titleLengthThreshold1
        const shouldBreakTitleLine2 = titleText.length > titleLengthThreshold2

        let titleLine1 = titleText.substring(0, titleLengthThreshold1)
        let titleLine2 = shouldBreakTitleLine1
            ? titleText.substring(titleLengthThreshold1, titleLengthThreshold2)
            : ''
        let titleLine3 = shouldBreakTitleLine2
            ? titleText.substring(titleLengthThreshold2)
            : ''

        return (
            <div>
                <Plot
                    data={[
                        {
                            value: value,
                            type: 'indicator',
                            mode: 'gauge',
                            gauge: gauge,
                        },
                    ]}
                    layout={{
                        width: this.props.width,
                        height: this.props.height,
                        margin: {
                            l: 30,
                            r: 30,
                            t: 70,
                            b: 30,
                        },
                        paper_bgcolor: 'white',
                        title: {
                            text: `<b style="letter-spacing: -0.7px">${titleLine1}<br>${titleLine2}<br>${titleLine3}</b>`,
                            font: {
                                size: shouldBreakTitleLine2 ? 14 : 16,
                                'letter-spacing': '-3rem',
                                family: 'Montserrat',
                            },

                            y: shouldBreakTitleLine2 && 0.92, // Adjust vertical position based on the number of lines
                            x: 0.5,
                            xanchor: 'center',
                            yanchor: 'top',
                        },
                        xaxis: {
                            showticklabels: false,
                        },
                        yaxis: {
                            showticklabels: false,
                        },
                        annotations: [
                            {
                                text: `<b style="letter-spacing: -2px">${value}</b>`,
                                x: 0.5,
                                y: 0.2,
                                showarrow: false,
                                font: {
                                    size: 55,
                                    family: 'Arial, sans-serif',
                                    color: isValueInRange
                                        ? '#059e79'
                                        : '#9f0c2c', // Green if within range, red if out of range
                                },
                            },
                            {
                                text: this.props.units
                                    ? ` ${this.props.units}`
                                    : '',
                                x: 0.5,
                                y: 0.15,
                                showarrow: false,
                                font: {
                                    size: 14,
                                    family: 'Arial, sans-serif',
                                },
                            },

                            ...(this.props.showSigmaAvg
                                ? [
                                      {
                                          text: `Lower CL: ${
                                              this.props.stddevLower
                                                  ? `${this.props.stddevLower}`
                                                  : '-'
                                          }`,
                                          x: 0,
                                          y: 0,
                                          showarrow: false,
                                          font: {
                                              size: 13,
                                              family: 'Arial, sans-serif',
                                          },
                                      },
                                      {
                                          text: `Upper CL: ${
                                              this.props.stddevUpper
                                                  ? `${this.props.stddevUpper}`
                                                  : '-'
                                          }`,
                                          x: 1,
                                          y: 0,
                                          showarrow: false,
                                          font: {
                                              size: 13,
                                              family: 'Arial, sans-serif',
                                          },
                                      },
                                      {
                                          text: `<span style='color: blue;'>Average: ${
                                              this.props.mean
                                                  ? `${this.props.mean}`
                                                  : '-'
                                          }</span>`,
                                          x: 0.5,
                                          y: shouldBreakTitleLine1 ? 0.97 : 1,
                                          showarrow: false,
                                          font: {
                                              size: 14,
                                              family: 'Arial, sans-serif',
                                          },
                                      },
                                  ]
                                : [
                                      {
                                          text: `Min: ${
                                              this.props.min
                                                  ? `${this.props.min}${
                                                        this.props.units
                                                            ? ` ${this.props.units}`
                                                            : ''
                                                    }`
                                                  : '-'
                                          }`,
                                          x: 0,
                                          y: 0,
                                          showarrow: false,
                                          font: {
                                              size: 14,
                                              family: 'Arial, sans-serif',
                                              //  color: this.props.min
                                              //      ? '#D60033'
                                              //      : '#363636',
                                              color: '#D60033',
                                          },
                                      },
                                      {
                                          text: `Max: ${
                                              this.props.max
                                                  ? `${this.props.max}${
                                                        this.props.units
                                                            ? ` ${this.props.units}`
                                                            : ''
                                                    }`
                                                  : '-'
                                          }`,
                                          x: 1,
                                          y: 0,
                                          showarrow: false,
                                          font: {
                                              size: 13,
                                              family: 'Arial, sans-serif',
                                              //  color: this.props.min
                                              //      ? '#D60033'
                                              //      : '#363636',
                                              color: '#D60033',
                                          },
                                      },
                                      {
                                          text: `Target: ${
                                              this.props.target
                                                  ? `${this.props.target}${
                                                        this.props.units
                                                            ? ` ${this.props.units}`
                                                            : ''
                                                    }`
                                                  : '-'
                                          }`,
                                          x: 0.5,
                                          y: shouldBreakTitleLine1 ? 0.97 : 1,
                                          showarrow: false,
                                          font: {
                                              size: 14,
                                              family: 'Arial, sans-serif',
                                              color: '#EDAB07',
                                          },
                                      },
                                  ]),

                            {
                                text: 'Current:',
                                x: 0.5,
                                y: 0.55,
                                showarrow: false,
                                font: { size: 14, family: 'Arial, sans-serif' },
                            },
                        ],
                    }}
                />
            </div>
        )
    }
}

Gauge.defaultProps = {
    width: 300,
    height: 320,
    values: [],
    min: 0,
    max: 500,
    warn: 0.6,
    critical: 0.8,
    units: '',
}
