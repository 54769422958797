import axios from 'axios'

export const getAllUsers = async () => {
    return await axios
        .get('/api/users')
        .then(({ data }) => data)
        .catch((err) => console.error(err))
}

export const createOrganization = async (organizationName, ownerEmail) => {
    return await axios
        .post('/api/organizations', {
            organizationName,
            ownerEmail,
        })
        .then(({ data }) => data)
}

export const updateOrganization = async (
    organizationId,
    organizationName,
    ownerEmail
) => {
    return await axios
        .patch(`/api/organizations/${organizationId}`, {
            organizationName,
            ownerEmail,
        })
        .then(({ data }) => data)
}

export const changePassword = async (username, password) => {
    return await axios
        .post('/api/admin-change-password', {
            username,
            password,
        })
        .then(({ data }) => data)
}

export const changeRole = async (username, role) => {
    return await axios
        .post('/api/change-role', {
            username,
            role,
        })
        .then(({ data }) => data)
}
