import React, { useState, useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import {
    Box,
    Button,
    Typography,
    InputLabel,
    NativeSelect,
    FormControl,
    Stack,
    Grid,
    IconButton,
    InputAdornment,
} from '@mui/material'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useToastContext } from '../../../context/ToastContext'
import IndividualStation from './IndividualStation'
import { createStation, createZone, updateZone, getIndividualZone } from '../actions'


const CopyZone = ({
    displayCopyZone,
    onSetDisplayCopyZone,
    zone,
    station,
    onSuccess = () => {},

}) => {
    const [location, setLocation] = useState('')
    const history = useHistory()
    const [formValues, setFormValues] = useState({})
    const [error, setError] = useState(false)
    const toastContext = useToastContext()
    const onCopyZone = async () => {
        
            try {
                const newZone = await createZone(zone.zoneTitle, zone.imageS3Key, zone.organizationIds) 
                let i = 0
                const copyStation = async () => {
                    await createStation(newZone.id,
                        station[i].stationTitle,
                        station[i].stationDescription,
                        station[i].target,
                        station[i].rangeLow,
                        station[i].rangeHigh,
                        station[i].measurementUnit,
                        station[i].measurementOptions)
                        .then(() => {
                            onSuccess?.()
                        })
                        .catch((e) => toastContext.sendToast(e.message, 'error'))
        
                        }       
                station.forEach(function() {
                    console.log(station[i])
                    copyStation()
                    i = i + 1
                })
                toastContext.sendToast('Successfully Copied Zone')

            }
            catch (e) {
                toastContext.sendToast(e.message, 'error')

            }
   
       
        onSetDisplayCopyZone(false)

       
   
    }
    
    return (
        displayCopyZone && (
            <Dialog
                open={displayCopyZone}
                onClose={() => onSetDisplayCopyZone(false)}
            >
                <DialogContent>
                    <DialogContentText id="create-zone-error">
                        {error}
                    </DialogContentText>
                    <DialogContentText>
                        Are you sure you want to copy this zone? This action
                        cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => onSetDisplayCopyZone(false)}>
                        Cancel
                    </Button>
                    <Button onClick={onCopyZone}>Copy</Button>
                </DialogActions>
            </Dialog>
        )
    )

}
export default CopyZone