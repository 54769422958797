import React, { useState, useEffect } from 'react'
import localforage from 'localforage'
import IndividualLogsheet from './IndividualLogsheet'
import {
    getIndividualLogsheet,
    getMainLogsheetStationOrdering,
} from '../actions'
import useLoader from '../../../hooks/useLoader'

//Renders IndavidualLogsheet when the url path is a match
//Uses the useLoader hook to display the waiting screen while logsheet data
//is bieng retrieved from either localforage or the database
const IndividualLogsheetPath = ({ match }) => {
    const masterLogsheetId = match.params.masterLogsheetId
    const logsheetId = match.params.logsheetId
    const stationId = match.params.stationId
    const [logsheet, setIndividualLogsheet] = useState({})
    const [stationOrdering, setStationOrdering] = useState({})
    const [LoaderWrapper, setIsLoading] = useLoader()

    const asyncGetIndividualLogsheet = async () => {
        setIsLoading(true)

        if (!navigator.onLine) {
            const allMasterLogsheets = await localforage.getItem('allLogsheets')
            const allLogsheetOrderings = await localforage.getItem(
                'allLogsheetStationOrdering'
            )

            if (allMasterLogsheets) {
                setIndividualLogsheet(
                    allMasterLogsheets
                        .find(
                            (masterLogsheet) =>
                                +masterLogsheet.id === +masterLogsheetId
                        )
                        .logsheets.find(
                            (logsheet) => +logsheet.id === +logsheetId
                        )
                )

                if (allLogsheetOrderings) {
                    const mainLogsheet = allMasterLogsheets
                        .find(
                            (masterLogsheet) =>
                                +masterLogsheet.id === +masterLogsheetId
                        )
                        .logsheets.find(
                            (logsheet) => logsheet.includesAllStations
                        )
                    const mainLogsheetOrdering = allLogsheetOrderings.find(
                        (logsheetOrdering) =>
                            +logsheetOrdering.logsheetId === +mainLogsheet.id
                    )

                    setStationOrdering(
                        mainLogsheetOrdering?.ordering
                            ? JSON.parse(mainLogsheetOrdering.ordering)
                            : {}
                    )
                }
                setIsLoading(false)
                return
            }
        }

        const individualLogsheetResult = await getIndividualLogsheet(
            masterLogsheetId,
            logsheetId
        )

        setIndividualLogsheet(individualLogsheetResult.data)

        const mainLogsheetStationOrderingResults =
            await getMainLogsheetStationOrdering(masterLogsheetId)

        setStationOrdering(mainLogsheetStationOrderingResults)

        setIsLoading(false)
    }

    useEffect(() => {
        asyncGetIndividualLogsheet()
    }, [logsheetId])

    return (
        <LoaderWrapper>
            <IndividualLogsheet
                {...logsheet}
                masterLogsheetId={masterLogsheetId}
                stationOrdering={stationOrdering}
                updateStationOrdering={setStationOrdering}
                asyncGetIndividualLogsheet={asyncGetIndividualLogsheet}
                stationIdToScroll={stationId}
            />
        </LoaderWrapper>
    )
}

export default IndividualLogsheetPath
