import React, { useState, useRef } from 'react'
import { useShowContentBasedOnRoles } from '../../../hooks/useShowContentBasedOnRoles'
import { Box, Typography, Paper } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import CopyContentIcon from '@mui/icons-material/ContentCopy'
import { makeStyles } from '@mui/styles'
import DeleteStation from './DeleteStation'
import CopyStation from './CopyStation'
import UpdateStation from './CreateOrUpdateStation'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { styles } from '../../../theme/styles'

import { ReactComponent as StationIcon } from '../../../icons/stations/station.svg'
import { ReactComponent as DangerIcon } from '../../../icons/danger.svg'

const useStyles = makeStyles((theme) => ({
    stationTitleBox: {
        // marginBottom: theme.spacing(4),
        height: '150px',
        width: '300px',
    },
    stationMeasurements: {
        height: '150px',
        width: '300px',
    },
    // stationMeasurementTextField: {
    //     '& .MuiOutlinedInput-input': {
    //         '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
    //             '-webkit-appearance': 'none',
    //         },
    //     },
    // },
}))

function IndividualStation({
    id,
    stationTitle,
    stationDescription,
    target,
    rangeLow,
    rangeHigh,
    possibleRangeLow,
    possibleRangeHigh,
    measurementUnit,
    broken,
    asyncReloadZone,
    isReorderingStations,
    zoneId,
    stationId,
    measurementOptions,
}) {
    const classes = useStyles()
    const ShowContentBasedOnRolesWrapper = useShowContentBasedOnRoles()
    const [displayDeleteStation, setDisplayDeleteStation] = useState(false)
    const [displayEditStation, setDisplayEditStation] = useState(false)
    const [displayCopyStation, setDisplayCopyStation] = useState(false)

    const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({
            id: id,
            disabled: isReorderingStations ? false : true,
        })

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    }

    const stationRef = useRef(null)

    //When a station is clicked the URL is updated
    const updateURL = () => {
        const newUrl = `/zones/${zoneId}/${stationId}`
        window.history.pushState(null, '', newUrl)
    }

    return (
        <div
            key={stationId}
            id={`station-${stationId}`}
            mb={12}
            ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners}
            onClick={(e) => {
                updateURL()
            }}
        >
            <div className="station">
                <div className="stationInfo">
                    <StationIcon className="stationLogo" />
                    <div>
                        <div style={styles.cardTextStationBig}>
                            {stationTitle}
                        </div>
                        <div style={styles.cardTextStationSmallerBlock}>
                            {stationDescription}
                        </div>

                        {broken && (
                            <div style={styles.cardTextStationRedBlock}>
                                <DangerIcon
                                    style={{
                                        cursor: 'pointer',
                                        width: '17px',
                                    }}
                                />
                                <div style={styles.cardTextStationSmallRed}>
                                    Instrument is currently broken
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="stationButtons">
                    <ShowContentBasedOnRolesWrapper
                        roles={[
                            'admin',
                            'organization_admin',
                            'organization_superuser',
                        ]}
                    >
                        <>
                            <Typography
                                style={{
                                    cursor: 'pointer',
                                    marginRight: 5,
                                }}
                            >
                                <EditIcon
                                    onClick={() => setDisplayEditStation(true)}
                                />
                            </Typography>

                            <Typography
                                style={{
                                    cursor: 'pointer',
                                    marginRight: 5,
                                }}
                            >
                                <DeleteIcon
                                    onClick={() =>
                                        setDisplayDeleteStation(true)
                                    }
                                />
                            </Typography>
                            <Typography
                                style={{
                                    cursor: 'pointer',
                                }}
                            >
                                <CopyContentIcon
                                    onClick={() => setDisplayCopyStation(true)}
                                />
                            </Typography>
                        </>
                    </ShowContentBasedOnRolesWrapper>
                </div>
                {isReorderingStations && (
                    <DragIndicatorIcon style={{ marginTop: 0 }} />
                )}
            </div>
            <Box>
                {/* Only available to admins */}
                {displayEditStation && (
                    <UpdateStation
                        station={{
                            id,
                            stationTitle,
                            stationDescription,
                            target,
                            rangeLow,
                            rangeHigh,
                            possibleRangeLow,
                            possibleRangeHigh,
                            measurementUnit,
                            measurementOptions,
                        }}
                        zoneId={zoneId}
                        displayCreateStation={displayEditStation}
                        onSetDisplayCreateStation={setDisplayEditStation}
                        operation="Update"
                        onSuccess={asyncReloadZone}
                    />
                )}

                {/* Only available to admins */}
                {displayDeleteStation && (
                    <DeleteStation
                        displayDeleteStation={displayDeleteStation}
                        onSetDisplayDeleteStation={setDisplayDeleteStation}
                        zoneId={zoneId}
                        stationId={id}
                        onSuccess={asyncReloadZone}
                    />
                )}
                {displayCopyStation && (
                    <CopyStation
                        station={{
                            id,
                            stationTitle,
                            stationDescription,
                            target,
                            rangeLow,
                            rangeHigh,
                            possibleRangeLow,
                            possibleRangeHigh,
                            measurementUnit,
                            measurementOptions,
                        }}
                        displayCopyStation={displayCopyStation}
                        onSetDisplayCopyStation={setDisplayCopyStation}
                        zoneId={zoneId}
                        stationId={id}
                        onSuccess={asyncReloadZone}
                    />
                )}
            </Box>
        </div>
    )
}

IndividualStation.defaultProps = {
    stationTitle: '',
    id: '',
    stationDescription: '',
    target: '',
    rangeLow: '',
    rangeHigh: '',
    possibleRangeLow: '',
    possibleRangeHigh: '',
    lastAccess: '2020-01-01T00:00:00.000Z',
}

export default IndividualStation
