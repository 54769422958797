import { useState } from 'react'
import { CircularProgress, Box } from '@mui/material'

//Same as useLoader except it also gives the caller access to 'isLoading' and displays different text
export const useCrudLoader = () => {
    const [isLoading, setisLoading] = useState(false)

    const LoaderWrapper = ({ children }) => {
        return isLoading ? (
            <Box
                sx={{
                    display: 'flex',
                    height: '25vh',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                <CircularProgress sx={{ marginBottom: 3 }} />
                Updating...
            </Box>
        ) : (
            <>{children}</>
        )
    }

    return [LoaderWrapper, setisLoading, isLoading]
}

export default useCrudLoader