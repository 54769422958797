import React, { useState, useEffect } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { Paper, Typography } from '@mui/material'
import { getAllLogbookSignoffs } from '../actions'
import useLoader from '../../../hooks/useLoader'
import { useToastContext } from '../../../context/ToastContext'

const AdminLogbookSignoffs = () => {
    const [signoffs, setSignoffs] = useState([])
    const [LoaderWrapper, setIsLoading] = useLoader()
    const toastContext = useToastContext()

    const fetchSignoffs = async () => {
        setIsLoading(true)
        try {
            const data = await getAllLogbookSignoffs()
            setSignoffs(data)
        } catch (error) {
            toastContext.sendToast('Error fetching signoffs', 'error')
        }
        setIsLoading(false)
    }

    useEffect(() => {
        fetchSignoffs()
    }, [])

    const columns = [
        { field: 'masterLogbookTitle', headerName: 'Master Logbook', flex: 1 },
        { field: 'logbookTitle', headerName: 'Logbook', flex: 1 },
        { field: 'userEmail', headerName: 'User Email', flex: 1 },
        {
            field: 'signedAt',
            headerName: 'Signed At',
            flex: 1,
            valueGetter: (params) => new Date(params.value).toLocaleString(),
        },
    ]

    return (
        <LoaderWrapper>
            <Typography variant="h4" gutterBottom>
                All Logbook Signoffs (Last 14 Days)
            </Typography>
            <Paper style={{ height: 400, width: '100%' }}>
                <DataGrid
                    rows={signoffs}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10, 25, 50]}
                    disableSelectionOnClick
                />
            </Paper>
        </LoaderWrapper>
    )
}

export default AdminLogbookSignoffs
