import React, { useEffect } from 'react'
import { Box, CircularProgress } from '@mui/material'
import { useAuth } from 'oidc-react'
import { signOut } from '../Profile/actions'
import localforage from 'localforage'

//signs user out as soon as they open this page
const SignOut = () => {
    const auth = useAuth()

    useEffect(() => {

        localStorage.clear(); //synchronous. clears localstorage

        const clearCacheandIndexedDB = async () => {
            const allCaches = await caches.keys();
            await Promise.all(
                allCaches.map(cache => caches.delete(cache)) //clears cache
            );
            await localforage.clear(); //clears indexeddb
        }

        const revokeTokens = async () => {
            await signOut()
        }

        clearCacheandIndexedDB().then(()=>{revokeTokens().then(() => auth.signOutRedirect())})
        .catch(() => auth.signOutRedirect()); //If something goes wrong we still want to logout

    }, [auth])

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
            }}
        >
            <CircularProgress sx={{ marginBottom: 3 }} />
            Signing out...
        </Box>
    )
}

export default SignOut
