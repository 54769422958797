import React, { useState, useEffect, useMemo } from 'react'
import {
    Box,
    Dialog,
    TextField,
    DialogActions,
    DialogContent,
    Button,
    Tabs,
    Tab,
    Checkbox,
    Input,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import useLoader from '../../../hooks/useLoader'
import {
    getLogbook,
    createLogbookComment,
    createLogbookPrompt,
    updateLogbookPrompt,
    updateLogbookComment,
    getLogbookPrompts,
    createLogbookSignOff,
    getLogbookMissedMeasurements,
    deleteLogbookPrompt,
} from '../actions'
import { styles } from '../../../theme/styles'
import { ReactComponent as CreateIcon } from '../../../icons/buttons/create.svg'
import { ReactComponent as SaveIcon } from '../../../icons/buttons/save.svg'
import { ReactComponent as DeleteIcon } from '../../../icons/buttons/delete.svg'
import { useShowContentBasedOnRoles } from '../../../hooks/useShowContentBasedOnRoles'
import { useToastContext } from '../../../context/ToastContext'
import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'

const IndividualLogbook = ({ match }) => {
    const ShowContentBasedOnRolesWrapper = useShowContentBasedOnRoles()
    const toastContext = useToastContext()

    const logbookId = match.params.logbookId
    const masterLogbookId = match.params.masterLogbookId

    const [tabValue, setTabValue] = useState(0)
    const [logbook, setLogbook] = useState()
    const [
        displayCreateLogbookCommentModal,
        setDisplayCreateLogbookCommentModal,
    ] = useState(false)
    const [displayCreatePromptModal, setDisplayCreatePromptModal] =
        useState(false)
    const [comment, setComment] = useState('')
    const [prompt, setPrompt] = useState('')
    const [promptValues, setPromptValues] = useState({})
    const [comments, setComments] = useState([])
    const [missedMeasurements, setMissedMeasurements] = useState([])

    const [LoaderWrapper, setIsLoading] = useLoader()

    const handleSubmitSignoff = async () => {
        try {
            await createLogbookSignOff(logbookId)
            toastContext.sendToast('Logbook Signed Off Successfully')
            asyncGetLogbook()
        } catch (error) {
            toastContext.sendToast('Error Signing Off Logbook', 'error')
        }
    }

    const asyncGetLogbook = async () => {
        setIsLoading(true)
        const data = await getLogbook(logbookId)
        setLogbook(data)
        setComments(data.comments)
        setIsLoading(false)
        return
    }

    const fetchLogbookPrompts = async () => {
        setIsLoading(true)
        const data = await getLogbookPrompts(logbookId)
        setPromptValues(data)
        setIsLoading(false)
        return
    }

    const fetchSkippedLogbookMeasurements = async () => {
        setIsLoading(true)
        const data = await getLogbookMissedMeasurements(logbookId)
        setMissedMeasurements(data)
        setIsLoading(false)
        return
    }

    useEffect(() => {
        asyncGetLogbook()
    }, [logbookId])

    useEffect(() => {
        if (tabValue === 1) {
            fetchLogbookPrompts()
        } else if (tabValue === 3) {
            fetchSkippedLogbookMeasurements()
        }
    }, [tabValue])

    const handleClose = () => {
        setDisplayCreateLogbookCommentModal(false)
        setDisplayCreatePromptModal(false)
    }

    const handleCreateComment = async () => {
        const tempComment = comment
        try {
            await createLogbookComment(logbookId, comment)
            setComment(null)
            asyncGetLogbook()
            setDisplayCreateLogbookCommentModal(false)
        } catch {
            setComment(tempComment)
        }
    }

    const handleCreateLogbookPrompt = async () => {
        const tempPrompt = prompt
        try {
            await createLogbookPrompt(masterLogbookId, logbookId, prompt)
            setPrompt(null)
            fetchLogbookPrompts()
        } catch {
            setPrompt(tempPrompt)
        }
    }

    const handleEditLogbookPrompt = async (promptIdx) => {
        const prompt = promptValues[promptIdx]

        try {
            await updateLogbookPrompt(logbookId, prompt.id, {
                promptIsTrue: prompt.promptIsTrue,
                promptComment: prompt.promptComment,
            })
            fetchLogbookPrompts()
        } catch {}
    }

    const handleEditComment = async (commentIdx) => {
        const comment = comments[commentIdx]

        try {
            await updateLogbookComment(logbookId, comment.id, {
                comment: comment.comment,
            })

            asyncGetLogbook()
        } catch {}
    }

    const handleDeleteLogbookPrompt = async (promptId) => {
        try {
            await deleteLogbookPrompt(logbookId, masterLogbookId, promptId)
            fetchLogbookPrompts()
        } catch {}
    }

    const createCommentSourceInfo = (comment) => {
        if (
            comment.masterLogsheetName &&
            comment.logsheetName &&
            comment.stationName
        ) {
            return `Master Logsheet: ${comment.masterLogsheetName}, Logsheet: ${comment.logsheetName}, Station: ${comment.stationName}`
        } else if (comment.masterLogsheetName && comment.logsheetName) {
            return `Master Logsheet: ${comment.masterLogsheetName}, Logsheet: ${comment.logsheetName}`
        } else if (comment.masterLogsheetName) {
            return `Master Logsheet: ${comment.masterLogsheetName}`
        } else if (comment.logsheetName && comment.stationName) {
            return `Logsheet: ${comment.logsheetName}, Station: ${comment.stationName}`
        } else if (comment.logsheetName) {
            return `Logsheet: ${comment.logsheetName}`
        } else {
            return 'Logbook comment'
        }
    }

    const CustomWidthTooltip = useMemo(() => {
        return styled(({ className, ...props }) => (
            <Tooltip {...props} classes={{ popper: className }} />
        ))({
            [`& .${tooltipClasses.tooltip}`]: {
                maxWidth: 700,
            },
        })
    }, [])

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mb={2}>
                <Tabs
                    value={tabValue}
                    onChange={(event, newValue) => setTabValue(newValue)}
                >
                    <Tab label="Comments" />
                    <Tab label="Handoff" />
                    <Tab label="Out of Range Values" />
                    <Tab label="Skipped Values" />
                </Tabs>
            </Box>

            {tabValue === 0 && (
                <LoaderWrapper>
                    <Box sx={styles.innerContainer}>
                        <Dialog
                            open={displayCreateLogbookCommentModal}
                            onClick={handleClose}
                        >
                            <DialogContent
                                sx={{
                                    width: '440px',
                                }}
                            >
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    name="name"
                                    label="Logbook comment"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                    onChange={(e) => setComment(e.target.value)}
                                    value={comment}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter' && !e.shiftKey) {
                                            e.preventDefault()
                                            handleCreateComment()
                                        }
                                    }}
                                    onClick={(e) => e.stopPropagation()}
                                />
                            </DialogContent>

                            <DialogActions>
                                <Button onClick={handleClose}>Cancel</Button>
                                <Button onClick={handleCreateComment}>
                                    Create
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Box sx={styles.buttonList}>
                            <div
                                className="normalButton"
                                onClick={() => {
                                    setDisplayCreateLogbookCommentModal(true)
                                }}
                            >
                                <div className="buttonIconBlock">
                                    <CreateIcon />
                                </div>
                                <div className="buttonTextBlock">
                                    <div className="buttonTextBiggerBlock">
                                        Create comment
                                    </div>
                                </div>
                            </div>
                        </Box>

                        {logbook && comments.length ? (
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Source</TableCell>
                                            <TableCell>Email</TableCell>
                                            <TableCell>Created At</TableCell>
                                            <TableCell>Comment</TableCell>
                                            <TableCell>Action </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {comments
                                            .sort((a, b) => {
                                                if (
                                                    a.masterLogsheetName <
                                                    b.masterLogsheetName
                                                )
                                                    return -1
                                                if (
                                                    a.masterLogsheetName >
                                                    b.masterLogsheetName
                                                )
                                                    return 1
                                                return 0
                                            })
                                            .sort(
                                                (a, b) =>
                                                    new Date(b.createdAt) -
                                                    new Date(a.createdAt)
                                            )
                                            .map((comment, idx) => (
                                                <TableRow key={idx}>
                                                    <TableCell>
                                                        {createCommentSourceInfo(
                                                            comment
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        {comment.userEmail}
                                                    </TableCell>
                                                    <TableCell>
                                                        {new Date(
                                                            comment.createdAt
                                                        ).toLocaleString()}
                                                    </TableCell>
                                                    <TableCell>
                                                        <CustomWidthTooltip
                                                            title={
                                                                <Typography
                                                                    style={{
                                                                        fontSize:
                                                                            '1rem',
                                                                    }}
                                                                >
                                                                    {comments[
                                                                        idx
                                                                    ]
                                                                        ?.comment ||
                                                                        ''}
                                                                </Typography>
                                                            }
                                                            arrow
                                                            placement="top"
                                                        >
                                                            <Input
                                                                value={
                                                                    comments[
                                                                        idx
                                                                    ]
                                                                        ?.comment ||
                                                                    ''
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const tempComments =
                                                                        [
                                                                            ...comments,
                                                                        ]
                                                                    tempComments[
                                                                        idx
                                                                    ] = {
                                                                        ...tempComments[
                                                                            idx
                                                                        ],
                                                                        comment:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    }
                                                                    setComments(
                                                                        tempComments
                                                                    )
                                                                }}
                                                            />
                                                        </CustomWidthTooltip>
                                                    </TableCell>
                                                    <TableCell>
                                                        <div
                                                            style={{
                                                                display:
                                                                    'inline-flex',
                                                            }}
                                                            className="normalButton normalButtonSmaller"
                                                            onClick={() => {
                                                                handleEditComment(
                                                                    idx
                                                                )
                                                            }}
                                                        >
                                                            <div className="buttonIconBlock">
                                                                <SaveIcon />
                                                            </div>
                                                            <div className="buttonTextBlock">
                                                                <div className="buttonTextBiggerBlock">
                                                                    Save
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            'No comments'
                        )}
                    </Box>
                </LoaderWrapper>
            )}

            {tabValue === 1 && (
                <LoaderWrapper>
                    <Box sx={styles.innerContainer}>
                        <Dialog
                            open={displayCreatePromptModal}
                            onClick={handleClose}
                        >
                            <DialogContent
                                sx={{
                                    width: '440px',
                                }}
                            >
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    name="name"
                                    label="Prompt"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                    onChange={(e) => setPrompt(e.target.value)}
                                    value={prompt}
                                />
                            </DialogContent>

                            <DialogActions>
                                <Button onClick={handleClose}>Cancel</Button>
                                <Button onClick={handleCreateLogbookPrompt}>
                                    Create
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <ShowContentBasedOnRolesWrapper roles={['admin']}>
                            <Box sx={styles.buttonList}>
                                <div
                                    className="normalButton"
                                    onClick={() => {
                                        setDisplayCreatePromptModal(true)
                                    }}
                                >
                                    <div className="buttonIconBlock">
                                        <CreateIcon />
                                    </div>
                                    <div className="buttonTextBlock">
                                        <div className="buttonTextBiggerBlock">
                                            Create prompt
                                        </div>
                                    </div>
                                </div>
                            </Box>
                        </ShowContentBasedOnRolesWrapper>

                        {logbook && promptValues.length ? (
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Prompt</TableCell>
                                            <TableCell>Yes</TableCell>
                                            <TableCell>Comment</TableCell>
                                            <TableCell>
                                                Last edited by
                                            </TableCell>
                                            <TableCell>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {promptValues
                                            .sort(
                                                (a, b) =>
                                                    new Date(b.createdAt) -
                                                    new Date(a.createdAt)
                                            )
                                            .map((prompt, idx) => (
                                                <TableRow key={idx}>
                                                    <TableCell>
                                                        {prompt.prompt}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Checkbox
                                                            checked={
                                                                promptValues[
                                                                    idx
                                                                ]
                                                                    ?.promptIsTrue ||
                                                                false
                                                            }
                                                            onChange={(e) => {
                                                                const tempPromptValues =
                                                                    [
                                                                        ...promptValues,
                                                                    ]
                                                                tempPromptValues[
                                                                    idx
                                                                ] = {
                                                                    ...tempPromptValues[
                                                                        idx
                                                                    ],
                                                                    promptIsTrue:
                                                                        e.target
                                                                            .checked,
                                                                }
                                                                setPromptValues(
                                                                    tempPromptValues
                                                                )
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <CustomWidthTooltip
                                                            title={
                                                                <Typography
                                                                    style={{
                                                                        fontSize:
                                                                            '1rem',
                                                                    }}
                                                                >
                                                                    {promptValues[
                                                                        idx
                                                                    ]
                                                                        ?.promptComment ||
                                                                        ''}
                                                                </Typography>
                                                            }
                                                            arrow
                                                            placement="top"
                                                        >
                                                            <Input
                                                                value={
                                                                    promptValues[
                                                                        idx
                                                                    ]
                                                                        ?.promptComment ||
                                                                    ''
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const tempPromptValues =
                                                                        [
                                                                            ...promptValues,
                                                                        ]
                                                                    tempPromptValues[
                                                                        idx
                                                                    ] = {
                                                                        ...tempPromptValues[
                                                                            idx
                                                                        ],
                                                                        promptComment:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    }
                                                                    setPromptValues(
                                                                        tempPromptValues
                                                                    )
                                                                }}
                                                            />
                                                        </CustomWidthTooltip>
                                                    </TableCell>
                                                    <TableCell>
                                                        {prompt.userEmail}
                                                    </TableCell>
                                                    <TableCell>
                                                        <div
                                                            style={{
                                                                display:
                                                                    'inline-flex',
                                                            }}
                                                            className="normalButton normalButtonSmaller"
                                                            onClick={() => {
                                                                handleEditLogbookPrompt(
                                                                    idx
                                                                )
                                                            }}
                                                        >
                                                            <div className="buttonIconBlock">
                                                                <SaveIcon />
                                                            </div>
                                                            <div className="buttonTextBlock">
                                                                <div className="buttonTextBiggerBlock">
                                                                    Save
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <ShowContentBasedOnRolesWrapper
                                                            roles={['admin']}
                                                        >
                                                            <div
                                                                style={{
                                                                    display:
                                                                        'inline-flex',
                                                                    marginLeft:
                                                                        '10px',
                                                                }}
                                                                className="normalButton normalButtonSmaller"
                                                                onClick={() => {
                                                                    handleDeleteLogbookPrompt(
                                                                        prompt.id
                                                                    )
                                                                }}
                                                            >
                                                                <div className="buttonIconBlock">
                                                                    <DeleteIcon />
                                                                </div>
                                                                <div className="buttonTextBlock">
                                                                    <div className="buttonTextBiggerBlock">
                                                                        Delete
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ShowContentBasedOnRolesWrapper>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            'No prompts'
                        )}
                    </Box>
                </LoaderWrapper>
            )}

            {tabValue === 2 && (
                <LoaderWrapper>
                    <Box sx={styles.innerContainer}>
                        {logbook && logbook.measurements.length ? (
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Source</TableCell>
                                            <TableCell>Value</TableCell>
                                            <TableCell>User</TableCell>
                                            <TableCell>Range Low</TableCell>
                                            <TableCell>Range High</TableCell>
                                            <TableCell>Target</TableCell>
                                            <TableCell>Comment</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {logbook.measurements
                                            .sort(
                                                (a, b) =>
                                                    new Date(b.createdAt) -
                                                    new Date(a.createdAt)
                                            )
                                            .map((measurement, idx) => (
                                                <TableRow key={idx}>
                                                    <TableCell>
                                                        {measurement.Station
                                                            .stationDescription
                                                            ? `${measurement.Station.stationTitle} - ${measurement.Station.stationDescription}`
                                                            : `${measurement.Station.stationTitle}`}
                                                    </TableCell>
                                                    <TableCell>
                                                        {
                                                            measurement.measurementValue
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        {measurement.userEmail}
                                                    </TableCell>
                                                    <TableCell>
                                                        {
                                                            measurement.Station
                                                                .rangeLow
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        {
                                                            measurement.Station
                                                                .rangeHigh
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        {
                                                            measurement.Station
                                                                .target
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        <CustomWidthTooltip
                                                            title={
                                                                <Typography
                                                                    style={{
                                                                        fontSize:
                                                                            '1rem',
                                                                    }}
                                                                >
                                                                    {measurement?.comment ||
                                                                        ''}
                                                                </Typography>
                                                            }
                                                            arrow
                                                            placement="top"
                                                        >
                                                            <Box
                                                                sx={{
                                                                    maxWidth: 200,
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                    overflow:
                                                                        'hidden',
                                                                    textOverflow:
                                                                        'ellipsis',
                                                                }}
                                                            >
                                                                {measurement.comment ||
                                                                    ''}
                                                            </Box>
                                                        </CustomWidthTooltip>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            'No out of range values'
                        )}
                    </Box>
                </LoaderWrapper>
            )}

            {tabValue === 3 && (
                <LoaderWrapper>
                    <Box sx={styles.innerContainer}>
                        {logbook && missedMeasurements.length ? (
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Station Title</TableCell>
                                            <TableCell>
                                                Station Description
                                            </TableCell>
                                            <TableCell>
                                                Logsheet Title
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {missedMeasurements.map(
                                            (measurement, idx) => (
                                                <TableRow key={idx}>
                                                    <TableCell>
                                                        {
                                                            measurement.stationTitle
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        {
                                                            measurement.stationDescription
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        {
                                                            measurement.logsheetTitle
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            'No skipped values'
                        )}
                    </Box>
                </LoaderWrapper>
            )}

            <Paper
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    padding: '7px',
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    gap: 3,
                    zIndex: 1299,
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    boxShadow: 'none',
                }}
            >
                {logbook && logbook.signOffs && logbook.signOffs.length > 0 ? (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <CheckCircleIcon sx={{ color: 'green', mr: 1 }} />
                        <Typography variant="body1">
                            This logbook has already been signed off.
                        </Typography>
                    </Box>
                ) : (
                    <div className="normalButton" onClick={handleSubmitSignoff}>
                        <div className="buttonIconBlock">
                            <CheckCircleIcon />
                        </div>
                        <div className="buttonTextBlock">
                            <div className="buttonTextBiggerBlock">
                                Sign Off
                            </div>
                            <div className="buttonTextSmallerBlock">
                                Logbook
                            </div>
                        </div>
                    </div>
                )}
            </Paper>
        </>
    )
}

export default IndividualLogbook
