import React, { createContext, useReducer, useContext } from 'react'

export const SessionMeasurementsContext = createContext()

const initialState = []

export const ADD = 'ADD'
export const REMOVE_ALL = 'REMOVE_ALL'
export const GET_ALL = 'GET_ALL'

//For the reducer hook this function updates the sessionMeasurmentsState array by adding a new measurments
//or wiping the array blank
export const sessionMeasurementsReducer = (state, action) => {
    switch (action.type) {
        case ADD:
            return [
                {
                    id: +new Date(),
                    content: action.payload,
                },
                ...state,
            ]
        case REMOVE_ALL:
            return initialState
        default:
            return state
    }
}

//Sets up state through a reducer to keep track of session measurments and passes the ability to view and change state to child components
export const SessionMeasurementsProvider = (props) => {
    const [sessionMeasurementsState, sessionMeasurementsDispatch] = useReducer(
        sessionMeasurementsReducer,
        initialState
    )

    //Adds a new measurment by calling the dispatch function an causes a rerender
    const addSessionMeasurement = (
        zoneTitle,
        stationTitle,
        measurementValue,
        measurementUnit
    ) => {
        sessionMeasurementsDispatch({
            type: ADD,
            payload: {
                zoneTitle,
                stationTitle,
                measurementValue,
                measurementUnit,
            },
        })
    }

    //Removes all elements by using the dipatch function and causes a rerender
    const removeAllSessionMeasurements = () => {
        sessionMeasurementsDispatch({
            type: REMOVE_ALL,
        })
    }

    const measurementData = {
        sessionMeasurementsState,
        addSessionMeasurement,
        removeAllSessionMeasurements,
    }

    //gives all child components the ability to see the state of session measurments
    //and the ability to add and remove from it
    return (
        <SessionMeasurementsContext.Provider value={measurementData}>
            {props.children}
        </SessionMeasurementsContext.Provider>
    )
}

//gives other classes acces to the context provided here
export const useSessionMeausrementsContext = () => {
    return useContext(SessionMeasurementsContext)
}
