import React, { useState, useRef } from 'react'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { makeStyles } from '@mui/styles'
import { createZone, updateZone } from '../actions'
import { uploadImage } from '../../actions'
import { useToastContext } from '../../../context/ToastContext'
import useCrudLoader from '../../../hooks/useCrudLoader'

const useStyles = makeStyles((theme) => ({
    zoneImage: {
        height: '200px',
        width: '250px',
        objectFit: 'cover',
        overflow: 'hidden',
        alignSelf: 'center',
        display: 'flex',
        margin: 'auto',
    },
    zoneImageContainer: {
        padding: theme.spacing(2),
        margin: '16px 0px 0px 0px',
    },
}))

const SelectButton = styled(Button)({
    marginTop: '10px',
})

//A component that allows a user to create or modify a zone.
//Acts slightly differnt depending on props.operation. It is either update or create
export default function CreateOrUpdateZone(props) {
    const [open, setOpen] = React.useState(true)
    const [formValues, setFormValues] = useState({})
    const [error, setError] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null)
    const classes = useStyles()
    const toastContext = useToastContext()
    const [LoaderWrapper, setIsLoading, isLoading] = useCrudLoader()

    const handleClickOpen = () => {
        setOpen(true)
        props.setDisplayModal(true)
    }

    const handleClose = () => {
        setOpen(false)
        props.setDisplayModal(false)
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setFormValues({
            ...formValues,
            [name]: value,
        })
    }

    const handleSubmit = async (event) => {
        if(isLoading) {
            return;
        }
        event.preventDefault()
        let imageS3Key = null

        if (formValues.name) {
            setIsLoading(true);
            if (selectedFile) {
                imageS3Key = await uploadImage(
                    '1',
                    selectedFile.name,
                    selectedFile
                )
            }

            setFormValues({
                ...formValues,
                imageS3Key: imageS3Key,
            })

            if (props.operation === 'Create') {
                await createZone(formValues.name, imageS3Key)
                    .then(() => {
                        props?.onSuccess?.()
                        toastContext.sendToast('Successfully Created Zone')
                    })
                    .catch((e) => toastContext.sendToast(e.message, 'error'))
            } else {
                await updateZone(props.zoneId, formValues.name, imageS3Key)
                    .then(() => {
                        props?.onSuccess?.()
                        toastContext.sendToast('Successfully Updated Zone')
                    })
                    .catch((e) => toastContext.sendToast(e.message, 'error'))
            }
            setIsLoading(false)
            props.onSetDisplayModal(false)
        } else {
            setError('Zone title is required')
        }
    }

    const handleFileSelected = async (event) => {
        const files = event.target.files
        if (files.length > 0) {
            setSelectedFile(files[0])
        }
    }

    if (isLoading) {
        return (
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={'xs'}
            >
                <LoaderWrapper>
                </LoaderWrapper>
            </Dialog>
        )
    }
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogContent>
                <DialogContentText id="create-zone-error">
                    {error}
                </DialogContentText>

                <form onSubmit={handleSubmit}>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        name="name"
                        label="Zone Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={handleInputChange}
                    />

                    <SelectButton variant="contained" component="label">
                        Select Zone Image
                        <input
                            name="file"
                            onChange={handleFileSelected}
                            hidden
                            accept="image/*"
                            multiple
                            type="file"
                        />
                    </SelectButton>

                    {!!selectedFile && (
                        <Box className={classes.zoneImageContainer}>
                            <img
                                className={classes.zoneImage}
                                src={URL.createObjectURL(selectedFile)}
                                alt={selectedFile.name}
                            />
                            {/* File selected: {selectedFile.name} */}
                        </Box>
                    )}
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSubmit}>{props.operation}</Button>
            </DialogActions>
        </Dialog>
    )
}
