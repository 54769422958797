import React, { useState, useEffect } from 'react'
import localforage from 'localforage'
import IndividualLogsheetSignoff from './IndividualLogsheetSignoff'
import {
    getIndividualLogsheet,
    getLogsheetStationOrdering,
    getMainLogsheetStationOrdering,
    getMasterLogsheetSignOffs,
} from '../actions'
import useLoader from '../../../hooks/useLoader'

//Renders IndavidualLogsheet when the url path is a match
//Uses the useLoader hook to display the waiting screen while logsheet data
//is bieng retrieved from either localforage or the database
const IndividualLogsheetSignoffPath = ({ match }) => {
    const masterLogsheetId = match.params.masterLogsheetId
    const logsheetId = match.params.logsheetId
    const stationId = match.params.stationId
    const [logsheet, setIndividualLogsheet] = useState({})
    const [stationOrdering, setStationOrdering] = useState({})
    const [LoaderWrapper, setIsLoading] = useLoader()
    const [signOffs, setSignOffs] = useState([])

    const asyncGetIndividualLogsheet = async () => {
        setIsLoading(true)

        if (!navigator.onLine) {
            const allMasterLogsheets = await localforage.getItem('allLogsheets')
            const allLogsheetOrderings = await localforage.getItem(
                'allLogsheetStationOrdering'
            )

            if (allMasterLogsheets) {
                setIndividualLogsheet(
                    allMasterLogsheets
                        .find(
                            (masterLogsheet) =>
                                +masterLogsheet.id === +masterLogsheetId
                        )
                        .logsheets.find(
                            (logsheet) => +logsheet.id === +logsheetId
                        )
                )

                if (allLogsheetOrderings) {
                    console.log(allMasterLogsheets)
                    console.log(allLogsheetOrderings)
                    const logsheetOrdering = allLogsheetOrderings.find(
                        (logsheetOrdering) =>
                            +logsheetOrdering.logsheetId === +logsheetId
                    )

                    if (logsheetOrdering?.ordering) {
                        setStationOrdering(
                            JSON.parse(logsheetOrdering.ordering)
                        )
                    } else {
                        const mainLogsheet = allMasterLogsheets
                            .find(
                                (masterLogsheet) =>
                                    +masterLogsheet.id === +masterLogsheetId
                            )
                            .logsheets.find(
                                (logsheet) => logsheet.includesAllStations
                            )
                        const mainLogsheetOrdering = allLogsheetOrderings.find(
                            (logsheetOrdering) =>
                                +logsheetOrdering.logsheetId ===
                                +mainLogsheet.id
                        )

                        setStationOrdering(
                            mainLogsheetOrdering?.ordering
                                ? JSON.parse(mainLogsheetOrdering.ordering)
                                : {}
                        )
                    }
                }

                setIsLoading(false)
                return
            }
        }

        const [
            individualLogsheetResult,
            logsheetStationOrderingResults,
            masterLogsheetSignOffs,
        ] = await Promise.all([
            getIndividualLogsheet(masterLogsheetId, logsheetId),
            getLogsheetStationOrdering(logsheetId),
            getMasterLogsheetSignOffs(masterLogsheetId),
        ])

        setIndividualLogsheet(individualLogsheetResult.data)
        setSignOffs(masterLogsheetSignOffs)

        if (Object.keys(logsheetStationOrderingResults).length > 0) {
            setStationOrdering(logsheetStationOrderingResults)
        } else {
            const mainLogsheetStationOrderingResults =
                await getMainLogsheetStationOrdering(masterLogsheetId)

            setStationOrdering(mainLogsheetStationOrderingResults)
        }

        setIsLoading(false)
    }

    useEffect(() => {
        asyncGetIndividualLogsheet()
    }, [logsheetId])

    return (
        <LoaderWrapper>
            <IndividualLogsheetSignoff
                {...logsheet}
                masterLogsheetId={masterLogsheetId}
                stationOrdering={stationOrdering}
                updateStationOrdering={setStationOrdering}
                asyncGetIndividualLogsheet={asyncGetIndividualLogsheet}
                stationIdToScroll={stationId}
                signOffs={signOffs}
            />
        </LoaderWrapper>
    )
}

export default IndividualLogsheetSignoffPath
