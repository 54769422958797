import { useAuth } from 'oidc-react'

const COGNITO_USER_ROLE_FIELD_NAME = 'custom:user_role'
// A hook that changes the content of the state based on the level a user is, such as if they are admin.
export const useShowContentBasedOnRoles = () => {
    const auth = useAuth()

    const ShowContentBasedOnRolesWrapper = ({ roles, children }) => {
        if (!auth.userData) {
            return <></>
        }

        const userInRoles = roles.includes(
            auth.userData.profile[COGNITO_USER_ROLE_FIELD_NAME]
        )

        return userInRoles ? <>{children}</> : <></>
    }

    return ShowContentBasedOnRolesWrapper
}
