import React, { useState, useEffect, useRef } from 'react'

import { useHistory } from 'react-router-dom'

import { Box, Button, Typography } from '@mui/material'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { deleteStation } from '../actions'
import { useToastContext } from '../../../context/ToastContext'
import useCrudLoader from '../../../hooks/useCrudLoader'

//Component called to show user an option to delete a station
const DeleteStation = ({
    displayDeleteStation,
    onSetDisplayDeleteStation,
    zoneId,
    stationId,
    onSuccess = () => {},
}) => {
    const [location, setLocation] = useState('')
    const history = useHistory()
    const [formValues, setFormValues] = useState({})
    const [error, setError] = useState(false)
    const toastContext = useToastContext()
    const [LoaderWrapper, setIsLoading, isLoading] = useCrudLoader()

    const onDelete = async () => {
        if(isLoading) {
            return;
        }
        setIsLoading(true);
        await deleteStation(zoneId, stationId)
            .then(() => {
                onSuccess?.()
                toastContext.sendToast('Successfully Deleted Station')
            })
            .catch((e) => toastContext.sendToast(e.message, 'error'))
        setIsLoading(false)
        onSetDisplayDeleteStation(false)
    }
    
    if (isLoading && displayDeleteStation) {
        return (
            <Dialog
                open={displayDeleteStation}
                onClose={() => onSetDisplayDeleteStation(false)}
                fullWidth={true}
                maxWidth={'xs'}
            >
                <LoaderWrapper>
                </LoaderWrapper>
            </Dialog>
        )
    }

    return (
        displayDeleteStation && (
            <Dialog
                open={displayDeleteStation}
                onClose={() => onSetDisplayDeleteStation(false)}
            >
                <DialogContent>
                    <DialogContentText id="create-zone-error">
                        {error}
                    </DialogContentText>
                    <DialogContentText>
                        Are you sure you want to delete this station? This action
                        cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => onSetDisplayDeleteStation(false)}>
                        Cancel
                    </Button>
                    <Button onClick={onDelete}>Delete</Button>
                </DialogActions>
            </Dialog>
        )
    )
}

export default DeleteStation
