import React, { Fragment } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import SignIn from 'src/views/Auth/SignIn'
import SignOut from 'src/views/Auth/SignOut'
import Callback from './views/Auth/Callback'
import Profile from 'src/views/Profile'
import Account from 'src/views/AdminAccount'
import Dashboard from 'src/views/Dashboard'
import Zones from 'src/views/Zones'
import MasterLogsheets from 'src/views/Logsheets'
import MasterLogbooks from 'src/views/Logbooks'
import UserLogbookSignoffs from './views/Logbooks/UserLogbookSignOffs'
import AdminLogbookSignoffs from './views/Logbooks/AdminLogbookSignOffs'
import Logsheets from 'src/views/Logsheets/Logsheets'
import LogsheetsSignoffs from 'src/views/Logsheets/LogsheetsSignoffs'
import IndividualZone from 'src/views/Zones/IndividualZone'
import IndividualZoneSummary from 'src/views/Zones/IndividualZone/Summary'
import IndividualLogsheet from './views/Logsheets/IndividualLogsheet'
import IndividualLogsheetSignoff from './views/Logsheets/IndividualLogsheetSignoff'
import MasterLogsheetTable from './views/Logsheets/MasterLogsheetTable'
import Header from 'src/components/Header'
import PageError from 'src/views/PageNotFound/PageNotFound'
import AdminPortal from './views/AdminPortal'
import LogsheetComments from './views/Logsheets/IndividualLogsheet/LogsheetComments'
import StationComments from './views/Logsheets/IndividualLogsheet/StationComments'
import IndividualMasterLogbook from './views/Logbooks/IndividualMasterLogbook'
import IndividualLogbook from './views/Logbooks/IndividualLogbook'
import MasterLogsheetOrganizingTable from './views/Logsheets/MasterLogsheetOrganizingTable'

//Iterates through routes to set up the components and the url path to get to the components (different pages)
//Uses recursion to route many of the paths for authenticated users to have the page specified and the header component
export const renderRoutes = (routes = []) => (
    <Switch>
        {routes.map((route, i) => {
            const Guard = route.guard || Fragment
            const Layout = route.layout || Fragment
            const Component = route.component

            return (
                <Route
                    key={i}
                    path={route.path}
                    exact={route.exact}
                    render={(props) => (
                        <Guard>
                            <Layout>
                                {route.routes ? (
                                    renderRoutes(route.routes)
                                ) : (
                                    <Component {...props} />
                                )}
                            </Layout>
                        </Guard>
                    )}
                />
            )
        })}
    </Switch>
)

//routes available to non authenticated users
export const guestRoutes = [
    {
        exact: true,
        path: '/signin',
        component: SignIn,
    },
    {
        exact: true,
        path: '/callback',
        component: Callback,
    },
]

//routes available to authenticated users
export const authRoutes = [
    {
        exact: true,
        path: '/signout',
        component: SignOut,
    },
    {
        path: '/',
        layout: Header,
        routes: [
            {
                exact: true,
                path: '/',
                component: Dashboard,
            },
            {
                exact: true,
                path: '/account',
                component: Account,
            },
            {
                exact: true,
                path: '/zones',
                component: Zones,
            },
            {
                exact: true,
                path: '/zones/:zoneId/summary',
                component: IndividualZoneSummary,
            },
            {
                exact: true,
                path: '/zones/:zoneId/:stationId?',
                component: IndividualZone,
            },
            {
                exact: true,
                path: '/settings',
                component: Profile,
            },
            {
                exact: true,
                path: '/logsheets',
                component: MasterLogsheets,
            },
            {
                exact: true,
                path: '/logbooks',
                component: MasterLogbooks,
            },
            {
                exact: true,
                path: '/logbooks/:masterLogbookId',
                component: IndividualMasterLogbook,
            },
            {
                exact: true,
                path: '/logbooks/:masterLogbookId/logbook/:logbookId',
                component: IndividualLogbook,
            },
            {
                exact: true,
                path: '/user-logbook-signoffs',
                component: UserLogbookSignoffs,
            },
            {
                exact: true,
                path: '/admin-logbook-signoffs',
                component: AdminLogbookSignoffs,
            },
            {
                exact: true,
                path: '/logsheets/:masterLogsheetId',
                component: Logsheets,
            },
            {
                exact: true,
                path: '/logsheets/:masterLogsheetId/logsheet/:logsheetId/comments',
                component: LogsheetComments,
            },
            {
                exact: true,
                path: '/logsheets/:masterLogsheetId/logsheet/:logsheetId/station/:stationId/comments',
                component: StationComments,
            },
            {
                exact: true,
                path: '/logsheets/:masterLogsheetId/logsheet/:logsheetId/:stationId?',
                component: IndividualLogsheet,
            },
            {
                exact: true,
                path: '/logsheets/:masterLogsheetId/signoffs',
                component: LogsheetsSignoffs,
            },
            {
                exact: true,
                path: '/logsheets/:masterLogsheetId/signoff/:logsheetId/:stationId?',
                component: IndividualLogsheetSignoff,
            },
            {
                exact: true,
                path: '/logsheets/:masterLogsheetId/table',
                component: MasterLogsheetTable,
            },
            {
                exact: true,
                path: '/logsheets/:masterLogsheetId/organizing-table',
                component: MasterLogsheetOrganizingTable,
            },
            {
                path: '/callback',
                component: () => <Redirect to="/" />,
            },
            {
                exact: true,
                path: '/error',
                component: PageError,
            },
            {
                path: '*',
                component: () => <Redirect to="/error" />,
            },
        ],
    },
]

export const adminRoutes = [
    {
        layout: Header,
        exact: true,
        path: '/admin-portal',
        component: AdminPortal,
    },
]
