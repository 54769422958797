import React, { useState, useEffect } from 'react'
import {
    Box,
    Typography,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
} from '@mui/material'
import {
    getAllMasterLogsheets,
    updateUserFieldOfWork,
    getUserFieldOfWork,
} from '../Logsheets/actions'
import { useToastContext } from '../../context/ToastContext'
import useLoader from '../../hooks/useLoader'

function FieldOfWorkSettings() {
    const [masterLogsheets, setMasterLogsheets] = useState([])
    const [selectedLogsheets, setSelectedLogsheets] = useState([])
    const toastContext = useToastContext()
    const [LoaderWrapper, setIsLoading] = useLoader()

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async () => {
        setIsLoading(true)
        try {
            const [masterLogsheetsResponse, userFieldOfWorkResponse] =
                await Promise.all([
                    getAllMasterLogsheets(),
                    getUserFieldOfWork(),
                ])
            setMasterLogsheets(masterLogsheetsResponse || [])
            setSelectedLogsheets(
                userFieldOfWorkResponse?.masterLogsheetIds || []
            )
        } catch (error) {
            toastContext.sendToast('Error fetching data', 'error')
        } finally {
            setIsLoading(false)
        }
    }

    const handleLogsheetChange = (logsheetId) => {
        setSelectedLogsheets((prev) => {
            if (prev.includes(logsheetId)) {
                return prev.filter((id) => id !== logsheetId)
            } else {
                return [...prev, logsheetId]
            }
        })
    }

    const handleSave = async () => {
        setIsLoading(true)
        try {
            await updateUserFieldOfWork(selectedLogsheets)
            toastContext.sendToast(
                'Field of work updated successfully',
                'success'
            )
        } catch (error) {
            toastContext.sendToast('Error updating field of work', 'error')
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Card>
            <CardHeader title="Field of Work Settings" />
            <Divider />
            <CardContent>
                <LoaderWrapper>
                    <>
                        {masterLogsheets.length > 0 && (
                            <Box>
                                <Typography mb={1}>
                                    Choose your fields of work:
                                </Typography>
                                <FormGroup>
                                    {masterLogsheets.map((logsheet) => (
                                        <FormControlLabel
                                            key={logsheet.id}
                                            control={
                                                <Checkbox
                                                    checked={selectedLogsheets.includes(
                                                        logsheet.id
                                                    )}
                                                    onChange={() =>
                                                        handleLogsheetChange(
                                                            logsheet.id
                                                        )
                                                    }
                                                />
                                            }
                                            label={logsheet.masterLogsheetTitle}
                                        />
                                    ))}
                                </FormGroup>
                            </Box>
                        )}
                        <Divider />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSave}
                            sx={{ mt: 2 }}
                        >
                            Save Changes
                        </Button>
                    </>
                </LoaderWrapper>
            </CardContent>
        </Card>
    )
}

export default FieldOfWorkSettings
