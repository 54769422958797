import React, { useState, useEffect } from 'react'

import { Html5Qrcode } from 'html5-qrcode'
import { useHistory } from 'react-router-dom'

import { Box, Button, Modal, Paper, Typography } from '@mui/material'

//Used to show a QRcode for a zone

const QRScanner = ({ onSuccess }) => {
    useEffect(() => {
        const html5QrCode = new Html5Qrcode(/* element id */ 'reader')
        const qrCodeSuccessCallback = (decodedText, decodedResult) => {
            onSuccess(decodedText)
            html5QrCode.stop()
        }
        const config = {
            fps: 10,
            qrbox: 250,
            // aspectRatio: '1:1',
        }

        // back camera
        html5QrCode.start(
            { facingMode: 'environment' },
            config,
            qrCodeSuccessCallback
        )
    }, [])

    return <div id="reader" height="600px" width="600px"></div>
}

const QRScannerModal = ({ displayQRScanner, onSetDisplayQRScanner }) => {
    const [location, setLocation] = useState('')
    const history = useHistory()

    useEffect(() => {
        if (location) {
            const locationStripRegexp = new RegExp('/zones/.')
            const [locationStrip, ...rest] = location.match(locationStripRegexp)
            history.push(locationStrip)
            onSetDisplayQRScanner(false)
        }
    }, [location])

    return (
        displayQRScanner && (
            <Modal
                open={displayQRScanner}
                onClose={() => onSetDisplayQRScanner(false)}
            >
                <Paper
                    style={{
                        position: 'absolute',
                        top: '35%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '650px',
                    }}
                >
                    <QRScanner
                        onSuccess={(result) => {
                            setLocation(result)
                        }}
                    />
                    <Box display={'flex'} m={2}>
                        <Button
                            onClick={() => onSetDisplayQRScanner(false)}
                            style={{
                                margin: 'auto',
                            }}
                            size="large"
                            variant="contained"
                        >
                            <Typography>Close</Typography>
                        </Button>
                    </Box>
                </Paper>
            </Modal>
        )
    )
}

export default QRScannerModal
