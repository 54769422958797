import React, { useState, useEffect } from 'react'
import {
    Box,
    Dialog,
    TextField,
    DialogActions,
    DialogContent,
    Button,
} from '@mui/material'
import useLoader from '../../../hooks/useLoader'
import { getLogsheetComments, createLogsheetComment } from '../../actions'
import { styles } from '../../../theme/styles'
import { ReactComponent as CreateIcon } from '../../../icons/buttons/create.svg'

const LogsheetComments = ({ match }) => {
    const masterLogsheetId = match.params.masterLogsheetId
    const logsheetId = match.params.logsheetId
    const [logsheetComments, setLogsheetComments] = useState([])
    const [
        displayCreateLogsheetCommentModal,
        setDisplayCreateLogsheetCommentModal,
    ] = useState(false)
    const [comment, setComment] = useState('')
    const [LoaderWrapper, setIsLoading] = useLoader()

    const asyncGetLogsheetComments = async () => {
        setIsLoading(true)

        const data = await getLogsheetComments(masterLogsheetId, logsheetId)
        setLogsheetComments(data)
        setIsLoading(false)
        return
    }

    useEffect(() => {
        asyncGetLogsheetComments()
    }, [logsheetId, masterLogsheetId])

    const handleClose = () => {
        setDisplayCreateLogsheetCommentModal(false)
    }

    const handleCreateComment = async () => {
        const tempComment = comment
        try {
            await createLogsheetComment(masterLogsheetId, logsheetId, comment)
            setComment(null)
            asyncGetLogsheetComments()
            setDisplayCreateLogsheetCommentModal(false)
        } catch {
            setComment(tempComment)
        }
    }

    return (
        <LoaderWrapper>
            <Box sx={styles.innerContainer}>
                <Dialog
                    open={displayCreateLogsheetCommentModal}
                    onClick={handleClose}
                >
                    <DialogContent
                        sx={{
                            width: '440px',
                        }}
                    >
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            name="name"
                            label="Logsheet comment"
                            type="text"
                            fullWidth
                            variant="standard"
                            onChange={(e) => setComment(e.target.value)}
                            value={comment}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                    e.preventDefault()
                                    handleCreateComment()
                                }
                            }}
                            onClick={(e) => e.stopPropagation()}
                        />
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={handleCreateComment}>Create</Button>
                    </DialogActions>
                </Dialog>

                <Box sx={styles.buttonList}>
                    <div
                        className="normalButton"
                        onClick={() => {
                            setDisplayCreateLogsheetCommentModal(true)
                        }}
                    >
                        <div className="buttonIconBlock">
                            <CreateIcon />
                        </div>
                        <div className="buttonTextBlock">
                            <div className="buttonTextBiggerBlock">
                                Create comment
                            </div>
                        </div>
                    </div>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignContent: 'center',
                        flexDirection: 'column',
                    }}
                    gap={2}
                >
                    {logsheetComments.length
                        ? logsheetComments
                              .sort(
                                  (a, b) =>
                                      new Date(b.createdAt) -
                                      new Date(a.createdAt)
                              )
                              .map((comment, id) => (
                                  <Box
                                      key={id}
                                      width="90%"
                                      padding={2}
                                      sx={{
                                          backgroundColor: 'white',
                                          borderRadius: 5,
                                      }}
                                  >
                                      <strong>{comment.userEmail}</strong>
                                      <br />
                                      {comment.comment}
                                  </Box>
                              ))
                        : 'No comments'}
                </Box>
            </Box>
        </LoaderWrapper>
    )
}

export default LogsheetComments
