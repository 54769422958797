import React, { useEffect, useRef } from 'react'
import {
    Box,
    TextField,
    Typography,
    FormControlLabel,
    Checkbox,
} from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import Plot from 'react-plotly.js'
import { useSortable } from '@dnd-kit/sortable'

function IndividualStationSignoff({
    index,
    stationTitle,
    stationDescription,
    target,
    rangeLow,
    rangeHigh,
    measurementUnit,
    broken,
    stationId,
    setSize,
    measurementOptions,
    stationMeasurements,
    mostRecentMeasurement,
    virtualizedListStyles,
}) {
    const isMultipleChoiceStation = measurementOptions.length > 0
    const rangeLowNumber = parseFloat(rangeLow)
    const rangeHighNumber = parseFloat(rangeHigh)

    const sizeRef = useRef(null)

    React.useEffect(() => {
        if (sizeRef.current)
            setSize(index, sizeRef.current.getBoundingClientRect().height)
    }, [])

    return (
        <Box
            px={3}
            mb={12}
            key={stationId}
            style={{ ...virtualizedListStyles }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 3,
                }}
            >
                <Box sx={{ width: '200px' }}>
                    <Typography variant="h5" fontWeight="bold" paragraph>
                        {stationTitle}
                    </Typography>
                    <Typography>{stationDescription}</Typography>
                </Box>

                <Box
                    sx={{
                        width: '300px',
                        borderRadius: '20px',
                        padding: '12px',
                        paddingBottom: '15px',
                    }}
                    ref={sizeRef}
                >
                    <Box
                        sx={{
                            paddingBottom: '15px',
                        }}
                    >
                        {target && (
                            <Typography>
                                Target: {target}
                                {measurementUnit}
                            </Typography>
                        )}
                        {rangeLow && rangeHigh ? (
                            <Typography>
                                Range: {rangeLowNumber}
                                {measurementUnit} - {rangeHighNumber}
                                {measurementUnit}
                            </Typography>
                        ) : (
                            <Typography>
                                {rangeLow || rangeHigh ? 'Range' : ''}
                                {!!rangeLowNumber ? `> ${rangeLowNumber}` : ''}
                                {!!rangeHighNumber
                                    ? `< ${rangeHighNumber}`
                                    : ''}
                                {!!rangeLowNumber || !!rangeHighNumber
                                    ? measurementUnit
                                    : ''}
                            </Typography>
                        )}
                    </Box>

                    {mostRecentMeasurement && (
                        <TextField
                            InputProps={{
                                readOnly: true,
                                endAdornment: (
                                    <InputAdornment position="start">
                                        {measurementUnit}
                                    </InputAdornment>
                                ),
                            }}
                            variant="standard"
                            style={{
                                width: '100%',
                            }}
                            helperText={
                                mostRecentMeasurement.createdAt
                                    ? `${new Date(
                                          mostRecentMeasurement.createdAt
                                      ).toLocaleString()}  ${
                                          mostRecentMeasurement.measurementValue <
                                          rangeLowNumber
                                              ? 'BELOW THE RANGE'
                                              : mostRecentMeasurement.measurementValue >
                                                rangeHighNumber
                                              ? 'ABOVE THE RANGE'
                                              : ''
                                      }`
                                    : 'no data'
                            }
                            error={
                                mostRecentMeasurement.measurementValue <
                                    rangeLowNumber ||
                                mostRecentMeasurement.measurementValue >
                                    rangeHighNumber
                            }
                            color={
                                mostRecentMeasurement.measurementValue <
                                    rangeLowNumber ||
                                mostRecentMeasurement.measurementValue >
                                    rangeHighNumber
                                    ? 'error'
                                    : 'success'
                            }
                            value={mostRecentMeasurement.measurementValue}
                        />
                    )}
                </Box>

                {isMultipleChoiceStation
                    ? stationMeasurements && (
                          <Plot
                              data={[
                                  {
                                      x: stationMeasurements
                                          .map(
                                              (measurement) =>
                                                  measurement.measurementValue
                                          )
                                          .reverse(),
                                      type: 'histogram',
                                      mode: 'lines+markers',
                                      marker: { color: 'green' },
                                  },
                              ]}
                              layout={{
                                  width: 400,
                                  height: 200,
                                  title: '',
                                  autosize: true,
                                  showlegend: false,
                                  hidesources: false,
                                  margin: {
                                      l: 30,
                                      r: 0,
                                      b: 30,
                                      t: 0,
                                      pad: 0,
                                  },
                              }}
                              config={{
                                  displayModeBar: false,
                                  staticPlot: true,
                                  fillFrame: true,
                                  responsive: true,
                              }}
                          />
                      )
                    : stationMeasurements && (
                          <Plot
                              data={[
                                  {
                                      x: stationMeasurements.map(
                                          (measurement) =>
                                              new Date(
                                                  measurement.createdAt
                                              ).toLocaleString()
                                      ),
                                      y: stationMeasurements
                                          .map(
                                              (measurement) =>
                                                  measurement.measurementValue
                                          )
                                          .reverse(),
                                      type: 'scatter',
                                      mode: 'lines+markers',
                                      marker: { color: 'green' },
                                  },
                              ]}
                              layout={{
                                  width: 400,
                                  height: 200,
                                  title: '',
                                  autosize: true,
                                  showlegend: false,
                                  hidesources: false,
                                  margin: {
                                      l: 30,
                                      r: 0,
                                      b: 30,
                                      t: 0,
                                      pad: 0,
                                  },
                                  xaxis: {
                                      autorange: true,
                                      type: 'category',
                                      visible: false,
                                  },
                              }}
                              config={{
                                  displayModeBar: false,
                                  staticPlot: true,
                                  fillFrame: true,
                                  responsive: true,
                              }}
                          />
                      )}
                <FormControlLabel
                    control={<Checkbox checked={broken} disabled />}
                    label="Instrument Broken"
                    labelPlacement="top"
                />
            </Box>
        </Box>
    )
}

IndividualStationSignoff.defaultProps = {
    stationTitle: '',
    id: '',
    stationDescription: '',
    target: '',
    rangeLow: '',
    rangeHigh: '',
    lastAccess: '2020-01-01T00:00:00.000Z',
}

export default IndividualStationSignoff
