import React from 'react'
import MasterLogsheetTable from './MasterLogsheetTable'

const MasterLogsheetTablePath = ({ match }) => {
    const masterLogsheetId = match.params.masterLogsheetId

    return <MasterLogsheetTable masterLogsheetId={masterLogsheetId} />
}

export default MasterLogsheetTablePath
