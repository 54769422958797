import React from 'react'
import Alert from '@mui/material/Alert'
import { makeStyles } from '@mui/styles'
import Fade from '@mui/material/Fade'

const useStyles = makeStyles(() => ({
    main: {
        position: 'fixed',
        top: '100px',
        right: '60px',
        maxHeight: '90vh',
        zIndex: 9999999999,
    },
    alertContainer: {
        marginBottom: '15px',
    },
}))

//Shows a toasts or a series of toasts on the screen.
//prop toast: An array of toast objects to be rendered
export default function Toast({ toast, removeToast }) {
    const styles = useStyles()

    return (
        <div className={styles.main}>
            {toast.map((t) => {
                const severity = t.content?.severity || 'success'

                return (
                    <div key={t.id} className={styles.alertContainer}>
                        <Fade in={!t.clear}>
                            <Alert
                                onClose={() => {
                                    removeToast(t.id)
                                }}
                                severity={severity}
                                sx={{
                                    width: '100%',
                                }}
                                variant="filled"
                            >
                                <span>{t.content.message}</span>
                            </Alert>
                        </Fade>
                    </div>
                )
            })}
        </div>
    )
}
