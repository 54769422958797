import React, { useState, useEffect } from 'react'
import { DataGrid, GridToolbar, getGridDateOperators } from '@mui/x-data-grid'
import { getMasterLogsheetMeasurements } from '../actions'

import { Paper } from '@mui/material'
import WarningIcon from '@mui/icons-material/Warning'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'

function MasterLogsheetTable({ masterLogsheetId }) {
    const [gridData, setGridData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const initialQueryOptions = {
        startDate: null,
        endDate: null,
    }
    const [queryOptions, setQueryOptions] = useState(initialQueryOptions)

    const getMeasurementRange = (measurement) => {
        if (!measurement.rangeLow && !measurement.rangeHigh) {
            return '-'
        }
        return `${measurement.rangeLow} - ${measurement.rangeHigh}`
    }

    const isValueOutOfRange = (measurement) => {
        const value = parseFloat(measurement.measurementValue)
        const rangeLow = parseFloat(measurement.rangeLow)
        const rangeHigh = parseFloat(measurement.rangeHigh)
        return value < rangeLow || value > rangeHigh
    }

    // to sort properly different types of data
    const measurementValueSortComparator = (a, b, order) => {
        const numericAValue = parseFloat(a)
        const numericBValue = parseFloat(b)

        if (!isNaN(numericAValue) && !isNaN(numericBValue)) {
            return order === 'asc'
                ? numericAValue - numericBValue
                : numericBValue - numericAValue
        }

        // If either value is not numeric, use string comparison
        return order === 'asc'
            ? String(a).localeCompare(b)
            : String(b).localeCompare(a)
    }

    // to see possible filtering operators for filtering dates and select only needed
    const dateOperators = getGridDateOperators()

    const columns = [
        {
            field: 'warningIcon',
            headerName: '',
            width: 60,
            renderCell: (params) => params.value || null,
            filterable: false,
        },
        {
            field: 'measurementValue',
            headerName: 'Measurement Value',
            flex: 1,
            sortComparator: measurementValueSortComparator,
            filterable: true,
            renderCell: (params) => {
                // Check if the warning icon is present for this row
                const isOutOfRange = params.row.warningIcon !== null
                return (
                    <div
                        style={{ color: isOutOfRange ? '#dc143c' : 'inherit' }}
                    >
                        {params.value}
                    </div>
                )
            },
        },
        {
            field: 'measurementRange',
            headerName: 'Measurement Range',
            width: 140,
            filterable: false,
            renderCell: (params) => {
                // Check if the warning icon is present for this row
                const isOutOfRange = params.row.warningIcon !== null
                return (
                    <div
                        style={{ color: isOutOfRange ? '#dc143c' : 'inherit' }}
                    >
                        {params.value}
                    </div>
                )
            },
        },
        {
            field: 'target',
            headerName: 'Target',
            width: 100,
            filterable: true,
        },
        {
            field: 'logsheetTitle',
            headerName: 'Logsheet Name',
            flex: 1,
            filterable: true,
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            type: 'date',
            width: 155,
            filterable: true,
            filterOperators: dateOperators.filter(
                (operator) =>
                    operator.value === 'is' ||
                    operator.value === 'after' ||
                    operator.value === 'onOrAfter'
            ),
            renderCell: (params) => {
                if (
                    params.value instanceof Date &&
                    !isNaN(params.value.getTime())
                ) {
                    return params.value.toLocaleString()
                }
                return 'N/A'
            },
        },

        {
            field: 'userId',
            headerName: 'Created By',
            flex: 1,
            filterable: true,
        },
        //   { field: 'updatedAt', headerName: 'Updated At', type: 'date', flex: 1, filterable: false, },
        {
            field: 'stationTitle',
            headerName: 'Station',
            flex: 1,
            filterable: true,
        },
        {
            field: 'isSigned',
            headerName: 'Is Signed',
            width: 80,
            filterable: false,
            renderCell: (params) =>
                params.value ? (
                    <CheckIcon color="success" />
                ) : (
                    <ClearIcon color="error" />
                ),
        },
        {
            field: 'signedWhen',
            headerName: 'Signed on',
            width: 155,
            filterable: false,
            type: 'custom', // Use a custom type
            renderCell: (params) => {
                const dateValue = new Date(params.value)
                return isNaN(dateValue) ? '-' : dateValue.toLocaleString()
            },
        },

        //   { field: 'signedBy', headerName: 'Signed by', flex: 1, filterable: false, },
    ]

    const rows = gridData?.map((measurement, index) => {
        return {
            id: index,
            warningIcon: isValueOutOfRange(measurement) ? (
                <WarningIcon color="warning" />
            ) : null,
            measurementValue: measurement.measurementValue,
            measurementRange: getMeasurementRange(measurement),
            target: measurement.target,
            logsheetTitle: measurement.logsheetTitle,
            createdAt: new Date(measurement.createdAt),
            userId: measurement.userEmail,
            updatedAt: measurement.updatedAt
                ? new Date(measurement.updatedAt)
                : new Date(measurement.createdAt),
            stationTitle: measurement.stationTitle,
            isSigned: measurement.isSigned,
            signedWhen:
                measurement.signedWhen !== null
                    ? new Date(measurement.signedWhen)
                    : '-',
            signedBy: measurement.signedBy,
        }
    })

    const onFilterChange = (filterModel) => {
        if (
            !Array.isArray(filterModel.items) ||
            filterModel.items.length === 0
        ) {
            return // Exit if there are no filter items or if it's not an array
        }
        if (filterModel?.items?.length > 0) {
            const operator = filterModel.items[0].operator
            const field = filterModel.items[0].field
            const value = filterModel.items[0].value

            let now = new Date()

            if (value !== undefined && field === 'createdAt') {
                let startDate = new Date(value)
                let endDate = new Date(value)

                if (operator === 'after') {
                    startDate.setDate(startDate.getDate() + 1) // Move to the next day
                    startDate.setHours(0, 0, 0, 0)
                    endDate = now
                } else if (operator === 'is') {
                    startDate.setHours(0, 0, 0, 0)
                    endDate.setHours(23, 59, 59, 999)
                } else if (operator === 'onOrAfter') {
                    endDate = now
                    startDate.setHours(0, 0, 0, 0)
                }
                setQueryOptions({ startDate, endDate })
            }
        }
    }

    // function to get data fron the server
    const asyncGetMasterLogsheetTable = async (queryOptions) => {
        setIsLoading(true)
        const [masterLogsheetMeasurements] = await Promise.all([
            getMasterLogsheetMeasurements(
                masterLogsheetId,
                queryOptions.startDate,
                queryOptions.endDate
            ),
        ])
        setGridData(masterLogsheetMeasurements)
        setIsLoading(false)
    }

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)
            try {
                const response = await asyncGetMasterLogsheetTable(queryOptions)
                if (response !== undefined) {
                    setGridData(response)
                }
            } catch (error) {
                setGridData([])
            } finally {
                setIsLoading(false)
            }
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryOptions])

    return (
        <div style={{ width: '100%', overflowX: 'auto' }}>
            <div
                style={{
                    textAlign: 'center',
                    marginBottom: '10px',
                    padding: '10px',
                    backgroundColor: '#fff',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.03)',
                    color: '#555',
                    fontSize: '14px',
                    border: '1px solid #e0e0e0',
                }}
            >
                {queryOptions.startDate === null &&
                queryOptions.endDate === null ? (
                    <>
                        Data shown for: <strong> last 100 measurements </strong>
                        (use filters to modify)
                    </>
                ) : (
                    <>
                        {queryOptions.startDate.toDateString() ===
                        queryOptions.endDate.toDateString() ? (
                            <>
                                Data shown for date:{' '}
                                <strong>
                                    {queryOptions.startDate.toLocaleDateString()}
                                </strong>
                            </>
                        ) : (
                            <>
                                Data shown for date range:{' '}
                                <strong>
                                    {queryOptions.startDate.toLocaleDateString()}{' '}
                                    -{' '}
                                    {queryOptions.endDate.toLocaleDateString()}
                                </strong>
                            </>
                        )}{' '}
                        (use filters to modify)
                    </>
                )}
            </div>

            <Paper
                elevation={3}
                style={{ height: 650, padding: '10px', overflowX: 'auto' }}
            >
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    headerHeight={100}
                    sx={{
                        '& .MuiDataGrid-columnHeaderTitle': {
                            textOverflow: 'clip',
                            whiteSpace: 'break-spaces',
                            lineHeight: 1.4,
                            color: '#555',
                        }, // for table header titles to be on 2 lines
                    }}
                    rowsPerPageOptions={[10, 25, 50]}
                    slots={{
                        toolbar: GridToolbar,
                    }}
                    //  filterMode="server" // should be here for sever filtering by removing it allows client filtering to work too
                    onFilterModelChange={onFilterChange}
                    loading={isLoading}
                />
            </Paper>
        </div>
    )
}

export default MasterLogsheetTable
