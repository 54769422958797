import React, { useState, useEffect } from 'react'
import { useShowContentBasedOnRoles } from '../../../hooks/useShowContentBasedOnRoles'
import { Box, Button, Divider, Paper, Typography } from '@mui/material'

import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined' // create
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined' // export
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined' // update
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined' // delete
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined' // sign off
import FormatListNumberedRtlOutlinedIcon from '@mui/icons-material/FormatListNumberedRtlOutlined' // table

import ContentPasteIcon from '@mui/icons-material/ContentPaste'
import { Link as RouterLink } from 'react-router-dom'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import CreateLogsheet from '../CreateOrUpdateLogsheet'
import UpdateMasterLogsheet from '../CreateOrUpdateMasterLogsheet'
import DeleteMasterLogsheet from '../DeleteMasterLogsheet'
import { downloadReport } from '../../Zones/actions'
import {
    updateLogsheetOrdering,
    downloadMasterLogsheetReport,
} from '../actions'
import { downloadExcelFiles } from '../../../utils/excel'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core'
import {
    arrayMove,
    SortableContext,
    rectSortingStrategy,
    sortableKeyboardCoordinates,
} from '@dnd-kit/sortable'
import { styles } from '../../../theme/styles'
import { ScreenScrollerButtons } from '../../../components/ScreenScrollerButtons'

import { ReactComponent as LogsheetMaster } from '../../../icons/logsheets/logsheetMaster.svg'
import { ReactComponent as LogsheetIcon } from '../../../icons/logsheets/Logsheet.svg'
import { ReactComponent as LogsheetIcon1AMLogsheet } from '../../../icons/logsheets/1AMLogsheet.svg'
import { ReactComponent as LogsheetIcon2AMLogsheet } from '../../../icons/logsheets/2AMLogsheet.svg'
import { ReactComponent as LogsheetIcon3AMLogsheet } from '../../../icons/logsheets/3AMLogsheet.svg'
import { ReactComponent as LogsheetIcon4AMLogsheet } from '../../../icons/logsheets/4AMLogsheet.svg'
import { ReactComponent as LogsheetIcon5AMLogsheet } from '../../../icons/logsheets/5AMLogsheet.svg'
import { ReactComponent as LogsheetIcon6AMLogsheet } from '../../../icons/logsheets/6AMLogsheet.svg'
import { ReactComponent as LogsheetIcon7AMLogsheet } from '../../../icons/logsheets/7AMLogsheet.svg'
import { ReactComponent as LogsheetIcon8AMLogsheet } from '../../../icons/logsheets/8AMLogsheet.svg'
import { ReactComponent as LogsheetIcon9AMLogsheet } from '../../../icons/logsheets/9AMLogsheet.svg'
import { ReactComponent as LogsheetIcon10AMLogsheet } from '../../../icons/logsheets/10AMLogsheet.svg'
import { ReactComponent as LogsheetIcon11AMLogsheet } from '../../../icons/logsheets/11AMLogsheet.svg'
import { ReactComponent as LogsheetIcon12AMLogsheet } from '../../../icons/logsheets/12AMLogsheet.svg'
import { ReactComponent as LogsheetIcon1PMLogsheet } from '../../../icons/logsheets/1PMLogsheet.svg'
import { ReactComponent as LogsheetIcon2PMLogsheet } from '../../../icons/logsheets/2PMLogsheet.svg'
import { ReactComponent as LogsheetIcon3PMLogsheet } from '../../../icons/logsheets/3PMLogsheet.svg'
import { ReactComponent as LogsheetIcon4PMLogsheet } from '../../../icons/logsheets/4PMLogsheet.svg'
import { ReactComponent as LogsheetIcon5PMLogsheet } from '../../../icons/logsheets/5PMLogsheet.svg'
import { ReactComponent as LogsheetIcon6PMLogsheet } from '../../../icons/logsheets/6PMLogsheet.svg'
import { ReactComponent as LogsheetIcon7PMLogsheet } from '../../../icons/logsheets/7PMLogsheet.svg'
import { ReactComponent as LogsheetIcon8PMLogsheet } from '../../../icons/logsheets/8PMLogsheet.svg'
import { ReactComponent as LogsheetIcon9PMLogsheet } from '../../../icons/logsheets/9PMLogsheet.svg'
import { ReactComponent as LogsheetIcon10PMLogsheet } from '../../../icons/logsheets/10PMLogsheet.svg'
import { ReactComponent as LogsheetIcon11PMLogsheet } from '../../../icons/logsheets/11PMLogsheet.svg'
import { ReactComponent as LogsheetIcon12PMLogsheet } from '../../../icons/logsheets/12PMLogsheet.svg'

import { ReactComponent as DownloadIcon } from '../../../icons/buttons/download.svg'
import { ReactComponent as EditIcon } from '../../../icons/buttons/edit.svg'
import { ReactComponent as DeleteIcon } from '../../../icons/buttons/delete.svg'
import { ReactComponent as CreateIcon } from '../../../icons/buttons/create.svg'
import { ReactComponent as Edit2Icon } from '../../../icons/buttons/edit2.svg'
import { ReactComponent as TableIcon } from '../../../icons/buttons/table.svg'
import { ReactComponent as ReorderIcon } from '../../../icons/buttons/order.svg'
import { ReactComponent as SaveIcon } from '../../../icons/buttons/save.svg'
import { ReactComponent as XIcon } from '../../../icons/buttons/x.svg'

import { useHistory } from 'react-router-dom'

//An indavidual logsheet JSx element that is rendered by logsheets
export const Logsheet = ({
    logsheetTitle,
    lastAccess,
    isReorderingLogsheets,
    id,
    masterLogsheetId,
    logsheets,
    ...rest
}) => {
    const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({
            id,
            disabled: isReorderingLogsheets ? false : true,
        })

    const style = {
        width: '100%',
        height: '100%',
        transform: CSS.Transform.toString(transform),
        transition,
    }

    const isMastLog = logsheets.find(
        (sheet) => sheet.id === id
    ).includesAllStations
    // find the original master logsheet (it is the one that includes all stations)

    // Valid titles that will make a color appear (not black):
    // Must include a number 1-12, a space (optional), and either am or pm (not case sensitive). In this order

    // const getColor = () => {
    //     let num = Math.abs(id)
    //     let lastDigit = num % 10

    //     if (isMastLog) {
    //         return '#000000'
    //     }

    //     if (lastDigit === 0) {
    //         return '#B71C1C'
    //     } else if (lastDigit === 1) {
    //         return '#006064'
    //     } else if (lastDigit === 2) {
    //         return '#0D47A1'
    //     } else if (lastDigit === 3) {
    //         return '#880E4F'
    //     } else if (lastDigit === 4) {
    //         return '#004D40'
    //     } else if (lastDigit === 5) {
    //         return '#FF6F00'
    //     } else if (lastDigit === 6) {
    //         return '#827717'
    //     } else if (lastDigit === 7) {
    //         return '#33691E'
    //     } else if (lastDigit === 8) {
    //         return '#BF360C'
    //     } else if (lastDigit === 9) {
    //         return '#311B92'
    //     }
    //     // else if (lastDigit === 10) {
    //     //     return '#4A148C'
    //     // }
    //     // else if (lastDigit === 11) {
    //     //     return '#01579B'
    //     // }
    //     // else if (lastDigit === 12) {
    //     //     return '#880E4F'
    //     // }
    //     else {
    //         return '#000000'
    //     }
    // }
    const iconStyles = {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fill: 'initial',
    }
    const getIcon = (name) => {
        if (
            name.toLowerCase().includes('11 am') ||
            name.toLowerCase().includes('11am')
        ) {
            return <LogsheetIcon11AMLogsheet style={iconStyles} />
        } else if (
            name.toLowerCase().includes('12 am') ||
            name.toLowerCase().includes('12am')
        ) {
            return <LogsheetIcon12AMLogsheet style={iconStyles} />
        } else if (
            name.toLowerCase().includes('3 am') ||
            name.toLowerCase().includes('3am')
        ) {
            return <LogsheetIcon3AMLogsheet style={iconStyles} />
        } else if (
            name.toLowerCase().includes('4 am') ||
            name.toLowerCase().includes('4am')
        ) {
            return <LogsheetIcon4AMLogsheet style={iconStyles} />
        } else if (
            name.toLowerCase().includes('5 am') ||
            name.toLowerCase().includes('5am')
        ) {
            return <LogsheetIcon5AMLogsheet style={iconStyles} />
        } else if (
            name.toLowerCase().includes('6 am') ||
            name.toLowerCase().includes('6am')
        ) {
            return <LogsheetIcon6AMLogsheet style={iconStyles} />
        } else if (
            name.toLowerCase().includes('7 am') ||
            name.toLowerCase().includes('7am')
        ) {
            return <LogsheetIcon7AMLogsheet style={iconStyles} />
        } else if (
            name.toLowerCase().includes('8 am') ||
            name.toLowerCase().includes('8am')
        ) {
            return <LogsheetIcon8AMLogsheet style={iconStyles} />
        } else if (
            name.toLowerCase().includes('9 am') ||
            name.toLowerCase().includes('9am')
        ) {
            return <LogsheetIcon9AMLogsheet style={iconStyles} />
        } else if (
            name.toLowerCase().includes('10 am') ||
            name.toLowerCase().includes('10am')
        ) {
            return <LogsheetIcon10AMLogsheet style={iconStyles} />
        } else if (
            name.toLowerCase().includes('1 am') ||
            name.toLowerCase().includes('1am')
        ) {
            return <LogsheetIcon1AMLogsheet style={iconStyles} />
        } else if (
            name.toLowerCase().includes('2 am') ||
            name.toLowerCase().includes('2am')
        ) {
            return <LogsheetIcon2AMLogsheet style={iconStyles} />
        } else if (
            name.toLowerCase().includes('11 pm') ||
            name.toLowerCase().includes('11pm')
        ) {
            return <LogsheetIcon11PMLogsheet style={iconStyles} />
        } else if (
            name.toLowerCase().includes('12 pm') ||
            name.toLowerCase().includes('12pm')
        ) {
            return <LogsheetIcon12PMLogsheet style={iconStyles} />
        } else if (
            name.toLowerCase().includes('3 pm') ||
            name.toLowerCase().includes('3pm')
        ) {
            return <LogsheetIcon3PMLogsheet style={iconStyles} />
        } else if (
            name.toLowerCase().includes('4 pm') ||
            name.toLowerCase().includes('4pm')
        ) {
            return <LogsheetIcon4PMLogsheet style={iconStyles} />
        } else if (
            name.toLowerCase().includes('5 pm') ||
            name.toLowerCase().includes('5pm')
        ) {
            return <LogsheetIcon5PMLogsheet style={iconStyles} />
        } else if (
            name.toLowerCase().includes('6 pm') ||
            name.toLowerCase().includes('6pm')
        ) {
            return <LogsheetIcon6PMLogsheet style={iconStyles} />
        } else if (
            name.toLowerCase().includes('7 pm') ||
            name.toLowerCase().includes('7pm')
        ) {
            return <LogsheetIcon7PMLogsheet style={iconStyles} />
        } else if (
            name.toLowerCase().includes('8 pm') ||
            name.toLowerCase().includes('8pm')
        ) {
            return <LogsheetIcon8PMLogsheet style={iconStyles} />
        } else if (
            name.toLowerCase().includes('9 pm') ||
            name.toLowerCase().includes('9pm')
        ) {
            return <LogsheetIcon9PMLogsheet style={iconStyles} />
        } else if (
            name.toLowerCase().includes('10 pm') ||
            name.toLowerCase().includes('10pm')
        ) {
            return <LogsheetIcon10PMLogsheet style={iconStyles} />
        } else if (
            name.toLowerCase().includes('1 pm') ||
            name.toLowerCase().includes('1pm')
        ) {
            return <LogsheetIcon1PMLogsheet style={iconStyles} />
        } else if (
            name.toLowerCase().includes('2 pm') ||
            name.toLowerCase().includes('2pm')
        ) {
            return <LogsheetIcon2PMLogsheet style={iconStyles} />
        } else if (isMastLog) {
            return <LogsheetMaster style={iconStyles} />
        } else {
            return <LogsheetIcon style={iconStyles} />
        }
    }

    //  const cardImageContainerLogsheet = {
    //      width: '325px',
    //      height: '200px',
    //      position: 'relative',
    //  }

    //  const iconMasterStyle = {
    //      width: '100%',
    //      height: '100%',
    //      display: 'flex',
    //      justifyContent: 'center',
    //      alignItems: 'center',
    //      position: 'absolute',
    //      // color: getColor(),
    //  }

    //  const textMasterStyle = {
    //      width: '100%',
    //      height: '100%',
    //      fontSize: 28,
    //      color: 'black',
    //      fontWeight: 'bold',
    //      display: 'flex',
    //      justifyContent: 'center',
    //      alignItems: 'center',
    //  }

    return (
        <Paper
            elevation={0}
            key={id}
            ref={setNodeRef}
            style={{
                ...styles.paper,
                transform: CSS.Transform.toString(transform),
                transition,
            }}
            {...attributes}
            {...listeners}
        >
            <Box sx={styles.cardImageSmallContainer}>
                {/* <MasterLogsheetIcon style={iconStyles} /> */}
                {getIcon(logsheetTitle)}
            </Box>
            <Box sx={styles.cardTextContainer}>
                <div style={styles.cardTextBig}>{logsheetTitle}</div>
                <div style={styles.cardTextSmallerBlock}>
                    <div style={styles.cardTextSmall}>Last Acccessed at: </div>
                    <div style={styles.cardTextSmallBold}>
                        {new Date(lastAccess).toLocaleString()}
                    </div>
                </div>
                {isReorderingLogsheets && <DragIndicatorIcon />}
            </Box>
        </Paper>
    )
}

//Renders all the logsheets inside of the logsheet and allows reordering
//If the  user is an admin buttons to alter the master logsheet are shown
function Logsheets({
    masterLogsheetId,
    masterLogsheetTitle,
    logsheets,
    ordering,
    updateOrdering,
    ...props
}) {
    const history = useHistory()
    const ShowContentBasedOnRolesWrapper = useShowContentBasedOnRoles()
    const [displayCreateLogsheet, setDisplayCreateLogsheet] = useState(false)
    const [displayUpdateMasterLogsheet, setDisplayUpdateMasterLogsheet] =
        useState(false)
    const [displayDeleteMasterLogsheet, setDisplayDeleteMasterLogsheet] =
        useState(false)
    const [hoveredLogsheet, setHoveredLogsheet] = useState('')
    const [initialLogsheets, setInitialLogsheets] = useState([])
    const [reorderableLogsheets, setReorderableLogsheets] = useState([])
    const [isReorderingLogsheets, setIsReorderingLogsheets] = useState(false)
    const [
        isDownloadingMasterLogsheetReport,
        setInitiateMasterLogsheetReportDownload,
    ] = useState(false)

    useEffect(() => {
        async function asyncDownloadMasterLogsheetReport() {
            if (isDownloadingMasterLogsheetReport) {
                setInitiateMasterLogsheetReportDownload(false)
                const reportContents = await downloadReport(masterLogsheetId)
                downloadExcelFiles(reportContents)
            }
        }

        asyncDownloadMasterLogsheetReport()
    }, [isDownloadingMasterLogsheetReport])

    useEffect(() => {
        const sortedInitialLogsheets = logsheets && [
            ...logsheets.sort((a, b) => {
                return ordering?.[a.id] - ordering?.[b.id]
            }),
        ]
        setInitialLogsheets(sortedInitialLogsheets)
        setReorderableLogsheets(sortedInitialLogsheets)
    }, [logsheets, ordering])

    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(TouchSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    )

    const handleDragEnd = (event) => {
        const { active, over } = event

        if (active.id !== over.id) {
            setReorderableLogsheets((items) => {
                const activeIndex = items.findIndex((el) => el.id === active.id)
                const overIndex = items.findIndex((el) => el.id === over.id)

                return arrayMove(items, activeIndex, overIndex)
            })
        }
    }

    const handleSubmitReorder = async () => {
        const orderingJSON = {}
        for (const [index, zone] of reorderableLogsheets.entries()) {
            orderingJSON[zone.id] = index + 1
        }

        await updateLogsheetOrdering({ ...ordering, ...orderingJSON }).then(
            () => updateOrdering(orderingJSON)
        )
    }

    return (
        <Box sx={styles.innerContainer}>
            {/* Following 3 logsheets can only be seen by admins */}
            {displayCreateLogsheet && (
                <CreateLogsheet
                    setDisplayModal={setDisplayCreateLogsheet}
                    onSuccess={props.asyncFetchLogsheets}
                    masterLogsheetId={masterLogsheetId}
                    operation="Create"
                />
            )}

            {displayUpdateMasterLogsheet && (
                <UpdateMasterLogsheet
                    setDisplayModal={setDisplayUpdateMasterLogsheet}
                    onSuccess={props.asyncFetchLogsheets}
                    masterLogsheetId={masterLogsheetId}
                    masterLogsheetTitle={masterLogsheetTitle}
                    operation="Update"
                />
            )}

            {displayDeleteMasterLogsheet && (
                <DeleteMasterLogsheet
                    masterLogsheetId={masterLogsheetId}
                    setDisplayModal={setDisplayDeleteMasterLogsheet}
                />
            )}

            <Box sx={styles.buttonList}>
                <ShowContentBasedOnRolesWrapper
                    roles={[
                        'admin',
                        'organization_admin',
                        'organization_superuser',
                    ]}
                >
                    <div
                        className="normalButton"
                        onClick={() => setDisplayCreateLogsheet(true)}
                    >
                        <div className="buttonIconBlock">
                            <CreateIcon />
                        </div>
                        <div className="buttonTextBlock">
                            <div className="buttonTextBiggerBlock">Create</div>
                            <div className="buttonTextSmallerBlock">
                                logsheet
                            </div>
                        </div>
                    </div>
                </ShowContentBasedOnRolesWrapper>

                <ShowContentBasedOnRolesWrapper
                    roles={[
                        'admin',
                        'organization_admin',
                        'organization_superuser',
                    ]}
                >
                    <div
                        className="normalButton"
                        onClick={() => setDisplayUpdateMasterLogsheet(true)}
                    >
                        <div className="buttonIconBlock">
                            <EditIcon />
                        </div>
                        <div className="buttonTextBlock">
                            <div className="buttonTextBiggerBlock">Update</div>
                            <div className="buttonTextSmallerBlock">
                                master logsheet
                            </div>
                        </div>
                    </div>
                </ShowContentBasedOnRolesWrapper>

                <ShowContentBasedOnRolesWrapper
                    roles={[
                        'admin',
                        'organization_admin',
                        'organization_superuser',
                    ]}
                >
                    <div
                        className="normalButton"
                        onClick={() => setDisplayDeleteMasterLogsheet(true)}
                    >
                        <div className="buttonIconBlock">
                            <DeleteIcon />
                        </div>
                        <div className="buttonTextBlock">
                            <div className="buttonTextBiggerBlock">Delete</div>
                            <div className="buttonTextSmallerBlock">
                                master logsheet
                            </div>
                        </div>
                    </div>
                </ShowContentBasedOnRolesWrapper>
                <div
                    className="normalButton"
                    onClick={() =>
                        setInitiateMasterLogsheetReportDownload(true)
                    }
                >
                    <div className="buttonIconBlock">
                        <DownloadIcon />
                    </div>
                    <div className="buttonTextBlock">
                        <div className="buttonTextBiggerBlock">Export</div>
                        <div className="buttonTextSmallerBlock">
                            master logsheet report
                        </div>
                    </div>
                </div>

                <ShowContentBasedOnRolesWrapper
                    roles={[
                        'admin',
                        'organization_admin',
                        'organization_superuser',
                    ]}
                >
                    <div
                        className="normalButton"
                        onClick={() =>
                            history.push(
                                `/logsheets/${masterLogsheetId}/signoffs`
                            )
                        }
                    >
                        <div className="buttonIconBlock">
                            <Edit2Icon />
                        </div>
                        <div className="buttonTextBlock">
                            <div className="buttonTextBiggerBlock">
                                Supervisor
                            </div>
                            <div className="buttonTextSmallerBlock">
                                sign-off
                            </div>
                        </div>
                    </div>
                </ShowContentBasedOnRolesWrapper>

                <ShowContentBasedOnRolesWrapper roles={['admin']}>
                    <div
                        className="normalButton"
                        onClick={() =>
                            history.push(`/logsheets/${masterLogsheetId}/table`)
                        }
                    >
                        <div className="buttonIconBlock">
                            <TableIcon />
                        </div>
                        <div className="buttonTextBlock">
                            <div className="buttonTextBiggerBlock">Table</div>
                        </div>
                    </div>
                </ShowContentBasedOnRolesWrapper>

                <div
                    className="normalButton"
                    onClick={() =>
                        history.push(
                            `/logsheets/${masterLogsheetId}/organizing-table`
                        )
                    }
                >
                    <div className="buttonIconBlock">
                        <TableIcon />
                    </div>
                    <div className="buttonTextBlock">
                        <div className="buttonTextBiggerBlock">
                            Organizing Table
                        </div>
                    </div>
                </div>

                <Paper
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        padding: '7px',
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                        gap: 3,
                        zIndex: 1299,
                        backgroundColor: 'rgba(255, 255, 255, 0.9)',
                        boxShadow: 'none',
                    }}
                >
                    {isReorderingLogsheets ? (
                        // Renders this while logsheets are being moved around
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 2,
                            }}
                        >
                            <div
                                className="normalButton normalButtonSmaller"
                                onClick={() => {
                                    handleSubmitReorder()
                                    setIsReorderingLogsheets(false)
                                }}
                            >
                                <div className="buttonIconBlock">
                                    <SaveIcon />
                                </div>
                                <div className="buttonTextBlock">
                                    <div className="buttonTextBiggerBlock">
                                        Save
                                    </div>
                                </div>
                            </div>

                            <div
                                className="normalButton normalButtonSmaller"
                                onClick={() => {
                                    setIsReorderingLogsheets(false)
                                    setReorderableLogsheets(initialLogsheets)
                                }}
                            >
                                <div className="buttonIconBlock">
                                    <XIcon />
                                </div>
                                <div className="buttonTextBlock">
                                    <div className="buttonTextBiggerBlock">
                                        Cancel
                                    </div>
                                </div>
                            </div>

                            {/* <ScreenScrollerButtons /> */}
                        </Box>
                    ) : (
                        Array.isArray(reorderableLogsheets) &&
                        reorderableLogsheets.length > 1 && (
                            <div
                                className="normalButton normalButtonSmaller "
                                onClick={() => setIsReorderingLogsheets(true)}
                            >
                                <div className="buttonIconBlock">
                                    <ReorderIcon />
                                </div>
                                <div className="buttonTextBlock">
                                    <div className="buttonTextBiggerBlock">
                                        Reorder
                                    </div>

                                    <div className="buttonTextSmallerBlock">
                                        logsheets
                                    </div>
                                </div>
                            </div>
                        )
                    )}
                </Paper>
            </Box>

            <div className="titleBig">{masterLogsheetTitle}</div>

            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
            >
                <Box sx={styles.cardContainer}>
                    <SortableContext
                        items={reorderableLogsheets}
                        strategy={rectSortingStrategy}
                    >
                        {Array.isArray(reorderableLogsheets) &&
                            reorderableLogsheets?.map((logsheet) => (
                                <Box
                                    key={logsheet.id}
                                    component={
                                        !isReorderingLogsheets ? RouterLink : ''
                                    }
                                    to={`/logsheets/${masterLogsheetId}/logsheet/${logsheet.id}`}
                                    style={{ textDecoration: 'none' }}
                                    sx={{
                                        ':hover': {
                                            boxShadow:
                                                '0px 10px 15px rgba(0, 0, 0, 0.2)',
                                        },
                                    }}
                                >
                                    <Logsheet
                                        {...logsheet}
                                        isReorderingLogsheets={
                                            isReorderingLogsheets
                                        }
                                        id={logsheet.id}
                                        masterLogsheetId={masterLogsheetId}
                                        logsheets={logsheets}
                                        lastAccess={
                                            logsheet.lastAccessedAt
                                                ? logsheet.lastAccessedAt
                                                : logsheet.updatedAt
                                        }
                                    />
                                </Box>
                            ))}
                    </SortableContext>
                </Box>
            </DndContext>
        </Box>
    )
}

export default Logsheets
