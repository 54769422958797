import React, { useEffect, useState } from 'react'
import {
    updateDashboardOrdering,
    createDashboard,
    asyncDeleteStationsFromDashboard,
    changeDashboardName,
    deleteDashboard,
} from './actions'
import EditIcon from '@mui/icons-material/Edit'
import CloseIcon from '@mui/icons-material/Close'
import PropTypes from 'prop-types'
import {
    Box,
    Button,
    Divider,
    TextField,
    Paper,
    Typography,
    Chip,
} from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import AddIcon from '@mui/icons-material/Add'
import { styles } from '../../theme/styles'
import CreateOrUpdateDashboardItem from '../Dashboard/CreateOrUpdateDashboardItem'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Gauge, Histogram, Line } from './Chart'
import { MultChoicePie } from './Chart/PieChart'
import { useToastContext } from '../../context/ToastContext'
import { pink } from '@mui/material/colors'
import Checkbox from '@mui/material/Checkbox'
import DeleteDashboard from './DeleteDashboard'
import { useShowContentBasedOnRoles } from '../../hooks/useShowContentBasedOnRoles'

import { ReactComponent as ReorderIcon } from '../../icons/buttons/order.svg'
import { ReactComponent as CreateIcon } from '../../icons/buttons/create.svg'
import { ReactComponent as DeleteIcon } from '../../icons/buttons/delete.svg'
import { ReactComponent as XIcon } from '../../icons/buttons/x.svg'
import { ReactComponent as SaveIcon } from '../../icons/buttons/save.svg'

import FunctionsOutlinedIcon from '@mui/icons-material/FunctionsOutlined'
import FlipToFrontOutlinedIcon from '@mui/icons-material/FlipToFrontOutlined'

import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core'
import {
    arrayMove,
    SortableContext,
    rectSortingStrategy,
    sortableKeyboardCoordinates,
} from '@dnd-kit/sortable'
import { ScreenScrollerButtons } from '../../components/ScreenScrollerButtons'

export const DashBoardItem = ({
    zoneTitle,
    lastAccess,
    signedUrl,
    isReorderingDashboardItems,
    asyncReloadZone,
    imageS3Key,
    organizationIds,
    zoneId,
    displayCopyDashboardItem,
    setDisplayCopyDashboardItem,
    dashboardItem,
    stations,
    userDashboards,
    showSigmaAvg,
    ...rest
}) => {
    const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({
            id: rest.id,
            disabled: isReorderingDashboardItems ? false : true,
        })

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    }

    return (
        <Paper
            key={rest.id}
            ref={setNodeRef}
            // style={{
            //     ...styles.paper,
            //     transform: CSS.Transform.toString(transform),
            //     transition: transition, // assuming transition is a valid CSS transition property value
            // }}
            style={style}
            {...attributes}
            {...listeners}
        >
            {dashboardItem.chartType === 'Gauge' &&
                dashboardItem.station?.measurementOptions?.length === 0 && (
                    <Gauge
                        title={dashboardItem.station.stationTitle}
                        units={dashboardItem.station.measurementUnit}
                        min={dashboardItem.station.rangeLow}
                        max={dashboardItem.station.rangeHigh}
                        target={dashboardItem.station.target}
                        values={dashboardItem.station?.stationMeasurements}
                        stddevLower={dashboardItem.station?.stddevLower}
                        stddevUpper={dashboardItem.station?.stddevUpper}
                        mean={dashboardItem.station?.mean}
                        showSigmaAvg={showSigmaAvg}
                    />
                )}
            {dashboardItem.chartType === 'Gauge' &&
                dashboardItem.station?.measurementOptions?.length > 0 && (
                    <MultChoicePie
                        value={
                            dashboardItem.station?.stationMeasurements[0]
                                ?.measurementValue
                        }
                        measurementOptions={
                            dashboardItem.station?.measurementOptions
                        }
                        title={dashboardItem.station.stationTitle}
                        units={dashboardItem.station.measurementUnit}
                        min={dashboardItem.station.rangeLow}
                        max={dashboardItem.station.rangeHigh}
                        target={dashboardItem.station.target}
                    />
                )}
            {dashboardItem.chartType === 'Histogram' && (
                <Histogram
                    title={dashboardItem.station.stationTitle}
                    min={dashboardItem.station.rangeLow}
                    max={dashboardItem.station.rangeHigh}
                    target={dashboardItem.station.target}
                    values={dashboardItem.station?.stationMeasurements}
                    measurementOptions={
                        dashboardItem.station?.measurementOptions
                    }
                    stddevLower={dashboardItem.station?.stddevLower}
                    stddevUpper={dashboardItem.station?.stddevUpper}
                    mean={dashboardItem.station?.mean}
                    showSigmaAvg={showSigmaAvg}
                />
            )}
            {dashboardItem.chartType === 'Line' && (
                <div>
                    <Line
                        title={dashboardItem.station.stationTitle}
                        values={dashboardItem.station?.stationMeasurements}
                        min={dashboardItem.station.rangeLow}
                        max={dashboardItem.station.rangeHigh}
                        target={dashboardItem.station.target}
                        measurementOptions={
                            dashboardItem.station?.measurementOptions
                        }
                        stddevLower={dashboardItem.station?.stddevLower}
                        stddevUpper={dashboardItem.station?.stddevUpper}
                        mean={dashboardItem.station?.mean}
                        showSigmaAvg={showSigmaAvg}
                    />
                    <br></br>
                </div>
            )}

            {/* <Divider></Divider> */}
            {isReorderingDashboardItems && (
                <DragIndicatorIcon
                    sx={{
                        textAlign: 'center',
                        paddingLeft: '20px',
                        paddingBottom: '20px',
                    }}
                />
            )}
        </Paper>
    )
}

function DashboardItems({
    ordering,
    setOrdering,
    fetchDashboard,
    userDashboards,
    setUserDashboards,
    currentDashboard,
    setCurrentDashboard,
    dashboardIndex,
    setDashboardIndex,
    setIsLoading,
}) {
    const ShowContentBasedOnRolesWrapper = useShowContentBasedOnRoles()
    const [displayDeleteDashboard, setDisplayDeleteDashboard] = useState(false)
    const [dashboardItemeToDelete, setDashboardItemsToDelete] = useState([])
    const [displayCreateDasboardItem, setDisplayCreateDashboardItem] =
        useState(false)
    const [initialDashboardItems, setInitialDashboardItems] = useState([])
    const [reorderableDashboardItems, setReorderableDashboardItems] = useState(
        []
    )
    const [displayCopyDashboardItem, setDisplayCopyDashboardItem] =
        useState(false)
    const [isReorderingDashboardItems, setIsReorderingDashboardItems] =
        useState(false)
    const [isDeletingDashboardItems, setIsDeletingDashboardItems] =
        useState(false)
    const [editingDashboardName, setEditingDashboardName] = useState(false)
    const [newDashboardName, setNewDashboardName] = useState(
        currentDashboard?.dashboardName
    )
    const [showSigmaAvg, setShowSigmaAvg] = useState(false)
    const toastContext = useToastContext()
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } }

    useEffect(() => {
        if (
            Array.isArray(currentDashboard?.dashboardItems) &&
            currentDashboard?.dashboardItems.length > 0
        ) {
            const reorderableDashboardItems = [
                ...currentDashboard?.dashboardItems.sort((a, b) => {
                    return ordering?.[a.id] - ordering?.[b.id]
                }),
            ]
            setInitialDashboardItems(reorderableDashboardItems)
            setReorderableDashboardItems(reorderableDashboardItems)
        } else {
            setInitialDashboardItems([])
            setReorderableDashboardItems([])
        }
    }, [currentDashboard])

    const handleDeleteStationCheck = (dashboardItemId) => {
        if (dashboardItemeToDelete.includes(dashboardItemId)) {
            setDashboardItemsToDelete((prev) =>
                prev.filter((station) => dashboardItemId !== station)
            )
        } else {
            setDashboardItemsToDelete((prev) => [...prev, dashboardItemId])
        }
    }

    const deleteSelectedDashboardItems = async () => {
        setIsLoading(true)
        await asyncDeleteStationsFromDashboard(
            dashboardItemeToDelete,
            currentDashboard.id
        )
        toastContext.sendToast('Dashboard Items Deleted')
        fetchDashboard()
        setIsLoading(false)
    }

    const onDashboardCreateDisplay = async (display) => {
        setDisplayCreateDashboardItem(display)
        fetchDashboard()
    }

    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(TouchSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    )

    const handleDragEnd = (event) => {
        const { active, over } = event

        if (active.id !== over.id) {
            setReorderableDashboardItems((items) => {
                const activeIndex = items.findIndex((el) => el.id === active.id)
                const overIndex = items.findIndex((el) => el.id === over.id)

                return arrayMove(items, activeIndex, overIndex)
            })
        }
    }

    const handleSubmitReorder = async () => {
        const orderingJSON = {}
        let dbNumber
        for (const [index, dashboard] of reorderableDashboardItems.entries()) {
            orderingJSON[dashboard.id] = index + 1
            dbNumber = dashboard.dashboardId
        }

        await updateDashboardOrdering(orderingJSON, dbNumber)
            .then((data) => {
                setOrdering(data)
                toastContext.sendToast('Dashboard Items re-arranged')
            })
            .catch((e) => toastContext.sendToast(e.message, 'error'))
    }

    const addDashboard = async () => {
        await createDashboard(
            {
                dashboardName: `Dashboard #${
                    userDashboards[userDashboards?.length - 1] + 1
                }`,
            },
            userDashboards[userDashboards?.length - 1] + 1
        )
            .then((data) => {
                toastContext.sendToast('New Dashboard Created')

                const nextDashBoard =
                    userDashboards[userDashboards?.length - 1] + 1
                setUserDashboards((prev) => [...prev, nextDashBoard])
                setDashboardIndex(userDashboards.length)
            })
            .catch((e) => toastContext.sendToast(e.message, 'error'))
    }

    const incrementDashboard = () => {
        if (userDashboards[dashboardIndex + 1]) {
            setDashboardIndex((prev) => (prev += 1))
        }
    }

    const decrementDashboard = () => {
        if (userDashboards[dashboardIndex - 1]) {
            setDashboardIndex((prev) => (prev -= 1))
        }
    }

    const handleDeleteDashboard = async () => {
        try {
            await deleteDashboard(userDashboards[dashboardIndex])
            toastContext.sendToast('Dashboard Deleted')
            setUserDashboards((prev) => {
                const updatedUserDashboards = [...prev].filter(
                    (db) => db != userDashboards[dashboardIndex]
                )
                return updatedUserDashboards
            })
            setDashboardIndex(0)
        } catch (error) {
            console.log('error deleting dashboard', error)
        }
    }

    const handleEditDashboardName = () => {
        setEditingDashboardName((prev) => !prev)
        setNewDashboardName(newDashboardName || currentDashboard?.dashboardName)
    }

    const handleChangeDashboardName = async () => {
        setEditingDashboardName((prev) => !prev)
        const newName = await changeDashboardName(
            userDashboards[dashboardIndex],
            newDashboardName
        )
        setNewDashboardName(newName)
        fetchDashboard()
    }

    const changeTypingNewDashboardName = (e) => {
        setNewDashboardName(e.target.value)
    }

    return (
        <Box sx={styles.innerContainer}>
            {displayDeleteDashboard && (
                <DeleteDashboard
                    dashboardId={userDashboards[dashboardIndex]}
                    displayDeleteDashboard={displayDeleteDashboard}
                    onSetDisplayDeleteDashboard={setDisplayDeleteDashboard}
                    handleDeleteDashboard={handleDeleteDashboard}
                />
            )}
            <div // prev next buttons block
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    padding: 2,
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    gap: 3,
                    zIndex: 1299,
                }}
            >
                <Button onClick={decrementDashboard}>
                    <NavigateBeforeIcon></NavigateBeforeIcon>
                </Button>
                {userDashboards?.map((dashboard, idx) => (
                    <Button
                        key={dashboard.id}
                        size="large"
                        variant={
                            dashboard === userDashboards[dashboardIndex]
                                ? 'outlined'
                                : ''
                        }
                        onClick={() => {
                            setDashboardIndex(userDashboards.indexOf(dashboard))
                        }}
                    >
                        <Typography>{idx + 1}</Typography>
                    </Button>
                ))}
                <Button onClick={addDashboard} variant="contained">
                    <AddIcon variant="filled"></AddIcon>
                </Button>
                <Button onClick={incrementDashboard}>
                    <NavigateNextIcon></NavigateNextIcon>
                </Button>
            </div>

            <Box sx={{ p: 2 }}>
                {displayCreateDasboardItem && (
                    <CreateOrUpdateDashboardItem
                        displayModal={displayCreateDasboardItem}
                        setDisplayModal={setDisplayCreateDashboardItem}
                        onSetDisplayModal={onDashboardCreateDisplay}
                        operation={'Create'}
                        setCurrentDashboard={setCurrentDashboard}
                        userDashboards={userDashboards}
                        dashboardIndex={dashboardIndex}
                        currentDashboard={currentDashboard}
                        setOrdering={setOrdering}
                        setIsLoading={setIsLoading}
                    />
                )}

                <Box sx={styles.buttonList}>
                    <div
                        className="normalButton"
                        onClick={() => setDisplayCreateDashboardItem(true)}
                    >
                        <div className="buttonIconBlock">
                            <CreateIcon fontSize="large" />
                        </div>
                        <div className="buttonTextBlock">
                            <div className="buttonTextBiggerBlock">Create</div>
                            <div className="buttonTextSmallerBlock">
                                Dashboard Item
                            </div>
                        </div>
                    </div>
                    <div
                        className="normalButton"
                        onClick={() => setDisplayDeleteDashboard(true)}
                    >
                        <div className="buttonIconBlock">
                            <DeleteIcon fontSize="large" />
                        </div>
                        <div className="buttonTextBlock">
                            <div className="buttonTextBiggerBlock">Delete</div>
                            <div className="buttonTextSmallerBlock">
                                Dashboard
                            </div>
                        </div>
                    </div>
                    <ShowContentBasedOnRolesWrapper
                        roles={[
                            'admin',
                            'organization_admin',
                            'organization_superuser',
                        ]}
                    >
                        <div
                            className="normalButton"
                            onClick={() => setShowSigmaAvg((prev) => !prev)}
                        >
                            <div className="buttonIconBlock">
                                <FunctionsOutlinedIcon fontSize="large" />
                            </div>
                            <div className="buttonTextBlock">
                                <div className="buttonTextBiggerBlock">
                                    Show
                                </div>
                                <div className="buttonTextSmallerBlock">
                                    control limits and average
                                </div>
                            </div>
                        </div>
                    </ShowContentBasedOnRolesWrapper>

                    <Paper
                        sx={{
                            position: 'fixed',
                            bottom: 0,
                            left: 0,
                            padding: 2,
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                            gap: 3,
                            zIndex: 1299,

                            padding: '7px',
                            paddingLeft: 10,
                            backgroundColor: 'rgba(255, 255, 255, 0.9)',
                            boxShadow: 'none',
                        }}
                    >
                        {isReorderingDashboardItems ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: 2,
                                }}
                            >
                                {' '}
                                <div
                                    className="normalButton normalButtonSmaller"
                                    onClick={() => {
                                        handleSubmitReorder()
                                        setIsReorderingDashboardItems(false)
                                    }}
                                >
                                    <div className="buttonIconBlock">
                                        <SaveIcon />
                                    </div>
                                    <div className="buttonTextBlock">
                                        <div className="buttonTextBiggerBlock">
                                            Save
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="normalButton normalButtonSmaller"
                                    onClick={() => {
                                        setIsReorderingDashboardItems(false)
                                        setReorderableDashboardItems(
                                            initialDashboardItems
                                        )
                                    }}
                                >
                                    <div className="buttonIconBlock">
                                        <XIcon />
                                    </div>
                                    <div className="buttonTextBlock">
                                        <div className="buttonTextBiggerBlock">
                                            Cancel
                                        </div>
                                    </div>
                                </div>
                                {/* <ScreenScrollerButtons /> */}
                            </Box>
                        ) : (
                            currentDashboard?.dashboardItems?.length > 1 &&
                            !isDeletingDashboardItems && (
                                <div
                                    className="normalButton"
                                    onClick={() =>
                                        setIsReorderingDashboardItems(true)
                                    }
                                >
                                    <div className="buttonIconBlock">
                                        <ReorderIcon fontSize="large" />
                                    </div>
                                    <div className="buttonTextBlock">
                                        <div className="buttonTextBiggerBlock">
                                            Reorder
                                        </div>
                                        <div className="buttonTextSmallerBlock">
                                            Dashboard
                                        </div>
                                    </div>
                                </div>
                            )
                        )}

                        {isDeletingDashboardItems ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: 2,
                                }}
                            >
                                <div
                                    className="normalButton normalButtonSmaller"
                                    onClick={() => {
                                        deleteSelectedDashboardItems()
                                        setIsDeletingDashboardItems(false)
                                    }}
                                >
                                    <div className="buttonIconBlock ">
                                        <DeleteIcon />
                                    </div>
                                    <div className="buttonTextBlock">
                                        <div className="buttonTextBiggerBlock">
                                            Delete
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className="normalButton normalButtonSmaller"
                                    onClick={() => {
                                        setIsDeletingDashboardItems(false)
                                    }}
                                >
                                    <div className="buttonIconBlock">
                                        <XIcon />
                                    </div>
                                    <div className="buttonTextBlock">
                                        <div className="buttonTextBiggerBlock">
                                            Cancel
                                        </div>
                                    </div>
                                </div>

                                <ScreenScrollerButtons />
                            </Box>
                        ) : (
                            currentDashboard?.dashboardItems?.length > 0 &&
                            !isReorderingDashboardItems && (
                                <div
                                    className="normalButton"
                                    onClick={() => {
                                        setIsDeletingDashboardItems(true)
                                    }}
                                >
                                    <div className="buttonIconBlock">
                                        <DeleteIcon fontSize="large" />
                                    </div>
                                    <div className="buttonTextBlock">
                                        <div className="buttonTextBiggerBlock">
                                            Delete
                                        </div>
                                        <div className="buttonTextSmallerBlock">
                                            Dashboard Items
                                        </div>
                                    </div>
                                </div>
                            )
                        )}
                    </Paper>
                </Box>

                <Divider
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        height: '30px',
                        margin: '15px',
                        marginBottom: '20px',
                    }}
                >
                    {editingDashboardName && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                '& .MuiTextField-root': {
                                    width: '25ch',
                                    '& input': {
                                        textAlign: 'center',
                                    },
                                },
                            }}
                        >
                            <TextField
                                id="outlined-helperText"
                                label="Enter desired Dashboard Name"
                                helperText=""
                                fullWidth
                                onChange={changeTypingNewDashboardName}
                                value={newDashboardName}
                                InputProps={{ style: { textAlign: 'center' } }}
                            />
                            <Button onClick={handleChangeDashboardName}>
                                Accept
                            </Button>
                            <Button onClick={handleEditDashboardName}>
                                <CloseIcon />
                            </Button>
                        </Box>
                    )}
                    {!editingDashboardName && newDashboardName && (
                        <Chip
                            sx={{
                                fontFamily: 'Montserrat',
                                fontWeight: '700',
                                fontSize: '18px',
                                color: '#363636',
                                padding: '10px 7px 10px 7px',
                                textTransform: 'uppercase',
                                backgroundColor: 'transparent',
                            }}
                            label={
                                newDashboardName
                                    ? newDashboardName
                                    : currentDashboard?.dashboardName
                            }
                            onDelete={handleEditDashboardName}
                            deleteIcon={<EditIcon />}
                        />
                    )}
                </Divider>

                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                >
                    <Box sx={styles.cardContainer}>
                        <SortableContext
                            items={reorderableDashboardItems || []}
                            strategy={rectSortingStrategy}
                        >
                            {reorderableDashboardItems?.length > 0 &&
                                reorderableDashboardItems
                                    //   ?.slice()
                                    //   .reverse()
                                    .map((dashboardItem) => (
                                        <>
                                            <Box
                                                component={Box}
                                                sx={{
                                                    textDecoration: 'none',
                                                    ':hover': {
                                                        boxShadow:
                                                            '0px 10px 15px rgba(0, 0, 0, 0.2)',
                                                    },
                                                }}
                                                onClick={() =>
                                                    handleDeleteStationCheck(
                                                        dashboardItem.id
                                                    )
                                                }
                                            >
                                                <DashBoardItem
                                                    key={dashboardItem.id}
                                                    dashboardItem={
                                                        dashboardItem
                                                    }
                                                    {...dashboardItem}
                                                    setDisplayCopyDashboardItem={
                                                        setDisplayCopyDashboardItem
                                                    }
                                                    displayCopyDashboardItem={
                                                        displayCopyDashboardItem
                                                    }
                                                    isReorderingDashboardItems={
                                                        isReorderingDashboardItems
                                                    }
                                                    isDeletingDashboardItems={
                                                        isDeletingDashboardItems
                                                    }
                                                    reorderableDashboardItems={
                                                        reorderableDashboardItems
                                                    }
                                                    showSigmaAvg={showSigmaAvg}
                                                />
                                            </Box>
                                            {isDeletingDashboardItems && (
                                                <Checkbox
                                                    {...label}
                                                    sx={{
                                                        height: '40px',
                                                        marginRight: '20px',
                                                        marginLeft: '0px',

                                                        color: pink[800],
                                                        '&.Mui-checked': {
                                                            color: pink[600],
                                                        },
                                                    }}
                                                    onClick={() =>
                                                        handleDeleteStationCheck(
                                                            dashboardItem.id
                                                        )
                                                    }
                                                    checked={dashboardItemeToDelete.includes(
                                                        dashboardItem.id
                                                    )}
                                                />
                                            )}
                                        </>
                                    ))}
                        </SortableContext>
                    </Box>
                </DndContext>
            </Box>
        </Box>
    )
}

export default DashboardItems
