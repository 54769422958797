import React, { useRef, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import {
    Box,
    ButtonBase,
    Hidden,
    Menu,
    MenuItem,
    Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useAuth } from 'oidc-react'
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { ReactComponent as UserIcon } from '../../icons/user.svg'


const useStyles = makeStyles((theme) => ({
    avatar: {
        height: 32,
        width: 32,
        marginRight: theme.spacing(1),
    },
    popover: {
        width: 200,
    },
}))
// Allows for the account UI to be opened and closed. Displays options such as profile and account.
const AccountSettings = () => {
    const classes = useStyles()
    const history = useHistory()
    const ref = useRef(null)
    const auth = useAuth()
    const [isOpen, setOpen] = useState(false)

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleLogout = async () => {
        try {
            history.push('/signout')
        } catch (err) {}
    }

    return (
        <>
            <Box
                display="flex"
                alignItems="center"
                component={ButtonBase}
                onClick={handleOpen}
                ref={ref}
            >
                <UserIcon
                    style={{
                        marginRight: '0rem',
                        color: '#363636',
                        width: '27px',
                        height: '27px',
                    }}
                />
                <Hidden smDown>
                    <Typography variant="h6" color="inherit">
                        {/* {user?.name || ''} */}
                    </Typography>
                </Hidden>
            </Box>
            <Menu
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                keepMounted
                PaperProps={{ className: classes.popover }}
                getContentAnchorEl={null}
                anchorEl={ref.current}
                open={isOpen}
            >
                <MenuItem component={RouterLink} to="/settings">
                    Profile
                </MenuItem>
                {/* <MenuItem component={RouterLink} to="/account">
                    Account
                </MenuItem> */}
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
        </>
    )
}

export default AccountSettings
