import React from 'react'
import { Box, Button } from '@mui/material'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'

//Renders buttons to scroll up and down
export const ScreenScrollerButtons = ({ selector = null }) => (
    <Box
        sx={{
            position: 'fixed',
            bottom: 90,
            right: 60,
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            zIndex: 1299,
        }}
    >
        <Button
            variant="contained"
            onClick={() =>
                document
                    .querySelector(selector ?? '.content')
                    .scrollBy({ top: -200, behavior: 'smooth' })
            }
        >
            <KeyboardArrowUpIcon sx={{ fontSize: 30 }} />
        </Button>

        <Button
            variant="contained"
            onClick={() =>
                document
                    .querySelector(selector ?? '.content')
                    .scrollBy({ top: 200, behavior: 'smooth' })
            }
        >
            <KeyboardArrowDown sx={{ fontSize: 30 }} />
        </Button>
    </Box>
)
