import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import { useAuth } from 'oidc-react'

//This is the sign-in page
const SignIn = () => {
    const auth = useAuth()
    if (auth.isLoading) return <Box></Box>

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                flexDirection: 'column',
            }}
        >
            <Typography variant="h1" mb={3}>
                Welcome to LIMIS
            </Typography>
            <Button
                color="primary"
                onClick={() => auth.signIn()}
                variant="contained"
                size="large"
            >
                Sign in / Sign up
            </Button>
        </Box>
    )
}

export default SignIn
