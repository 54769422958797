import React from 'react'
import axios from 'axios'
import { useAuth } from 'oidc-react'
import { BrowserRouter as Router } from 'react-router-dom'
import { renderRoutes, guestRoutes, authRoutes } from 'src/routes'
import { isUserRole } from './utils/isUserRole'
import { adminRoutes } from './routes'

// Sets up a user's route configuration based on if they are authenticated.
const RouteRenderer = () => {
    const auth = useAuth()
    const [accessTokenLoaded, setAccessTokenLoaded] = React.useState(false)
    const [isUserAdmin, setIsUserAdmin] = React.useState(false)
    const [interceptor, setInterceptor] = React.useState(null)

    React.useEffect(() => {
        if (!auth.userData) {
            setAccessTokenLoaded(false)
            return
        }

        axios.interceptors.request.eject(interceptor)

        let requestInterceptor = axios.interceptors.request.use(
            (config) => {
                // Do something before request is sent
                config.headers['Authorization'] = auth.userData.access_token
                return config
            },
            (error) => {
                // Do something with request error
                return Promise.reject(error)
            }
        )

        setInterceptor(requestInterceptor)
        if (
            isUserRole(auth, 'admin') ||
            isUserRole(auth, 'organization_admin')
        ) {
            setIsUserAdmin(true)
        }
        setAccessTokenLoaded(true)
    }, [auth.userData, auth.userData?.access_token])

    return accessTokenLoaded ? (
        <Router>
            {renderRoutes([...(isUserAdmin ? adminRoutes : []), ...authRoutes])}
        </Router>
    ) : (
        <Router>{renderRoutes(guestRoutes)}</Router>
    )
}

export default RouteRenderer
