import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Box, Button, Divider, Paper, Typography } from '@mui/material'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FormControlLabel, Checkbox} from '@mui/material';
import { makeStyles } from '@mui/styles'

import { Link as RouterLink } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
root: {
minWidth: '900px',
padding: '25px',
},
zoneRoot: {
display: 'flex',
flexWrap: 'wrap',
},
}))

//Renders a table that shows all data for a station
const Summary = ({ loadingComplete, stations, fetchSummary }) => {
const classes = useStyles()

console.log("Map me", stations.map((station) => (
                        station.stationMeasurements.map((measurement) => (
                            {measurement, station}
                        )))).flat())

return (
    <Box className={classes.root}>
        <Box className={classes.zoneRoot}>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Reviewed</TableCell>
                            <TableCell align="left">Station</TableCell>
                            <TableCell align="right">Target</TableCell>
                            <TableCell align="right">Range</TableCell>
                            <TableCell align="right">Value</TableCell>
                            <TableCell align="right">Measured At</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {stations.map((station) => (
                            station.stationMeasurements.map((measurement) => (
                                <TableRow
                                    key={station.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="left">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={false}
                                                    onChange={(e) => console.log('clicked')}
                                                />
                                            }
                                            label="reviewed by... "
                                            labelPlacement="top"
                                        />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {station.stationTitle}
                                    </TableCell>
                                    <TableCell align="right">{station.target}{station.unit}</TableCell>
                                    <TableCell align="right">{station.lowerrange}{station.unit} - {station.upperrange}{station.unit}</TableCell>
                                    <TableCell align="right">{measurement.measurementValue}{station.unit}</TableCell>
                                    <TableCell align="right">{measurement.createdAt}</TableCell>
                                </TableRow>
                            )).flat()
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    </Box>
)


}

Summary.defaultProps = {
loadingComplete: false,
zones: [],
onDownloadReport: () => {},
}

Summary.propTypes = {
loadingComplete: PropTypes.bool,
zones: PropTypes.array,
onDownloadReport: PropTypes.func,
}

export default Summary