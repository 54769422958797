import React, { useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { getIndividualZone, updateZoneOrdering } from './actions'
import { useShowContentBasedOnRoles } from '../../hooks/useShowContentBasedOnRoles'
import PropTypes from 'prop-types'
import { Box, Button, Divider, Paper, Typography } from '@mui/material'
import { styles } from '../../theme/styles'
import QrCode2Icon from '@mui/icons-material/QrCode2'
import QRScannerModal from './QrScanner'
import { positions } from '@mui/system'
import CreateZone from './CreateOrUpdateZone'
import { downloadExcelFiles } from '../../utils/excel'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import CopyContentIcon from '@mui/icons-material/ContentCopy'
import CopyZone from './IndividualZone/CopyZone'
import IndividualZone from './IndividualZone/IndividualZone'
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core'
import {
    arrayMove,
    SortableContext,
    rectSortingStrategy,
    sortableKeyboardCoordinates,
} from '@dnd-kit/sortable'
import { ScreenScrollerButtons } from '../../components/ScreenScrollerButtons'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined'

import { ReactComponent as DownloadIcon } from '../../icons/buttons/download.svg'
import { ReactComponent as CreateIcon } from '../../icons/buttons/create.svg'
// import { ReactComponent as ScanIcon } from '../../icons/buttons/scan.svg'
import { ReactComponent as ReorderIcon } from '../../icons/buttons/order.svg'
import { ReactComponent as SaveIcon } from '../../icons/buttons/save.svg'
import { ReactComponent as XIcon } from '../../icons/buttons/x.svg'

//An indavidual zone JSX element that is rendered by Zones
export const Zone = ({
    zoneTitle,
    lastAccess,
    elevated,
    signedUrl,
    isReorderingZones,
    asyncReloadZone,
    imageS3Key,
    organizationIds,
    zoneId,
    displayCopyZone,
    setDisplayCopyZone,
    ...rest
}) => {
    const ShowContentBasedOnRolesWrapper = useShowContentBasedOnRoles()
    const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({
            id: rest.id,
            disabled: isReorderingZones ? false : true,
        })

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    }

    return (
        <Paper
            key={rest.id}
            ref={setNodeRef}
            style={{
                ...styles.paper,
                transform: CSS.Transform.toString(transform),
                transition: transition, // assuming transition is a valid CSS transition property value
            }}
            {...attributes}
            {...listeners}
        >
            <Box sx={styles.cardImageContainer}>
                {signedUrl && (
                    <img src={signedUrl} alt={zoneTitle} style={styles.image} />
                )}
            </Box>
            {/* <Box sx={styles.cardTextContainer}> */}
            {/* <Box>
                    <Typography variant="h4" sx={{ mb: 1 }}>
                        {zoneTitle}
                    </Typography>
                </Box>
                {displayCopyZone && (
                    <CopyZone
                        zone={{
                            zoneTitle,
                            imageS3Key,
                            organizationIds,
                        }}
                        displayCopyZone={displayCopyZone}
                        onSetDisplayCopyZone={setDisplayCopyZone}
                        zoneId={zoneId}
                        onSuccess={asyncReloadZone}
                    />
                )}

                {isReorderingZones && <DragIndicatorIcon />}
            </Box> */}
            <Box sx={styles.cardTextContainer}>
                <div style={styles.cardTextBigZone}> {zoneTitle}</div>
                {displayCopyZone && (
                    <CopyZone
                        zone={{
                            zoneTitle,
                            imageS3Key,
                            organizationIds,
                        }}
                        displayCopyZone={displayCopyZone}
                        onSetDisplayCopyZone={setDisplayCopyZone}
                        zoneId={zoneId}
                        onSuccess={asyncReloadZone}
                    />
                )}
                {isReorderingZones && <DragIndicatorIcon />}
            </Box>
        </Paper>
    )
}

//Renders all the zones inside of the zone and allows reordering
//If the  user is an admin buttons to add a zone is given
function Zones({
    zones,
    ordering,
    updateOrdering,
    onDownloadReport,
    fetchZones,
}) {
    const ShowContentBasedOnRolesWrapper = useShowContentBasedOnRoles()
    const [hoveredZone, setHoveredZone] = useState('')
    const [displayQRScanner, setDisplayQRScanner] = useState(false)
    const [isDownloadingReport, setInitiateReportDownload] = useState(false)
    const [displayCreateZone, setDisplayCreateZone] = useState(false)
    const [initialZones, setInitialZones] = useState([])
    const [reorderableZones, setReorderableZones] = useState([])
    const [displayCopyZone, setDisplayCopyZone] = useState(false)
    const [isReorderingZones, setIsReorderingZones] = useState(false)

    const onZoneCreateDisplay = async (display) => {
        setDisplayCreateZone(display)
        fetchZones()
    }

    useEffect(() => {
        if (Array.isArray(zones)) {
            const sortedInitialZones = [
                ...zones.sort((a, b) => {
                    return ordering?.[a.id] - ordering?.[b.id]
                }),
            ]
            setInitialZones(sortedInitialZones)
            setReorderableZones(sortedInitialZones)
        } else {
            setInitialZones([])
            setReorderableZones([])
        }
    }, [zones, ordering])

    useEffect(() => {
        async function asyncDownloadReport() {
            if (isDownloadingReport) {
                setInitiateReportDownload(false)
                const reportContents = await onDownloadReport()
                downloadExcelFiles(reportContents)
            }
        }

        asyncDownloadReport()
    }, [isDownloadingReport])

    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(TouchSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    )

    const handleDragEnd = (event) => {
        const { active, over } = event

        if (active.id !== over.id) {
            setReorderableZones((items) => {
                const activeIndex = items.findIndex((el) => el.id === active.id)
                const overIndex = items.findIndex((el) => el.id === over.id)

                return arrayMove(items, activeIndex, overIndex)
            })
        }
    }

    const handleSubmitReorder = async () => {
        const orderingJSON = {}
        for (const [index, zone] of reorderableZones.entries()) {
            orderingJSON[zone.id] = index + 1
        }

        await updateZoneOrdering(orderingJSON).then(() =>
            updateOrdering(orderingJSON)
        )
    }

    return (
        <Box sx={styles.innerContainer}>
            {/* {displayQRScanner && (
                <QRScannerModal
                    displayQRScanner={displayQRScanner}
                    onSetDisplayQRScanner={setDisplayQRScanner}
                />
            )} */}

            {/* Only shown to admins*/}
            {displayCreateZone && (
                <CreateZone
                    displayModal={displayCreateZone}
                    setDisplayModal={setDisplayCreateZone}
                    onSetDisplayModal={onZoneCreateDisplay}
                    operation={'Create'}
                />
            )}

            <Box sx={styles.buttonList}>
                {/* <div
                    className="normalButton"
                    onClick={() => setDisplayQRScanner(true)}
                >
                    <div className="buttonIconBlock">
                        <QrCode2Icon fontSize="large" />
                    </div>
                    <div className="buttonTextBlock">
                        <div className="buttonTextBiggerBlock">Scan</div>
                        <div className="buttonTextSmallerBlock">zone</div>
                    </div>
                </div> */}
                {/* {reorderableZones.length > 0 && (
                    <div
                        className="normalButton"
                        onClick={() => setInitiateReportDownload(true)}
                    >
                        <div className="buttonIconBlock">
                            <DownloadIcon />
                        </div>
                        <div className="buttonTextBlock">
                            <div className="buttonTextBiggerBlock">Export</div>
                            <div className="buttonTextSmallerBlock">report</div>
                        </div>
                    </div>
                )} */}
                {
                    <ShowContentBasedOnRolesWrapper
                        roles={[
                            'admin',
                            'organization_admin',
                            'organization_superuser',
                        ]}
                    >
                        <div
                            className="normalButton"
                            onClick={() => setDisplayCreateZone(true)}
                        >
                            <div className="buttonIconBlock">
                                <CreateIcon />
                            </div>
                            <div className="buttonTextBlock">
                                <div className="buttonTextBiggerBlock">
                                    Create
                                </div>
                                <div className="buttonTextSmallerBlock">
                                    zone
                                </div>
                            </div>
                        </div>
                    </ShowContentBasedOnRolesWrapper>
                }
                <Paper
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        padding: '7px',
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                        gap: 3,
                        zIndex: 1299,
                        backgroundColor: 'rgba(255, 255, 255, 0.9)',
                        boxShadow: 'none',
                    }}
                >
                    {isReorderingZones ? (
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 2,
                            }}
                        >
                            <div
                                className="normalButton normalButtonSmaller"
                                onClick={() => {
                                    handleSubmitReorder()
                                    setIsReorderingZones(false)
                                }}
                            >
                                <div className="buttonIconBlock">
                                    <SaveIcon />
                                </div>
                                <div className="buttonTextBlock">
                                    <div className="buttonTextBiggerBlock">
                                        Save
                                    </div>
                                </div>
                            </div>

                            <div
                                className="normalButton normalButtonSmaller"
                                onClick={() => {
                                    setIsReorderingZones(false)
                                    setReorderableZones(initialZones)
                                }}
                            >
                                <div className="buttonIconBlock">
                                    <XIcon />
                                </div>
                                <div className="buttonTextBlock">
                                    <div className="buttonTextBiggerBlock">
                                        Cancel
                                    </div>
                                </div>
                            </div>

                            {/* <ScreenScrollerButtons /> */}
                        </Box>
                    ) : (
                        reorderableZones.length > 1 && (
                            <div
                                className="normalButton normalButtonSmaller "
                                onClick={() => setIsReorderingZones(true)}
                            >
                                <div className="buttonIconBlock">
                                    <ReorderIcon />
                                </div>
                                <div className="buttonTextBlock">
                                    <div className="buttonTextBiggerBlock">
                                        Reorder
                                    </div>

                                    <div className="buttonTextSmallerBlock">
                                        zones
                                    </div>
                                </div>
                            </div>
                        )
                    )}
                </Paper>
            </Box>

            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
            >
                <Box sx={styles.cardContainer}>
                    <SortableContext
                        items={reorderableZones}
                        strategy={rectSortingStrategy}
                    >
                        {reorderableZones.length > 0 &&
                            reorderableZones.map((zone) => (
                                <Box
                                    component={
                                        !displayCopyZone
                                            ? !isReorderingZones
                                                ? RouterLink
                                                : undefined
                                            : Box
                                    }
                                    to={`/zones/${zone.id}`}
                                    sx={{
                                        ':hover': {
                                            boxShadow:
                                                '0px 10px 15px rgba(0, 0, 0, 0.2)',
                                        },
                                        textDecoration: 'none',
                                    }}
                                >
                                    <Zone
                                        {...zone}
                                        setDisplayCopyZone={setDisplayCopyZone}
                                        displayCopyZone={displayCopyZone}
                                        isReorderingZones={isReorderingZones}
                                        lastAccess={
                                            zone.lastAccess !== null
                                                ? zone.lastAccess
                                                : zone.updatedAt
                                        }
                                    />
                                </Box>
                            ))}
                    </SortableContext>
                </Box>
            </DndContext>
        </Box>
    )
}

Zones.defaultProps = {
    zones: [],
    onDownloadReport: () => {},
}

Zones.propTypes = {
    zones: PropTypes.array,
    onDownloadReport: PropTypes.func,
}

export default Zones
