import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import {
    Box,
    Button,
    Typography,
    InputLabel,
    NativeSelect,
    FormControl,
    Grid,
    InputAdornment,
} from '@mui/material'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { useToastContext } from '../../../context/ToastContext'
import { createStation, updateStation } from '../actions'
import useCrudLoader from '../../../hooks/useCrudLoader'

//component used to render a form where users can enter data to change or update a station
//props should have a variable 'operation' which defines what changes the user is making
const CreateOrUpdateStationModal = ({
    zoneId,
    displayCreateStation,
    onSetDisplayCreateStation,
    station = {},
    operation = 'Create',
    onSuccess = () => {},
}) => {
    const [location, setLocation] = useState('')
    const history = useHistory()
    const [formValues, setFormValues] = useState(
        operation === 'Create' ? {} : station
    )
    const [error, setError] = useState(false)
    const [isMultipleTypeSelected, setMultipleTypeSelected] = useState(false)
    const [measurementOptions, setMeasurementOptions] = useState([])
    const [isMeasurementOptions, setIsMeasurementOptions] = useState(true)
    const toastContext = useToastContext()
    const [LoaderWrapper, setIsLoading, isLoading] = useCrudLoader()

    useEffect(() => {
        if (location) {
            const locationStripRegexp = new RegExp('/zones/.')
            const [locationStrip, ...rest] = location.match(locationStripRegexp)
            history.push(locationStrip)
            onSetDisplayCreateStation(false)
        }
    }, [location])

    useEffect(() => {
        if (!!station?.measurementOptions?.length) {
            setMeasurementOptions(station.measurementOptions)
            setMultipleTypeSelected(true)
            setIsMeasurementOptions(false)
        }
    }, [isMeasurementOptions])

    const handleInputChange = (e) => {
        const { name, value } = e.target

        // Set the value to null if the input field is empty (usually after erasing a previously entered value)
        const updatedValue = value.trim() === '' ? null : value

        setFormValues({
            ...formValues,
            [name]: updatedValue,
        })
    }

    const handleSubmit = async (event) => {
        if (isLoading) {
            return
        }
        event.preventDefault()
        setIsLoading(true)
        if (operation === 'Create') {
            await createStation(
                zoneId,
                formValues.stationTitle,
                formValues.stationDescription,
                formValues.target,
                formValues.rangeLow,
                formValues.rangeHigh,
                formValues.measurementUnit,
                measurementOptions
            )
                .then(() => {
                    onSuccess?.()
                    toastContext.sendToast('Successfully Created Station')
                })
                .catch((e) => toastContext.sendToast(e.message, 'error'))
        } else {
            formValues.measurementOptions = measurementOptions
            await updateStation(zoneId, station?.id, formValues)
                .then(() => {
                    onSuccess?.()
                    toastContext.sendToast('Successfully Updated Station')
                })
                .catch((e) => toastContext.sendToast(e.message, 'error'))
        }

        setIsLoading(false)
        onSetDisplayCreateStation(false)
    }

    const handleMeasurementTypeChange = (event) =>
        setMultipleTypeSelected(
            event.target.value === 'multiple' ? true : false
        )

    const handleCancelMeasurementType = (index) => {
        let values = [...measurementOptions]
        values.splice(index, 1)
        setMeasurementOptions(values)
    }

    const handleAddMeasurementType = () => {
        if (
            !!formValues.inputMeasurementType &&
            formValues.inputMeasurementType.trim().length > 0
        ) {
            setMeasurementOptions([
                ...measurementOptions,
                formValues.inputMeasurementType,
            ])
            setFormValues({
                ...formValues,
                inputMeasurementType: '',
            })
        }
    }
    if (isLoading && displayCreateStation) {
        return (
            <Dialog
                open={displayCreateStation}
                onClose={() => onSetDisplayCreateStation(false)}
                fullWidth={true}
                maxWidth={'xs'}
            >
                <LoaderWrapper></LoaderWrapper>
            </Dialog>
        )
    }
    return (
        displayCreateStation && (
            <Dialog
                open={displayCreateStation}
                onClose={() => onSetDisplayCreateStation(false)}
            >
                <DialogContent>
                    <DialogContentText id="create-zone-error">
                        {error}
                    </DialogContentText>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="stationTitle"
                            name="stationTitle"
                            label="Station Name"
                            type="text"
                            fullWidth
                            variant="standard"
                            onChange={handleInputChange}
                            value={formValues.stationTitle}
                        />
                        <TextField
                            margin="dense"
                            id="stationDescription"
                            name="stationDescription"
                            label="Station Description"
                            type="text"
                            fullWidth
                            variant="standard"
                            onChange={handleInputChange}
                            value={formValues.stationDescription}
                        />
                        <TextField
                            margin="dense"
                            id="target"
                            name="target"
                            label="Target"
                            type="number"
                            fullWidth
                            variant="standard"
                            onChange={handleInputChange}
                            value={formValues.target}
                            error={
                                (formValues.possibleRangeLow &&
                                    parseFloat(formValues.target) <
                                        formValues.possibleRangeLow) ||
                                (formValues.possibleRangeHigh &&
                                    parseFloat(formValues.target) >
                                        formValues.possibleRangeHigh)
                            }
                            helperText={
                                (formValues.possibleRangeLow &&
                                    parseFloat(formValues.target) <
                                        formValues.possibleRangeLow) ||
                                (formValues.possibleRangeHigh &&
                                    parseFloat(formValues.target) >
                                        formValues.possibleRangeHigh)
                                    ? 'Target is out of possible range'
                                    : ''
                            }
                        />
                        <TextField
                            margin="dense"
                            id="rangeLow"
                            name="rangeLow"
                            label="Range Low"
                            type="number"
                            fullWidth
                            variant="standard"
                            onChange={handleInputChange}
                            value={formValues.rangeLow}
                            error={
                                (formValues.possibleRangeLow &&
                                    parseFloat(formValues.rangeLow) <
                                        formValues.possibleRangeLow) ||
                                (formValues.possibleRangeHigh &&
                                    parseFloat(formValues.rangeLow) >
                                        formValues.possibleRangeHigh)
                            }
                            helperText={
                                (formValues.possibleRangeLow &&
                                    parseFloat(formValues.rangeLow) <
                                        formValues.possibleRangeLow) ||
                                (formValues.possibleRangeHigh &&
                                    parseFloat(formValues.rangeLow) >
                                        formValues.possibleRangeHigh)
                                    ? 'Range low is out of possible range'
                                    : ''
                            }
                        />
                        <TextField
                            margin="dense"
                            id="rangeHigh"
                            name="rangeHigh"
                            label="Range High"
                            type="number"
                            fullWidth
                            variant="standard"
                            onChange={handleInputChange}
                            value={formValues.rangeHigh || null}
                            error={
                                (formValues.possibleRangeLow &&
                                    parseFloat(formValues.rangeHigh) <
                                        formValues.possibleRangeLow) ||
                                (formValues.possibleRangeHigh &&
                                    parseFloat(formValues.rangeHigh) >
                                        formValues.possibleRangeHigh)
                            }
                            helperText={
                                (formValues.possibleRangeLow &&
                                    parseFloat(formValues.rangeHigh) <
                                        formValues.possibleRangeLow) ||
                                (formValues.possibleRangeHigh &&
                                    parseFloat(formValues.rangeHigh) >
                                        formValues.possibleRangeHigh)
                                    ? 'Range high is out of possible range'
                                    : ''
                            }
                        />
                        <TextField
                            margin="dense"
                            id="possibleRangeLow"
                            name="possibleRangeLow"
                            label="possible Range Low"
                            type="number"
                            fullWidth
                            variant="standard"
                            onChange={handleInputChange}
                            value={formValues.possibleRangeLow}
                        />
                        <TextField
                            margin="dense"
                            id="possibleRangeHigh"
                            name="possibleRangeHigh"
                            label="Possible Range High"
                            type="number"
                            fullWidth
                            variant="standard"
                            onChange={handleInputChange}
                            value={formValues.possibleRangeHigh}
                        />
                        <TextField
                            margin="dense"
                            id="measurementUnit"
                            name="measurementUnit"
                            label="Measurement Unit"
                            type="text"
                            fullWidth
                            variant="standard"
                            onChange={handleInputChange}
                            value={formValues.measurementUnit}
                        />
                        <FormControl fullWidth margin="dense">
                            <InputLabel
                                variant="standard"
                                htmlFor="uncontrolled-native"
                            >
                                Select Measurement Type
                            </InputLabel>
                            <NativeSelect
                                defaultValue={
                                    !!station
                                        ? !station.measurementOptions?.length
                                            ? 'single'
                                            : 'multiple'
                                        : 'single'
                                }
                                inputProps={{
                                    name: 'select-measurement-type',
                                    id: 'uncontrolled-native',
                                }}
                                onChange={handleMeasurementTypeChange}
                            >
                                <option value={'single'}>Single</option>
                                <option value={'multiple'}>Multiple</option>
                            </NativeSelect>
                        </FormControl>
                        {isMultipleTypeSelected ? (
                            <Box paddingTop={'8px'}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="inputMeasurementType"
                                    name="inputMeasurementType"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                    placeholder="Enter measurement type.."
                                    onChange={handleInputChange}
                                    value={formValues?.inputMeasurementType}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Button
                                                    edge="end"
                                                    color="primary"
                                                    onClick={
                                                        handleAddMeasurementType
                                                    }
                                                >
                                                    <Typography>Add</Typography>
                                                </Button>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <ul>
                                    {measurementOptions.map((type, index) => {
                                        return (
                                            <Grid container spacing={'24px'}>
                                                <Grid item>
                                                    <Typography>
                                                        {type}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        onClick={() =>
                                                            handleCancelMeasurementType(
                                                                index
                                                            )
                                                        }
                                                    >
                                                        <Typography
                                                            fontSize={10}
                                                        >
                                                            Delete
                                                        </Typography>
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        )
                                    })}
                                </ul>
                            </Box>
                        ) : (
                            <></>
                        )}
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => onSetDisplayCreateStation(false)}>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        disabled={
                            (!formValues?.stationTitle ||
                                (!isMultipleTypeSelected &&
                                    !formValues?.measurementUnit)) &&
                            operation === 'Create'
                        }
                    >
                        {operation}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    )
}

export default CreateOrUpdateStationModal
