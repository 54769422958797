import React, { useState, useEffect } from 'react'
import Zones from './Zones'
import { getAllZones, downloadReport, getZoneOrdering } from './actions'
import useLoader from '../../hooks/useLoader'
import { useToastContext } from '../../context/ToastContext'

//Renders Zones when the url path is a match
//Uses the useLoader hook to display the waiting screen while the zone data is being retrieved from the database
const ZonesPath = () => {
    const [zones, setZones] = useState([])
    const [ordering, setOrdering] = useState({})
    const [LoaderWrapper, setIsLoading] = useLoader()
    const toastContext = useToastContext()

    const asyncFetchZones = async () => {
        setIsLoading(true)

        const [zoneResults, zoneOrderingResults] = await Promise.all([
            getAllZones(),
            getZoneOrdering(),
        ]).catch((e) => toastContext.sendToast('Error Fetching Data', 'error'))

        setZones(zoneResults)
        setOrdering(zoneOrderingResults)

        setIsLoading(false)
    }

    useEffect(() => {
        asyncFetchZones()
    }, [])

    return (
        <LoaderWrapper>
            <Zones
                zones={zones}
                ordering={ordering}
                updateOrdering={setOrdering}
                onDownloadReport={downloadReport}
                fetchZones={asyncFetchZones}
            />
        </LoaderWrapper>
    )
}

export default ZonesPath
