import React, { useState, useEffect } from 'react'
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core'
import {
    arrayMove,
    rectSortingStrategy,
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import {
    Box,
    CircularProgress,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
} from '@mui/material'
import { useToastContext } from '../../../context/ToastContext'
import { updateLogsheetStationOrdering } from '../actions'

export default function StationReordererByZone(props) {
    const [zones, setZones] = useState([])
    const toastContext = useToastContext()

    useEffect(() => {
        const zones = {}

        props?.stations.forEach((station) => {
            const zoneId = station.zoneId
            //Count how many stations each zone has.
            // This is imporant to order stations later on
            if (zoneId in zones) {
                zones[zoneId].stationCount += 1
            } else {
                zones[zoneId] = { stationCount: 1, ...station.Zone }
            }
        })

        setZones(Object.values(zones))
    }, [])

    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(TouchSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    )

    const handleDragEnd = (event) => {
        const { active, over } = event

        if (active.id !== over.id) {
            setZones((items) => {
                const activeIndex = items.findIndex((el) => el.id === active.id)
                const overIndex = items.findIndex((el) => el.id === over.id)

                return arrayMove(items, activeIndex, overIndex)
            })
        }
    }

    const handleClose = () => {
        props.setDisplayModal(false)
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        const orderingJSON = {}
        let currentStationCountOffset = 0

        for (const [_, zone] of zones.entries()) {
            const zoneStations = [...props.stations].filter(
                (station) => station.zoneId === zone.id
            )
            zoneStations.forEach((station, stationIndex) => {
                orderingJSON[station.id] =
                    stationIndex + currentStationCountOffset + 1
            })
            currentStationCountOffset += zone.stationCount
        }

        await updateLogsheetStationOrdering(
            props.logsheetId,
            orderingJSON
        ).then(() => {
            props.setStationOrdering(orderingJSON)
            props.setDisplayModal(false)
        })
    }

    return (
        <Dialog open={true} onClose={handleClose}>
            <DialogContent
                sx={{
                    width: '440px',
                }}
            >
                <form onSubmit={handleSubmit}>
                    {zones.length > 0 ? (
                        <Box sx={{ my: 2 }}>
                            <DndContext
                                sensors={sensors}
                                collisionDetection={closestCenter}
                                onDragEnd={handleDragEnd}
                            >
                                <SortableContext
                                    items={zones}
                                    strategy={rectSortingStrategy}
                                >
                                    {zones.map((zone, idx) => (
                                        <SortableZoneItem key={idx} {...zone} />
                                    ))}
                                </SortableContext>
                            </DndContext>
                        </Box>
                    ) : (
                        <CircularProgress sx={{ display: 'block', my: 2 }} />
                    )}
                </form>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSubmit}>Save</Button>
            </DialogActions>
        </Dialog>
    )
}

const SortableZoneItem = ({ id, zoneTitle }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({
        id,
    })

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    }

    return (
        <Box
            sx={{
                display: 'flex',
                gap: 2,
                alignItems: 'center',
                opacity: isDragging ? '0.5' : '1',
            }}
            style={style}
            {...attributes}
            {...listeners}
            ref={setNodeRef}
        >
            <DragIndicatorIcon />
            <p>{zoneTitle}</p>
        </Box>
    )
}
