import React, { useState, useEffect } from 'react'
import Summary from './Summary'

import { getZoneMeasurements } from '../../../actions'

//Renders Sumary when the url path is a match
//gets data from the database and passes it into the summary
const SummaryPath = ({ match }) => {
    const zoneId = match.params.zoneId
    const [stations, setStations] = useState([])
    const [loadingComplete, setLoadingStatus] = useState(false)

    const asyncFetchSummary = async () => {
        const results = await getZoneMeasurements(zoneId)
        setStations(results)
        setLoadingStatus(true)
    }

    useEffect(() => {
        asyncFetchSummary()
    }, [])

    return (
        <Summary
            loadingComplete={loadingComplete}
            stations={stations}
            fetchSummary={asyncFetchSummary}
        />
    )
}

export default SummaryPath

SummaryPath.propTypes = {}

SummaryPath.defaultProps = {}
