import React, { useEffect, useState } from 'react'
import { CircularProgress, Box } from '@mui/material'
import { useAuth } from 'oidc-react'

//This page is rendered as the user is signing in.
const Callback = () => {
    const auth = useAuth()
    const [isError, setIsError] = useState(false)

    //Once the timer runs put the user must be authenticated again
    useEffect(() => {
        let timer = setTimeout(() => {
            setIsError(true)
        }, 3000)
        return () => {
            clearTimeout(timer)
        }
    }, [])

    if (!new URL(window.location.href).searchParams.has('state') || isError) {
        /* 
        Authentication not initialized from our client or authentication timed out
        Redo the auth flow
        */
        auth.signIn()
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
            }}
        >
            <CircularProgress sx={{ marginBottom: 3 }} />
            Signing in...
        </Box>
    )
}

export default Callback
