import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Grid, Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useOrganization, useUserDetails } from 'src/actions/customHooks'

import AdminAccount from './AdminAccount'

import { getAllUsers, createUser, deleteUser } from 'src/actions/openapi'

const useStyles = makeStyles((theme) => ({
    root: { margin: theme.spacing(3) },
    spacer: { padding: theme.spacing(2) },
}))
// 
const General = ({ className, ...rest }) => {
    const classes = useStyles()
    const organization = useOrganization()
    const { role } = useUserDetails()
    const [users, setUsersList] = useState([])
    const [loadingComplete, setLoadingComplete] = useState(false)

    // Returns an updated user list after a user has been created. 
    async function handleUserCreate(userDetails) {
        return await createUser({
            ...userDetails,
            organizationId: organization.id,
        }).then(() => {
            setUsersList([...users, userDetails])
        })
    }
    
    // Returns an updated user list after a user has been deleted. 
    async function handleUserDelete(userId) {
        return await deleteUser({
            id: userId,
            organizationId: organization.id,
        }).then(() => {
            setUsersList(users.filter((user) => user.id !== userId))
        })
    }

    useEffect(() => {
        async function asyncFetchAccountUsers() {
            const results = await getAllUsers()
            setUsersList(results)
            setLoadingComplete(true)
        }
        asyncFetchAccountUsers()
    }, [])

    return (
        <Box className={clsx(classes.root, className)}>
            <AdminAccount
                userAccounts={users}
                loadingComplete={loadingComplete}
                onUserCreate={handleUserCreate}
                onUserDelete={handleUserDelete}
                currentRole={role}
            />
        </Box>
    )
}

General.propTypes = {
    className: PropTypes.string,
}

export default General
