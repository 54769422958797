import axios from 'axios'
// Uses an API call to allow a user to change their password and then updates the data.
export const changePassword = async (oldPassword, newPassword) => {
    return await axios
        .post('/api/change-password', {
            oldPassword,
            newPassword,
        })
        .then(({ data }) => data)
        .catch((err) => console.error(err))
}

// Calls an API function that changes data based on when the user signs out.
export const signOut = async () => {
    return await axios
        .get('/api/signout')
        .then(({ data }) => data)
        .catch((err) => console.error(err))
}
