import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import useCrudLoader from '../../../hooks/useCrudLoader'

//A component that allows the user to delete a dashboard
const DeleteDashboard = ({ displayDeleteDashboard, onSetDisplayDeleteDashboard, handleDeleteDashboard }) => {
    const [location, setLocation] = useState('')
    const history = useHistory()
    const [error, setError] = useState(false)
    const [LoaderWrapper, setIsLoading, isLoading] = useCrudLoader()

    useEffect(() => {
        if (location) {
            const locationStripRegexp = new RegExp('/zones/.')
            const [locationStrip, ...rest] = location.match(locationStripRegexp)
            history.push(locationStrip)
            onSetDisplayDeleteDashboard(false)
        }
    }, [location])

    if (isLoading && displayDeleteDashboard) {
        return (
            <Dialog
                open={displayDeleteDashboard}
                onClose={() => onSetDisplayDeleteDashboard(false)}
                fullWidth={true}
                maxWidth={'xs'}
            >
                <LoaderWrapper>
                </LoaderWrapper>
            </Dialog>
        )
    }

    return (
        <Dialog
            open={displayDeleteDashboard}
            onClose={() => onSetDisplayDeleteDashboard(false)}
        >
            <DialogContent>
                <DialogContentText id="create-zone-error">
                    {error}
                </DialogContentText>
                <DialogContentText>
                    Are you sure you want to delete this dashboard? This action
                    cannot be undone.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onSetDisplayDeleteDashboard(false)}>
                    Cancel
                </Button>
                <Button onClick={() => handleDeleteDashboard()}>Delete</Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteDashboard
