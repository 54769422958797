import React, { useState } from 'react'
import clsx from 'clsx'
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Divider,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import QRCode from 'react-qr-code'

const useStyles = makeStyles(() => ({
    root: {},
    cardInput: {
        width: '40%',
    },
    passwordButton: {
        width: '184px',
    },
}))

const QRLogin = ({ className, ...rest }) => {
    const classes = useStyles()
    const [refreshToken, setRefreshToken] = useState(null)

    console.log(
        Object.entries(sessionStorage).filter(([key, el]) =>
            key.startsWith('oidc')
        )
    )

    const generateQRLoginCode = () => {
        const FIRST_USER_SESSION_OCCURENCE = 0
        const USER_SESSION_VALUE_INDEX = 1
        const userSessionValue = Object.entries(sessionStorage).filter(
            ([key, el]) => key.startsWith('oidc')
        )[FIRST_USER_SESSION_OCCURENCE][USER_SESSION_VALUE_INDEX]
        const userRefreshToken = JSON.parse(userSessionValue)?.refresh_token

        setRefreshToken(userRefreshToken)
    }

    return (
        <Card className={clsx(classes.root, className)} {...rest}>
            <CardHeader title="QR Login" />
            <Divider />
            <CardContent>
                <Box
                    display={'flex'}
                    width="100%"
                    justifyContent="space-between"
                    flexDirection="column"
                >
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => generateQRLoginCode()}
                    >
                        Generate QR Login Code
                    </Button>

                    {refreshToken && (
                        <Box mt={3} alignSelf="center">
                            <QRCode
                                size={512}
                                value={refreshToken}
                                viewBox={`0 0 256 256`}
                            />
                        </Box>
                    )}
                </Box>
            </CardContent>
        </Card>
    )
}

export default QRLogin
