import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Button, Paper, Typography } from '@mui/material'
import IndividualStationSignoff from './IndividualStationSignoff'
import { styles } from '../../../theme/styles'
import { VariableSizeList as List } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { createLogsheetSignOff } from '../actions'
import { useToastContext } from '../../../context/ToastContext'


function IndividualLogsheetSignoff({
    id,
    stations,
    stationOrdering,
    logsheetTitle,
    asyncGetIndividualLogsheet,
    masterLogsheetId,
    stationIdToScroll,
    signOffs,
}) {
    const [searchBar, setSearchBar] = useState('')
    const [sortedStations, setSortedStations] = useState([])
    const listRef = useRef()
    const sizeMap = useRef({})
    const scrolledToInitialStation = useRef(false)
    const setSize = useCallback((index, size) => {
        sizeMap.current = { ...sizeMap.current, [index]: size }
        listRef.current.resetAfterIndex(index)
    }, [])
    const toastContext = useToastContext()
    console.log('stationOrdering', stationOrdering)

    // 200 is the plot height
    const getSize = (index) => Math.max(sizeMap.current[index], 200) + 96 || 300

    useEffect(() => {
        const sortedInitialStations = stations && [
            ...stations.sort((a, b) => {
                return stationOrdering?.[a.id] - stationOrdering?.[b.id]
            }),
        ]

        setSortedStations(sortedInitialStations)
    }, [stations, stationOrdering])

    useEffect(() => {
        if (
            stationIdToScroll &&
            !scrolledToInitialStation.current &&
            sortedStations &&
            listRef.current
        ) {
            scrolledToInitialStation.current = true
            const index = sortedStations.findIndex(
                (obj) => obj.id == stationIdToScroll
            )
            scrollToStation(index)
        }
    }, [sortedStations, listRef.current])

    const scrollToStation = (index) => {
        if (index < sortedStations.length) {
            listRef.current.scrollToItem(index, 'start')
        }
    }

    const searchBarEntered = (stationTitle) => {
        if (stationTitle) {
            const index = sortedStations.findIndex(
                (station) => station.stationTitle === stationTitle
            )
            if (index !== -1) {
                scrollToStation(index)
            }
        }
    }

    const signedOffMeasurementIds = signOffs.flatMap(
        (signOff) => signOff.stationMeasurementIds || []
    )
    const getMostRecentMeasurement = (station, signOffIds) => {
        return (
            station.stationMeasurements
                .filter(
                    (measurement) =>
                        new Date(measurement.createdAt) >
                            new Date(Date.now() - 16 * 60 * 60 * 1000) &&
                        measurement.logsheetId === id &&
                        !signOffIds.includes(measurement.id) // Exclude signed off measurements
                )
                .sort(
                    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                )[0] || {}
        )
    }
    const mostRecentMeasurements = sortedStations?.map((station) =>
        getMostRecentMeasurement(station, signedOffMeasurementIds)
    )

    const handleSubmitSignoff = async (event) => {
        const stationMeasurementIds = mostRecentMeasurements
            .filter(
                (measurement) => measurement && measurement.id !== undefined
            )
            .map((measurement) => measurement.id)

        await createLogsheetSignOff(masterLogsheetId, id, stationMeasurementIds)
            .then(() => {
                toastContext.sendToast(`Logsheet Signed Off`)
            })
            .catch((e) => toastContext.sendToast(e.message, 'error'))
    }

    const renderListItem = ({ index, style }) => {
        return (
            <IndividualStationSignoff
                key={index}
                index={index}
                {...sortedStations[index]}
                zoneTitle={logsheetTitle}
                asyncReloadZone={asyncGetIndividualLogsheet}
                stationId={sortedStations[index].id}
                setSize={setSize}
                setFocusedStation={scrollToStation}
                virtualizedListStyles={style}
                masterLogsheetId={masterLogsheetId}
                logsheetId={id}
                mostRecentMeasurement={mostRecentMeasurements[index]}
            />
        )
    }

    return (
        <Box sx={styles.innerContainer}>
            <Box sx={styles.buttonList}>
                <Autocomplete
                    id="search-bar"
                    options={sortedStations?.map(
                        (station) => station.stationTitle
                    )}
                    getOptionLabel={(option) => option}
                    style={{ width: '50%' }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Search"
                            variant="outlined"
                            value={searchBar}
                            onChange={(e) => setSearchBar(e.target.value)}
                            style={{ width: '100%' }}
                        />
                    )}
                    onChange={(event, val) => searchBarEntered(val)}
                />
            </Box>

            <Paper
                sx={{
                    mt: 3,
                    width: '100%',
                    minHeight: '100%',
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box style={{ display: 'flex' }} p={3}>
                    <Typography variant="h4">{logsheetTitle}</Typography>
                    <Paper
                        sx={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        padding: 2,
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                        gap: 3,
                        zIndex: 1299,
                        }}
                    >
                        <Box
                        sx={{
                            display: 'flex',
                            gap: 2,
                        }}
                        >
                        <Button
                            component={RouterLink}
                            to={`/logsheets/${masterLogsheetId}/signoffs`}
                            size="large"
                            variant="contained"
                            onClick={(e) => handleSubmitSignoff(e)}
                            startIcon={<CheckCircleIcon />}
                        >
                            SIGN LOGSHEET
                        </Button>
                        </Box>
                    </Paper>        
                </Box>

                <Box mt={3} sx={{ flex: 1 }}>
                    {Array.isArray(sortedStations) &&
                        sortedStations.length > 0 && (
                            <AutoSizer>
                                {({ height, width }) => (
                                    <List
                                        ref={listRef}
                                        height={height}
                                        itemCount={sortedStations.length}
                                        itemSize={(index) => getSize(index)}
                                        width={width}
                                        className="virtualList"
                                    >
                                        {renderListItem}
                                    </List>
                                )}
                            </AutoSizer>
                        )}
                </Box>
            </Paper>
        </Box>
    )
}

export default IndividualLogsheetSignoff
