import React, { useState, useEffect } from 'react'

import { getOrganization, getCurrentUser } from './openapi'
import { useLocation } from 'react-router-dom'

/** 
*  use organization -  Creats a use organization custom hook. This hook 
*  allows for the organization that is currently displayed to be changed or kept
*  based on the initial rendering.
*/
export function useOrganization() {
    const [organization, setOrganization] = useState({})

    useEffect(() => {
        async function asyncGetOrganization() {
            const result = await getOrganization()
            setOrganization(result)
        }
        asyncGetOrganization()
    }, [])
    return organization
}

/** 
*  use user details -  Creats a use user details custom hook. This hook 
*  allows for the the details of the user that is currently displayed 
*  to be changed or kept based on the initial rendering.
*/
export function useUserDetails() {
    const [userDetails, setUserDetails] = useState(-1)

    useEffect(() => {
        async function asyncFetchProfileSettings() {
            const results = await getCurrentUser()
            const { firstName, lastName, emailAddress, role } = results
            setUserDetails({
                firstName,
                lastName,
                emailAddress,
                role,
            })
        }
        asyncFetchProfileSettings()
    }, [])
    return userDetails
}

/** 
*  use query -  Creats a custom hook that returns the current URL parameters
*/
export function useQuery() {
    return new URLSearchParams(useLocation().search)
}
