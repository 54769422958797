import React from 'react'
import Plot from 'react-plotly.js'

export class Histogram extends React.Component {
    render() {
        const values =
            this.props.values?.map((stationMeasurement) => {
                if (!this.props.measurementOptions) {
                    return parseFloat(stationMeasurement?.measurementValue)
                } else {
                    return stationMeasurement?.measurementValue
                }
            }) || []

        if (values.length === 0) {
            return <div>No data to display</div>
        }

        let minVal = Math.floor(Math.min(...values))
        let maxVal = Math.ceil(Math.max(...values))

        const numberOfBins = this.props.bins || 10

        const range = maxVal - minVal

        const binSize = Math.ceil(range / numberOfBins)

        const binEdges = Array.from(
            { length: numberOfBins + 1 },
            (_, i) => minVal + i * binSize
        )

        const adjustedEnd = binEdges[binEdges.length - 1] + binSize // Ensure last bin is included

        const withinRangeValues = values.filter((value) => {
            const withinMin =
                this.props.min !== null
                    ? value >= parseFloat(this.props.min)
                    : true
            const withinMax =
                this.props.max !== null
                    ? value <= parseFloat(this.props.max)
                    : true
            return withinMin && withinMax
        })

        const outsideRangeValues = values.filter(
            (value) => !withinRangeValues.includes(value)
        )

        // Calculate the title length threshold for line break
        const titleLengthThreshold1 = 31 // Adjust as needed
        const titleLengthThreshold2 = 60 // Adjust as needed

        // Define the title text
        const titleText = this.props.title

        // Check if the title length exceeds the threshold
        const shouldBreakTitleLine1 = titleText.length > titleLengthThreshold1
        const shouldBreakTitleLine2 = titleText.length > titleLengthThreshold2

        let titleLine1 = titleText.substring(0, titleLengthThreshold1)
        let titleLine2 = shouldBreakTitleLine1
            ? titleText.substring(titleLengthThreshold1, titleLengthThreshold2)
            : ''
        let titleLine3 = shouldBreakTitleLine2
            ? titleText.substring(titleLengthThreshold2)
            : ''

        // Define arrays to store the transparency for bins within and outside the range
        const binTransparencyWithinRange = []
        const binTransparencyOutsideRange = []

        // Iterate over the bin edges to determine transparency
        for (let i = 0; i < binEdges.length - 1; i++) {
            const binStart = binEdges[i]
            const binEnd = binEdges[i + 1]

            // Filter values within the current bin range
            const binValuesWithinRange = withinRangeValues.filter(
                (value) => value >= binStart && value < binEnd
            )
            const binValuesOutsideRange = outsideRangeValues.filter(
                (value) => value >= binStart && value < binEnd
            )

            // Check if the bin contains values within and outside the range
            const hasWithinRangeValues = binValuesWithinRange.length > 0
            const hasOutsideRangeValues = binValuesOutsideRange.length > 0

            // Set transparency based on the presence of values within and outside the range
            if (hasWithinRangeValues && hasOutsideRangeValues) {
                binTransparencyWithinRange.push(0.6)
                binTransparencyOutsideRange.push(0.6)
            } else if (hasWithinRangeValues) {
                binTransparencyWithinRange.push(1)
            } else if (hasOutsideRangeValues) {
                binTransparencyOutsideRange.push(1)
            }
        }
        const data = [
            {
                type: 'histogram',
                x: withinRangeValues,
                name: 'within range',

                marker: {
                    color: '#6fe4ca',
                    line: {
                        color: '#10d2a8',
                        width: 1,
                    },
                    // Set transparency for each bin
                    opacity: binTransparencyWithinRange.map((opacity) =>
                        opacity.toString()
                    ),
                },
                xbins: {
                    start: minVal,
                    end: adjustedEnd,
                    size: binSize,
                },
            },
            {
                type: 'histogram',
                x: outsideRangeValues,
                name: 'out of range',

                marker: {
                    color: 'rgba(238, 32, 77, 0.9)',
                    line: {
                        color: '#b40e32',
                        width: 1,
                    },
                    // Set transparency for each bin
                    opacity: binTransparencyOutsideRange.map((opacity) =>
                        opacity.toString()
                    ),
                },
                xbins: {
                    start: minVal,
                    end: adjustedEnd,
                    size: binSize,
                },
            },
        ]

        const shapes = []
        if (this.props.showSigmaAvg) {
            if (this.props.stddevLower !== null) {
                shapes.push({
                    type: 'line',
                    yref: 'paper',
                    x0: parseInt(this.props.stddevLower),
                    y0: 0,
                    x1: parseInt(this.props.stddevLower),
                    y1: 1,
                    line: {
                        color: 'black',
                        width: 3,
                        dash: 'dot',
                    },
                })
            }
            if (this.props.stddevUpper !== null) {
                shapes.push({
                    type: 'line',
                    yref: 'paper',
                    x0: parseInt(this.props.stddevUpper),
                    y0: 0,
                    x1: parseInt(this.props.stddevUpper),
                    y1: 1,
                    line: {
                        color: 'black',
                        width: 3,
                        dash: 'dot',
                    },
                })
            }
            if (this.props.mean !== null) {
                shapes.push({
                    type: 'line',
                    yref: 'paper',
                    x0: parseInt(this.props.mean),
                    y0: 0,
                    x1: parseInt(this.props.mean),
                    y1: 1,
                    line: {
                        color: 'blue',
                        width: 3,
                        dash: 'dot',
                    },
                })
            }
        }

        if (!this.props.showSigmaAvg) {
            if (this.props.current) {
                shapes.push({
                    type: 'line',
                    yref: 'paper',
                    x0: values[0],
                    y0: 0,
                    x1: values[0],
                    y1: 1,
                    line: {
                        color: 'black',
                        width: 3,
                    },
                })
            }

            if (this.props.min) {
                shapes.push({
                    type: 'line',
                    yref: 'paper',
                    x0: this.props.min,
                    y0: 0,
                    x1: this.props.min,
                    y1: 1,
                    line: {
                        color: '#EE204D',
                        width: 2,
                        dash: 'dot',
                    },
                })
            }
            if (this.props.max) {
                shapes.push({
                    type: 'line',
                    yref: 'paper',
                    x0: this.props.max,
                    y0: 0,
                    x1: this.props.max,
                    y1: 1,
                    line: {
                        color: '#EE204D',
                        width: 2,
                        dash: 'dot',
                    },
                })
            }
        }

        return (
            <div>
                <Plot
                    data={data}
                    layout={{
                        width: this.props.width,
                        height: this.props.height,
                        margin: {
                            l: 30,
                            r: 30,
                            t: 70,
                            b: 90,
                        },
                        paper_bgcolor: 'white',
                        barmode: 'overlay', // Allows histograms to overlap
                        bargap: 0.05,
                        showlegend: false,
                        title: {
                            text: `<b style="letter-spacing: -0.7px">${titleLine1}<br>${titleLine2}<br>${titleLine3}</b>`,
                            // text: this.props.title,
                            font: {
                                size: shouldBreakTitleLine2 ? 14 : 16,
                                family: 'Montserrat',
                            },
                            y: shouldBreakTitleLine2 && 0.92, // Adjust vertical position based on the number of lines
                            x: 0.5,
                            xanchor: 'center',
                            yanchor: 'top',
                        },
                        shapes: shapes,
                        xaxis: {
                            title: {
                                text: `${
                                    !this.props.showSigmaAvg
                                        ? `<span style='color: #EDAB07;'>Target: ${
                                              this.props.target != null
                                                  ? `${this.props.target}${
                                                        this.props.units
                                                            ? ` ${this.props.units}`
                                                            : ''
                                                    }`
                                                  : '-'
                                          }</span><br><span style='color: #D60033;'>Min: ${
                                              this.props.min != null
                                                  ? `${this.props.min}${
                                                        this.props.units
                                                            ? ` ${this.props.units}`
                                                            : ''
                                                    }`
                                                  : '- '
                                          }\nMax: ${
                                              this.props.max != null
                                                  ? `${this.props.max}${
                                                        this.props.units
                                                            ? ` ${this.props.units}`
                                                            : ''
                                                    }`
                                                  : '- '
                                          }</span>`
                                        : `<span style='color: blue;'>Average: ${
                                              this.props.mean != null
                                                  ? `${this.props.mean}`
                                                  : '-'
                                          }</span><br>` +
                                          `Lower CL: ${
                                              this.props.stddevLower != null
                                                  ? `${this.props.stddevLower}`
                                                  : '- '
                                          } ` +
                                          `Upper CL: ${
                                              this.props.stddevUpper != null
                                                  ? `${this.props.stddevUpper}`
                                                  : '- '
                                          }`
                                }`,
                                standoff: 1,
                                font: {
                                    size: 14,
                                },
                            },
                            tickvals: !this.props.measurementOptions
                                ? binEdges
                                : 1,
                            ticktext: binEdges.map((tick) =>
                                Math.round(tick).toString()
                            ),
                            tickangle: 0,
                            showline: true,
                            showgrid: false,
                            showticklabels: true,
                            linecolor: '#444',
                        },
                        yaxis: {
                            title: {
                                text: 'Frequency',
                                standoff: 100,
                                font: {
                                    size: 14,
                                },
                            },
                            showline: false,
                            showgrid: true,
                            showticklabels: 'all',
                            tickvals: 'linear',
                            tick0: 0,
                            dtick: 2,
                            linecolor: '#444',
                        },
                    }}
                />
            </div>
        )
    }
}

Histogram.defaultProps = {
    width: 300,
    height: 320,
    value: 0,
    min: 0,
    max: Infinity,
    bins: 10,
    current: true,
    values: [],
}

export default Histogram
