import React, { useEffect, useState } from 'react'
import { useLocation, matchPath } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import PropTypes from 'prop-types'
import { capitalize } from 'lodash'
import {
    Box,
    Button,
    Collapse,
    Divider,
    Drawer,
    Hidden,
    List,
    ListItem,
    ListSubheader,
    Typography,
    Paper,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import NavItem from './NavItem'
import { useOrganization } from 'src/actions/customHooks'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import IconButton from '@mui/material/IconButton'
// import DashboardIcon from '@mui/icons-material/Dashboard'
import EqualizerIcon from '@mui/icons-material/Equalizer'
import GridViewIcon from '@mui/icons-material/GridView'
import TableViewIcon from '@mui/icons-material/TableView'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import { isUserRole } from '../../utils/isUserRole'
import { useAuth } from 'oidc-react'
import AirIcon from '@mui/icons-material/Air'

import { ReactComponent as DashboardIcon } from '../../icons/navbar/dashboard.svg'
import { ReactComponent as ZonesIcon } from '../../icons/navbar/zones.svg'
import { ReactComponent as LogsheetsIcon } from '../../icons/navbar/logsheets.svg'
import { ReactComponent as StatsIcon } from '../../icons/navbar/stats.svg'
import { ReactComponent as AdminIcon } from '../../icons/navbar/admin.svg'
import { ReactComponent as LimisLogo } from '../../icons/navbar/limis.svg'
import { ReactComponent as LimisLongLogo } from '../../icons/navbar/limisLong.svg'

const SECTIONS = [
    {
        title: 'Dashboard',
        icon: DashboardIcon,
        href: '/',
        disabled: false,
    },
    {
        title: 'Zones',
        icon: ZonesIcon,
        href: '/zones',
        disabled: false,
    },
    {
        title: 'Logsheets',
        icon: LogsheetsIcon,
        href: '/logsheets',
        disabled: false,
    },
    {
        title: 'Logbooks',
        icon: LogsheetsIcon,
        href: '/logbooks',
        disabled: false,
    },
    {
        title: 'Logbook Signoffs',
        icon: LogsheetsIcon,
        href: '/user-logbook-signoffs',
        disabled: false,
    },
    // {
    //     title: 'Analytics',
    //     icon: StatsIcon,
    //     href: '/analytics',
    //     disabled: true,
    // },
]

const ADMIN_SECTIONS = [
    {
        title: 'Admin Portal',
        icon: AdminIcon,
        href: '/admin-portal',
        disabled: false,
    },
    {
        title: 'All Logbook Signoffs',
        icon: LogsheetsIcon,
        href: '/admin-logbook-signoffs',
        disabled: false,
    },
]

const borderMap = {
    RTaaS: '#7B0000',
    MDR: '#00007b',
}

const useStyles = makeStyles((theme) => ({
    mobileDrawer: {
        width: 256,
    },
    desktopDrawer: {
        height: 'calc(100vh)',
        width: '205px',
        transition: 'width 0.3s ease', // Adding transition for smooth animation
    },
    minimizedDesktopDrawer: {
        width: '70px',
    },
    companyLogo: {
        cursor: 'pointer',
        width: 80,
        height: 80,
    },
    item: {
        display: 'block',
        paddingTop: 0,
        paddingBottom: 0,
        margin: '10px',
    },
    limis: {
        display: 'flex',
        padding: '0px 0px',
        justifyContent: 'center',
        //   fontSize: 24,
        // padding: '10px 18px',
        //   margin: '10px',
    },
    button: {
        // color: theme.palette.text.secondary,
        padding: '10px 8px',
        justifyContent: 'center', // Center content horizontally
        display: 'flex',
        alignItems: 'center',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
    },
    companyContainer: {
        margin: '0 auto',
        width: '50%',
        textAlign: 'center',
    },
    companyName: {
        color: theme.palette.text.primary,
    },
    companyPackage: {
        color: theme.palette.text.secondary,
    },
    headerButton: {
        height: '120px',
        width: '120px',
    },
    chevronButton: {
        position: 'absolute',
        right: '-5px',
    },
    selectedOpen: {
        background: 'linear-gradient(90deg, #E85799 0%, #763DE2 100%)',
        borderRadius: '30px',
        paddingLeft: '20px',
        color: 'white',

        border: '2px solid white',
        '&:hover': {
            border: '2px solid white',
            color: 'black',
        },
    },
    selectedClosed: {
        background: 'linear-gradient(90deg, #E85799 0%, #763DE2 100%)',
        borderRadius: '30px',
        paddingLeft: '3px',

        border: '2px solid white',

        '&:hover': {
            border: '2px solid white',
            color: 'black',
        },
    },
    listItemOpen: {
        borderRadius: '30px',

        border: '2px solid transparent',
        paddingLeft: '20px',

        '&:hover': {
            border: '2px solid #e95ee0',
            color: 'black',
            backgroundColor: 'transparent', // Remove hover effect
        },
        '&:active': {
            backgroundColor: 'transparent', // Remove click effect
        },
    },
    listItemClosed: {
        borderRadius: '30px',
        paddingLeft: '3px',

        border: '2px solid transparent',
        '&:hover': {
            border: '2px solid #e95ee0',
            color: 'black',

            backgroundColor: 'transparent', // Remove hover effect
        },
        '&:active': {
            backgroundColor: 'transparent', // Remove click effect
        },
    },
}))

const NavBar = () => {
    const classes = useStyles()
    const location = useLocation()
    const [isOpen, toggleOpen] = useState(true)
    const [navSections, setNavSections] = useState(SECTIONS)
    const [drawerWidth, setDrawerWidth] = useState(205) // Initial width of the desktop drawer
    const auth = useAuth()

    useEffect(() => {
        if (
            isUserRole(auth, 'admin') ||
            isUserRole(auth, 'organization_admin')
        ) {
            setNavSections([...navSections, ...ADMIN_SECTIONS])
        }
    }, [])

    const handleToggleOpen = () => {
        toggleOpen((prev) => !prev)
        setDrawerWidth((prevWidth) => (prevWidth === 205 ? 70 : 205))
    }

    return (
        <Box
            sx={{
                width: `${drawerWidth}px`,
                display: 'flex',
                flexDirection: 'row',
            }}
        >
            <Collapse in={true} orientation="horizontal" collapsedSize={20}>
                <Paper
                    className={`${classes.desktopDrawer} ${
                        !isOpen && classes.minimizedDesktopDrawer
                    }`}
                    style={{ width: `${drawerWidth}px` }}
                >
                    <Box
                        p={2}
                        style={{
                            paddingLeft: isOpen ? '20px' : '5px',
                            minWidth: '75px',
                        }}
                    >
                        <ListItem className={classes.limis} disableGutters>
                            <NavItem
                                depth={0}
                                icon={isOpen ? LimisLongLogo : LimisLogo}
                                open={true}
                                href={'/'}
                                style={{
                                    paddingLeft: isOpen ? '10px' : '0px',
                                    marginLeft: 0,
                                }}
                                disabled
                            />
                        </ListItem>
                    </Box>
                    <Box
                        height="100%"
                        display="flex"
                        flexDirection="column"
                        padding={isOpen ? '10px' : '7px'}
                    >
                        <List>
                            {navSections.map((section, idx) => {
                                const open = matchPath(location.pathname, {
                                    path: section.href,
                                    exact: false,
                                })
                                return (
                                    <ListItem
                                        key={idx}
                                        disableGutters
                                        className={
                                            section.href === '/' &&
                                            location.pathname !== '/' // Check if it's the dashboard link and pathname is not exactly '/'
                                                ? isOpen
                                                    ? classes.listItemOpen
                                                    : classes.listItemClosed // If true, do not highlight
                                                : location.pathname.startsWith(
                                                      section.href
                                                  ) // Otherwise, check if the pathname starts with section.href
                                                ? isOpen
                                                    ? classes.selectedOpen
                                                    : classes.selectedClosed
                                                : isOpen
                                                ? classes.listItemOpen
                                                : classes.listItemClosed
                                        }
                                    >
                                        <NavItem
                                            depth={0}
                                            href={section.href}
                                            open={isOpen ? true : false}
                                            selected={
                                                !(
                                                    section.href === '/' &&
                                                    location.pathname !== '/'
                                                ) &&
                                                location.pathname.startsWith(
                                                    section.href
                                                )
                                                    ? true
                                                    : false
                                            }
                                            icon={section.icon}
                                            info={section.info}
                                            key={section.title}
                                            title={
                                                isOpen ? section.title : null
                                            } // Render title only if navbar is open
                                            disabled
                                            external={section.external}
                                        />
                                    </ListItem>
                                )
                            })}
                        </List>
                    </Box>
                </Paper>
            </Collapse>{' '}
            <Button
                onClick={handleToggleOpen}
                variant="contained"
                sx={{
                    right: -10,
                    top: '50vh',
                    height: '10px',
                    width: '10px',
                    margin: 0,
                    minWidth: '10px',
                    padding: '11px',
                    //  minHeight: '5px',
                    borderRadius: '50%',
                    backgroundColor: '#363636',
                }}
            >
                {isOpen ? (
                    <KeyboardArrowLeftIcon
                        sx={{
                            fontSize: 20,
                        }}
                    />
                ) : (
                    <KeyboardArrowRightIcon
                        sx={{
                            fontSize: 20,
                        }}
                    />
                )}
            </Button>
        </Box>
    )
}

export default NavBar
