import Alert from '@mui/material/Alert'
import { Snackbar } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        top: theme.spacing(13),
        marginRight: theme.spacing(4),
    },
}))

//Renders a temporary pop up message on the screen.
//Props: showAlert showAlert.message is the message to be displayed and showAlert.type distates the style of the alert
//       setShowAlert changes the passed state once the alert has closed
const AlertMessage = ({ showAlert, setShowAlert }) => {
    const classes = useStyles()

    const handleClose = (e, reason) => {
        setShowAlert({
            ...showAlert,
            isOpen: false,
        })
    }

    return (
        <Snackbar
            className={classes.root}
            open={showAlert.isOpen}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            onClose={handleClose}
        >
            <Alert onClose={handleClose} severity={showAlert.type}>
                {showAlert.message}
            </Alert>
        </Snackbar>
    )
}

export default AlertMessage
