import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import IndividualZone from './IndividualZone'
import { getIndividualZone, getStationOrdering } from 'src/views/Zones/actions'
import useLoader from '../../../hooks/useLoader'
import { useToastContext } from '../../../context/ToastContext'

//Renders IndavidualZone when the url path is a match
//Uses the useLoader hook to display the waiting screen while the zone data is being retrieved from the database
const IndividualZonePath = ({ match }) => {
    const zoneId = match.params.zoneId
    const stationId = match.params.stationId
    const [zone, setIndividualZone] = useState({})
    const [stationOrdering, setStationOrdering] = useState({})
    const [LoaderWrapper, setIsLoading] = useLoader()
    const toastContext = useToastContext()

    const asyncGetIndividualZone = async () => {
        setIsLoading(true)

        const [individualZoneResult, zoneStationOrderingResults] =
            await Promise.all([
                getIndividualZone(zoneId),
                getStationOrdering(zoneId),
            ]).catch((e) =>
                toastContext.sendToast('Error Fetching Data', 'error')
            )

        setIndividualZone(individualZoneResult.data)
        setStationOrdering(zoneStationOrderingResults)

        setIsLoading(false)
    }

    useEffect(() => {
        asyncGetIndividualZone()
    }, [zoneId])

    return (
        <LoaderWrapper>
            <IndividualZone
                {...zone}
                stationOrdering={stationOrdering}
                updateZoneStationOrdering={setStationOrdering}
                asyncGetIndividualZone={asyncGetIndividualZone}
                stationIdToScroll={stationId}
            />
        </LoaderWrapper>
    )
}

export default IndividualZonePath

IndividualZonePath.propTypes = {
    zoneId: PropTypes.string,
}
