import React, { useState } from 'react'

import {
    Box,
    Button,
    Divider,
    Fade,
    Modal,
    TextField,
    Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => {
    return {
        root: {
            backgroundColor: 'white',
        },
        modal: {
            width: '675px',
            height: `calc(825px + ${theme.spacing(6)}px + ${theme.spacing(
                4
            )}px)`,
            margin: `${theme.spacing(2)}px auto`,
        },
        modalContent: { backgroundColor: theme.palette.background.default },
        modalForm: {
            padding: theme.spacing(2),
        },
        modalHeader: {
            padding: theme.spacing(3),
            alignItems: 'centered',
        },
        textField: {
            margin: theme.spacing(2),
        },
        userModalButton: {
            margin: theme.spacing(2),
            display: 'flex',
            justifyContent: 'space-between',
        },
    }
})
// Allows for a user to be created and their details to be updated.
export default function UserCreateModal({ onClose, onUserCreate, active }) {
    const classes = useStyles()
    const [userDetails, setUserDetails] = useState({
        firstName: '',
        lastName: '',
        emailAddress: '',
    })

    return (
        <Modal className={classes.modal} open={active} closeAfterTransition>
            <Fade in={active} timeout={500}>
                <Box
                    style={{ outline: 'none' }}
                    className={classes.modalContent}
                >
                    <Box className={classes.modalHeader}>
                        <Typography
                            variant="h2"
                            color="textPrimary"
                            width="fit-content"
                        >
                            Create User Account
                        </Typography>
                    </Box>

                    <Divider width={'96%'} />
                    <Box className={classes.modalForm}>
                        <Box display="flex">
                            <TextField
                                className={classes.textField}
                                fullWidth
                                label="First Name"
                                name="firstName"
                                onChange={(e) => {
                                    setUserDetails({
                                        ...userDetails,
                                        firstName: e.target.value,
                                    })
                                }}
                                required
                                variant="outlined"
                            />
                            <TextField
                                className={classes.textField}
                                fullWidth
                                label="Last Name"
                                name="lastName"
                                onChange={(e) => {
                                    setUserDetails({
                                        ...userDetails,
                                        lastName: e.target.value,
                                    })
                                }}
                                required
                                variant="outlined"
                            />
                        </Box>
                        <Box>
                            <TextField
                                className={classes.textField}
                                // fullWidth
                                style={{ width: '95%' }}
                                label="Email Address"
                                name="emailAddress"
                                onChange={(e) => {
                                    setUserDetails({
                                        ...userDetails,
                                        emailAddress: e.target.value,
                                    })
                                }}
                                required
                                variant="outlined"
                            />
                        </Box>

                        <Box className={classes.userModalButton}>
                            <Button
                                color="primary"
                                onClick={async () => {
                                    await onUserCreate(userDetails)
                                    onClose()
                                }}
                                variant="contained"
                                size="large"
                            >
                                Create
                            </Button>
                            <Button
                                color="primary"
                                onClick={() => {
                                    onClose(null)
                                }}
                                variant="contained"
                                size="large"
                            >
                                Close
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    )
}
