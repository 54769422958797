import React, { useState } from 'react'
import {
    Box,
    TextField,
    Typography,
    FormGroup,
    FormControlLabel,
    Checkbox,
} from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import Plot from 'react-plotly.js'

//Displays station while dragging in virtual list
function IndividualStationDragging({
    stationTitle,
    stationDescription,
    target,
    rangeLow,
    rangeHigh,
    measurementUnit,
    measurementOptions,
    stationMeasurements,
}) {
    const isMultipleChoiceStation = measurementOptions.length > 0
    const [recentMeasurements, setRecentMeasurements] =
        useState(stationMeasurements)
    const rangeLowNumber = parseFloat(rangeLow)
    const rangeHighNumber = parseFloat(rangeHigh)

    return (
        <Box px={3} mb={12} style={{ opacity: '0.5' }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 3,
                }}
            >
                <DragIndicatorIcon />

                <Box sx={{ width: '200px' }}>
                    <Typography variant="h5">{stationTitle}</Typography>
                    <Typography>{stationDescription}</Typography>
                </Box>

                <Box sx={{ width: '300px' }}>
                    {target && (
                        <Typography>
                            Target: {target}
                            {measurementUnit}
                        </Typography>
                    )}
                    {rangeLow && rangeHigh ? (
                        <Typography>
                            Range: {rangeLowNumber}
                            {measurementUnit} - {rangeHighNumber}
                            {measurementUnit}
                        </Typography>
                    ) : (
                        <Typography>
                            {rangeLow || rangeHigh ? 'Range' : ''}
                            {!!rangeLowNumber ? `> ${rangeLowNumber}` : ''}
                            {!!rangeHighNumber ? `< ${rangeHighNumber}` : ''}
                            {!!rangeLowNumber || !!rangeHighNumber
                                ? measurementUnit
                                : ''}
                        </Typography>
                    )}
                    {!isMultipleChoiceStation ? (
                        <TextField
                            variant="outlined"
                            placeholder="measured value"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        {measurementUnit}
                                    </InputAdornment>
                                ),
                            }}
                            style={{ zIndex: 2, backgroundColor: 'white' }}
                        ></TextField>
                    ) : (
                        <FormGroup
                            style={{
                                zIndex: 2,
                                backgroundColor: 'white',
                                position: 'relative',
                            }}
                        >
                            {measurementOptions.map((item) => {
                                return (
                                    <FormControlLabel
                                        control={<Checkbox />}
                                        label={item}
                                    />
                                )
                            })}
                        </FormGroup>
                    )}
                </Box>

                {isMultipleChoiceStation
                    ? recentMeasurements && (
                          <Plot
                              data={[
                                  {
                                      x: recentMeasurements
                                          .map(
                                              (measurement) =>
                                                  measurement.measurementValue
                                          )
                                          .reverse(),
                                      type: 'histogram',
                                      mode: 'lines+markers',
                                      marker: { color: 'green' },
                                  },
                              ]}
                              layout={{
                                  width: 400,
                                  height: 200,
                                  title: '',
                                  autosize: true,
                                  showlegend: false,
                                  hidesources: false,
                                  margin: {
                                      l: 30,
                                      r: 0,
                                      b: 30,
                                      t: 0,
                                      pad: 0,
                                  },
                              }}
                              config={{
                                  displayModeBar: false,
                                  staticPlot: true,
                                  fillFrame: true,
                                  responsive: true,
                              }}
                          />
                      )
                    : recentMeasurements && (
                          <Plot
                              data={[
                                  {
                                      x: recentMeasurements.map((measurement) =>
                                          new Date(
                                              measurement.createdAt
                                          ).toLocaleString()
                                      ),
                                      y: recentMeasurements
                                          .map(
                                              (measurement) =>
                                                  measurement.measurementValue
                                          )
                                          .reverse(),
                                      type: 'scatter',
                                      mode: 'lines+markers',
                                      marker: { color: 'green' },
                                  },
                              ]}
                              layout={{
                                  width: 400,
                                  height: 200,
                                  title: '',
                                  autosize: true,
                                  showlegend: false,
                                  hidesources: false,
                                  margin: {
                                      l: 30,
                                      r: 0,
                                      b: 30,
                                      t: 0,
                                      pad: 0,
                                  },
                                  xaxis: {
                                      autorange: true,
                                      type: 'category',
                                      visible: false,
                                  },
                              }}
                              config={{
                                  displayModeBar: false,
                                  staticPlot: true,
                                  fillFrame: true,
                                  responsive: true,
                              }}
                          />
                      )}
            </Box>
        </Box>
    )
}

export default IndividualStationDragging
