import React, { useState, useEffect } from 'react'
import useLoader from '../../hooks/useLoader'
import DashboardItems from './DashboardItems'
import {
    asyncFetchAllDashboards,
    asyncFetchDashboard,
    createDashboard,
} from './actions'

const Dashboard = () => {
    const [dashboardIndex, setDashboardIndex] = useState(0)
    const [currentDashboard, setCurrentDashboard] = useState([])
    const [userDashboards, setUserDashboards] = useState([1])
    const [ordering, setOrdering] = useState({})
    const [LoaderWrapper, setIsLoading] = useLoader()

    const fetchAllDashboards = async () => {
        const data = await asyncFetchAllDashboards()
        data.sort((a, b) => a.id - b.id)
        if (data.length > 0) {
            setUserDashboards(data.map((user) => user.id))
        } else {
            setUserDashboards([1])
            await createDashboard(
                { dashboardName: 'Your First Dashboard' },
            )
        }
    }

    const fetchIndividualDashboard = async () => {
        const dashboard = await asyncFetchDashboard(userDashboards[dashboardIndex])
        setCurrentDashboard(dashboard)
        setOrdering(dashboard.ordering)
    }

    useEffect(() => {
        fetchAllDashboards()
    }, [])

    useEffect(() => {
        let isMounted = true; 
        const fetchData = async () => {
            try {
                const dashboard = await asyncFetchDashboard(userDashboards[dashboardIndex])
                if (isMounted) {
                    setCurrentDashboard(dashboard)
                    setOrdering(dashboard.ordering)
                }
            } catch (error) {
                console.error('Error fetching data:', error)
            }
        }

        fetchData();

        return () => {
            isMounted = false
        };
    }, [userDashboards, dashboardIndex, asyncFetchDashboard, setCurrentDashboard, setOrdering]);

    return (
        <LoaderWrapper>
            <DashboardItems
                userDashboards={userDashboards}
                setUserDashboards={setUserDashboards}
                ordering={ordering}
                setOrdering={setOrdering}
                fetchDashboard={fetchIndividualDashboard}
                currentDashboard={currentDashboard}
                setCurrentDashboard={setCurrentDashboard}
                dashboardIndex={dashboardIndex}
                setDashboardIndex={setDashboardIndex}
                setIsLoading={setIsLoading}
            />
        </LoaderWrapper>
    )
}

export default Dashboard
