import React, { useState } from 'react'

import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Radio,
    RadioGroup,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Typography,
    TextField,
} from '@mui/material'
import Card from '@mui/material/Card'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useToastContext } from '../../../context/ToastContext'
import useCrudLoader from '../../../hooks/useCrudLoader'
import { addNewDashboardItems, asyncFetchDashboard } from '../actions'

export default function ModalContent({
    setDisplayModal,
    masterLogsheets,
    zonesWithStations,
    setCurrentDashboard,
    userDashboards,
    dashboardIndex,
    setOrdering,
    setLoading,
}) {
    const [open, setOpen] = React.useState(true)
    const [error, setError] = useState(false)
    const [selectedStationsData, setSelectedStationsData] = useState({})
    const [dataPoints, setDataPoints] = useState({})
    const [formValueWarning, setFormValueWarning] = useState('')
    const [LoaderWrapper, setIsLoading, isLoading] = useCrudLoader()
    const toastContext = useToastContext()

    const handleClose = () => {
        setOpen(false)
        setDisplayModal(false)
    }

    const handleSubmit = async (event) => {
        if (isLoading) {
            return
        }

        if (Object.values(selectedStationsData).includes(null)) {
            setFormValueWarning('Need to select a chart type for each station')
            return
        }
        if (Object.keys(selectedStationsData).length === 0) {
            setFormValueWarning('Need to select a station')
            return
        }

        setLoading(true)
        event.preventDefault()
        const currDashBoard = userDashboards[dashboardIndex]

        selectedStationsData.dataPointInfo = dataPoints
        await addNewDashboardItems(
            currDashBoard,
            selectedStationsData,
            dataPoints
        )
        const dashboard = await asyncFetchDashboard(
            userDashboards[dashboardIndex]
        )
        setCurrentDashboard(dashboard)
        setOrdering(dashboard.ordering)

        setIsLoading(false)
        setDisplayModal(false)
        toastContext.sendToast('New Dashboard Item Created')

        setFormValueWarning('')
        setLoading(false)
    }

    const handleCheckStation = (stationId) => {
        if (Object.keys(selectedStationsData).includes(stationId.toString())) {
            setSelectedStationsData((prev) =>
                Object.fromEntries(
                    Object.entries(selectedStationsData).filter(
                        (entry) => entry[0] !== stationId
                    )
                )
            )
        } else {
            setSelectedStationsData((prev) => ({ ...prev, [stationId]: null }))
        }
    }
    const handleSetDataPoints = (e, stationId) => {
        const value = parseInt(e.target.value)
        setDataPoints((prev) => ({
            ...prev,
            [stationId]: value,
        }))
    }

    const handleCheckStationChartType = (stationId, e) => {
        setSelectedStationsData((prev) => ({
            ...prev,
            [stationId]: e.target.value,
        }))
    }

    if (isLoading) {
        return (
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={'xs'}
            >
                <LoaderWrapper></LoaderWrapper>
            </Dialog>
        )
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogContent sx={{ width: '440px' }}>
                <DialogContentText id="create-zone-error">
                    {error}
                </DialogContentText>
                {/* 
                    Masterlogsheets is an array of arrays
                    The nested array includes 'masterlogsheetTitle' in position 0, and 'masterlogsheetId' in position 1.
                    Example: [ ["Master 1", 7], ["Master 2", 9], ["Master 3", 22] ]
                */}
                {masterLogsheets?.map((master) => {
                    return (
                        <div key={master.id}>
                            {zonesWithStations?.some(
                                (zone) => zone.masterLogsheetId === master[1]
                            ) && (
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        {master[0]}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {zonesWithStations?.map(
                                            (zone) =>
                                                zone.masterLogsheetId ===
                                                    master[1] && (
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={
                                                                <ExpandMoreIcon />
                                                            }
                                                            aria-controls="panel1-content"
                                                            id="panel1-header"
                                                        >
                                                            {zone.zoneTitle}
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <FormGroup>
                                                                {zone.stations?.map(
                                                                    (
                                                                        station
                                                                    ) => (
                                                                        <>
                                                                            <FormControlLabel
                                                                                key={
                                                                                    station.id
                                                                                }
                                                                                control={
                                                                                    <Checkbox
                                                                                        checked={
                                                                                            selectedStationsData
                                                                                                .station
                                                                                                ?.id
                                                                                        }
                                                                                        onChange={() =>
                                                                                            handleCheckStation(
                                                                                                station.id
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                }
                                                                                label={
                                                                                    `${
                                                                                        station.stationTitle
                                                                                    }${
                                                                                        station.stationDescription
                                                                                            ? `- ${station.stationDescription}`
                                                                                            : ''
                                                                                    }` +
                                                                                    (selectedStationsData[
                                                                                        station
                                                                                            .id
                                                                                    ]
                                                                                        ? ':   ' +
                                                                                          selectedStationsData[
                                                                                              station
                                                                                                  .id
                                                                                          ]
                                                                                        : '')
                                                                                }
                                                                            />
                                                                            {selectedStationsData[
                                                                                station
                                                                                    .id
                                                                            ] ===
                                                                                'Line' && (
                                                                                <TextField
                                                                                    id="outlined-basic"
                                                                                    label="How many data points? (Default is 10)"
                                                                                    variant="outlined"
                                                                                    value={
                                                                                        dataPoints[
                                                                                            station
                                                                                                .id
                                                                                        ] ||
                                                                                        ''
                                                                                    }
                                                                                    onChange={(
                                                                                        e
                                                                                    ) =>
                                                                                        handleSetDataPoints(
                                                                                            e,
                                                                                            station.id
                                                                                        )
                                                                                    }
                                                                                    style={{
                                                                                        width: '15vw',
                                                                                    }}
                                                                                />
                                                                            )}
                                                                            {Object.keys(
                                                                                selectedStationsData
                                                                            ).includes(
                                                                                station.id.toString()
                                                                            ) &&
                                                                                selectedStationsData[
                                                                                    station
                                                                                        .id
                                                                                ] ==
                                                                                    null && (
                                                                                    <Card
                                                                                        sx={{
                                                                                            minWidth: 275,
                                                                                            paddingLeft:
                                                                                                '5%',
                                                                                        }}
                                                                                    >
                                                                                        {/* <CardContent> */}
                                                                                        {/* <FormLabel id="demo-row-radio-buttons-group-label">Chart Type</FormLabel> */}
                                                                                        <RadioGroup
                                                                                            row
                                                                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                                                                            name="row-radio-buttons-group"
                                                                                        >
                                                                                            <FormControlLabel
                                                                                                value="Gauge"
                                                                                                control={
                                                                                                    <Radio />
                                                                                                }
                                                                                                label="Gauge"
                                                                                                onChange={(
                                                                                                    event
                                                                                                ) =>
                                                                                                    handleCheckStationChartType(
                                                                                                        station.id,
                                                                                                        event
                                                                                                    )
                                                                                                }
                                                                                            />
                                                                                            <FormControlLabel
                                                                                                value="Histogram"
                                                                                                control={
                                                                                                    <Radio />
                                                                                                }
                                                                                                label="Histogram"
                                                                                                onChange={(
                                                                                                    event
                                                                                                ) =>
                                                                                                    handleCheckStationChartType(
                                                                                                        station.id,
                                                                                                        event
                                                                                                    )
                                                                                                }
                                                                                            />
                                                                                            <FormControlLabel
                                                                                                value="Line"
                                                                                                control={
                                                                                                    <Radio />
                                                                                                }
                                                                                                label="Line"
                                                                                                onChange={(
                                                                                                    event
                                                                                                ) =>
                                                                                                    handleCheckStationChartType(
                                                                                                        station.id,
                                                                                                        event
                                                                                                    )
                                                                                                }
                                                                                            />
                                                                                        </RadioGroup>
                                                                                        {/* </CardContent> */}
                                                                                    </Card>
                                                                                )}
                                                                        </>
                                                                    )
                                                                )}
                                                            </FormGroup>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                )
                                        )}
                                    </AccordionDetails>
                                </Accordion>
                            )}
                        </div>
                    )
                })}
            </DialogContent>
            <DialogContent style={{ textAlign: 'right', color: 'red' }}>
                {formValueWarning && (
                    <Typography>{formValueWarning}</Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSubmit}>Create</Button>
            </DialogActions>
        </Dialog>
    )
}
