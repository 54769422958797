import axios from 'axios'
//This file contains functions that use axios to send and get information from the backend.
export const getStationMeasurements = async (
    zoneId,
    stationId,
) => {
    return await axios
        .get(`/api/zones/${zoneId}/stations/${stationId}/measurements`)
        .then(({ data }) => data)
        .catch((err) => console.error(err))
}

export const getBrokenStations = async () => {
    return await axios
        .get(`/api/broken-stations`)
        .then(({ data }) => data)
        .catch((err) => console.error(err))
}

export const setBrokenStation = async (
    zoneId,
    stationId,
    brokenVal
) => {
    return await axios
    .post(`/api/zones/${zoneId}/stations/${stationId}/broken`, {
        brokenVal,
    })
    .then(({ data }) => data)
}

export const getAllDashboardStations = async () => {
    return await axios.get('/api/zones').then(({ data }) => data)
}

export const getAllZonesWithStations = async () => {
    return await axios
        .get('/api/zones-with-stations')
        .then(({ data }) => {
            const returnObj = {}
            for (const zone of data) {
                returnObj[zone.id] = zone
            }
        })
        .catch((err) => console.error(err))
    }

export const asyncFetchDashboard = async (dashboardNumber) => {
    return await axios
        .get(`/api/dashboard/${dashboardNumber}`)
        .then(({ data }) => data)
        .catch((err) => console.error(err))    
}

export const addNewDashboardItems = async (dashboardNumber, stationObj, dataPoints) => {
    return await axios
        .post(`/api/dashboard/${dashboardNumber}/dashboardItems`, { stationObj, dataPoints })
        .then(({ data }) => data)
        .catch((err) => console.error(err))    
}

export const createDashboard = async (dashboardObject) => {
    return await axios
        .post(`/api/allDashboards`, {dashboardObject})
        .then(({ data }) => data)
        .catch((err) => console.error(err))    
}

export const asyncFetchAllDashboards = async () => {
    return await axios
        .get(`/api/allDashboards`)
        .then(({ data }) => data)
        .catch((err) => console.error(err))    
}

export const asyncFetchAllDashboardItems = async () => {
    return await axios
        .get(`/api/allDashboardItems`)
        .then(({ data }) => data)
        .catch((err) => console.error(err))    
}

export const updateDashboardOrdering = async (orderingJSON, dashboardNumber) => {
    return await axios
        .post(`/api/dashboard/${dashboardNumber}/ordering`, orderingJSON)
        .then(({ data }) => data)
        .catch((err) => console.error(err))
}

export const asyncDeleteStationsFromDashboard = async (dashboardItemsArray, dashboardNumber) => {
    const requestBody = JSON.stringify({ dashboardItems: dashboardItemsArray });

    return await axios
        .delete(`/api/dashboard/${dashboardNumber}/deleteDashboardItems`, {data: requestBody})
        .then(({ data }) => data)
        .catch((err) => console.error(err))
}

export const changeDashboardName = async (dashboardNumber, newDashboardName) => {
    return await axios
        .post(`/api/dashboard/${dashboardNumber}/changeDashboardName`, {newDashboardName})
        .then(({ data }) => data)
        .catch((err) => console.error(err))
}

export const deleteDashboard = async (dashboardNumber) => {
    return await axios
        .delete(`/api/dashboard/${dashboardNumber}`)
        .then(({ data }) => data)
        .catch((err) => console.error(err))
}

