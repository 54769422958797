import React, { useState } from 'react'
import { useShowContentBasedOnRoles } from '../../hooks/useShowContentBasedOnRoles'
import { Box, Button, Divider, Paper, Typography } from '@mui/material'
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined'
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo'
import { Link as RouterLink } from 'react-router-dom'
import CreateMasterLogsheet from './CreateOrUpdateMasterLogsheet'
import { styles } from '../../theme/styles'
import { ReactComponent as MasterLogsheetIcon } from '../../icons/masterLogsheet.svg'

import { ReactComponent as CreateIcon } from '../../icons/buttons/create.svg'

const iconStyles = {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}

export const MasterLogsheet = ({
    masterLogsheetTitle,
    lastAccess,
    elevated,
    ...rest
}) => {
    return (
        <Paper key={rest.id} elevation={0} style={styles.paper}>
            <Box sx={styles.cardImageSmallContainer}>
                <MasterLogsheetIcon style={iconStyles} />
            </Box>
            <Box sx={styles.cardTextContainer}>
                <div style={styles.cardTextBig}>{masterLogsheetTitle}</div>
                <div style={styles.cardTextSmallerBlock}>
                    <div style={styles.cardTextSmall}>Last Acccessed at: </div>
                    <div style={styles.cardTextSmallBold}>
                        {new Date(lastAccess).toLocaleString()}
                    </div>
                </div>
            </Box>
        </Paper>
    )
}

//The page where users can view masterlogsheets and create new ones if they are an admin
function MasterLogsheets({ masterLogsheets, ...props }) {
    const ShowContentBasedOnRolesWrapper = useShowContentBasedOnRoles()
    const [displayCreateMasterLogsheet, setDisplayCreateMasterLogsheet] =
        useState(false)
    return (
        <Box sx={styles.innerContainer}>
            {/* Renders the CreateMasterLogsheet object */}
            {displayCreateMasterLogsheet && (
                <CreateMasterLogsheet
                    setDisplayModal={setDisplayCreateMasterLogsheet}
                    onSuccess={props.asyncFetchMasterLogsheets}
                    operation="Create"
                />
            )}

            {/* If the user is an admin they can see the create master logsheet button */}
            <Box sx={styles.buttonList}>
                {
                    <ShowContentBasedOnRolesWrapper
                        roles={[
                            'admin',
                            'organization_admin',
                            'organization_superuser',
                        ]}
                    >
                        <button
                            className="normalButton"
                            onClick={() => setDisplayCreateMasterLogsheet(true)}
                        >
                            <div className="buttonIconBlock">
                                <CreateIcon />
                            </div>
                            <div className="buttonTextBlock">
                                <div className="buttonTextBiggerBlock">
                                    Create
                                </div>
                                <div className="buttonTextSmallerBlock">
                                    master logsheet
                                </div>
                            </div>
                        </button>
                    </ShowContentBasedOnRolesWrapper>
                }
            </Box>

            {/* Loops through the given master logsheets and renders the above specified MasterLogsheet JSX tag for each */}
            <Box sx={styles.cardContainer}>
                {Array.isArray(masterLogsheets) &&
                    masterLogsheets?.map((masterLogsheet) => (
                        <Box
                            component={RouterLink}
                            to={`/logsheets/${masterLogsheet.id}`}
                            style={{ textDecoration: 'none', gap: '5px' }}
                            sx={{
                                ':hover': {
                                    boxShadow:
                                        '0px 10px 15px rgba(0, 0, 0, 0.2)',
                                },
                            }}
                        >
                            <MasterLogsheet
                                {...masterLogsheet}
                                lastAccess={
                                    masterLogsheet.lastAccess
                                        ? masterLogsheet.lastAccess
                                        : masterLogsheet.updatedAt
                                }
                            />
                        </Box>
                    ))}
            </Box>
        </Box>
    )
}

export default MasterLogsheets
